import { Typography } from '@mui/material';

function FormLabel({ children }) {
  return (
    <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
      {children}
    </Typography>
  );
}

export default FormLabel;
