import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useDataProvider } from '@servicexcelerator/ui-design-system';
import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import DispatchSection from './DispatchSection';

function DispatchHistory({ serviceJobData, AppModule }) {
  const { Components, Constants } = AppModule;
  const { useUserAccess } = Components;
  const { COMPANY_TYPE } = Constants;
  const provider =
    process.env.NODE_ENV === 'development' ? 'serviceJob' : 'default';
  const { formatMessage } = useIntl();

  const defaultMessage = formatMessage({
    id: 'NO_DATA_EXISTS',
    defaultMessage: 'No data exists',
  });

  const userAccess = useUserAccess();

  const { getMany } = useDataProvider(provider);
  // const [loading, setLoading] = useState(true);
  const [dispatchData, setDispatchData] = useState({});

  const isSA = userAccess.companyType === COMPANY_TYPE.SERVICE_ADMINISTRATOR;

  const fetchData = async () => {
    // setLoading(true);

    const result = await getMany(
      `/servicejob/v1/servicejob/dispatch/${serviceJobData?.serviceJobNumber}/history`,
    );

    if (result) {
      setDispatchData(result?.data || []);
    }
    // setLoading(false);
  };

  useEffect(() => {
    if (serviceJobData?.serviceJobNumber && isSA) {
      fetchData();
    } else {
      // setLoading(false);
    }
  }, [serviceJobData?.serviceJobNumber]);

  if (!isSA) {
    return null;
  }

  return (
    <Grid2>
      {serviceJobData && dispatchData.serviceJobNumber ? (
        <DispatchSection
          serviceJobData={serviceJobData}
          dispatchData={dispatchData}
          defaultMessage={defaultMessage}
          AppModule={AppModule}
        />
      ) : (
        <Typography>
          {formatMessage({
            id: 'NO_DATA_EXISTS',
            defaultMessage: 'No data exists',
          })}
        </Typography>
      )}
    </Grid2>
  );
}

export default DispatchHistory;
