import { useEffect, useState, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import {
  PageSection,
  useStore,
  useSnackbar,
  DYNAMIC_FORM_MODE,
  SpecialSection,
  Breadcrumbs,
} from '@servicexcelerator/ui-design-system';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import RuleContainer from '../../ui/RuleContainer';
import {
  emptyRuleDecision,
  emptyCondition,
  findConditionById,
  getUniqueKey,
  emptyRule,
  findNodeByDecisionId,
  validateRule,
  generateFactMapByField,
  generateFlattenedFilters,
  generateOperatorsMapByType,
  generateOperatorsMapByValue,
} from '../../common/util';
import { COMBINATORS } from '../../common/constants';
import RuleClaimForm from '../../ui/RuleClaimForm';
import CLAIM_RULE_FORMS from '../../common/ruleclaimforms';
import Facts from '../../common/facts';
import Operators from '../../common/operators';

function RulesEditor({ formMode = DYNAMIC_FORM_MODE.VIEW, AppModule }) {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const [rules, setRules] = useState([]);
  const [errors, setErrors] = useState({});
  const [pageError, setPageError] = useState(null);
  const [selectedRule, setSelectedRule] = useState(emptyRule());
  const [ruleClaimForms, setRuleClaimForms] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [level, setLevel] = useState(0);
  const { Components, Icons } = AppModule;
  const { RulesEditor: RuleEditorIcon, BackButton } = Icons;
  const { Layout, Header } = Components;
  const store = useStore();
  const snackbar = useSnackbar();
  const routeParam = useParams();

  const factFieldMap = useMemo(() => generateFactMapByField(Facts), [Facts]);
  const factFields = useMemo(() => generateFlattenedFilters(Facts), [Facts]);
  const operatorsByTypeMap = useMemo(
    () => generateOperatorsMapByType(Operators),
    [Operators],
  );
  const operatorsByValueMap = useMemo(
    () => generateOperatorsMapByValue(Operators),
    [Operators],
  );

  useEffect(() => {
    const storeRules = store.uiState.rules.getRules();
    if (formMode === DYNAMIC_FORM_MODE.CREATE) {
      setSelectedRule(emptyRule());
      setErrors({});
    } else {
      if (!routeParam?.ruleId) {
        setPageError({
          errorMessage: formatMessage({
            id: 'RULE_ID_NOT_PROVIDED',
            defaultMessage: 'Rule id not provided',
          }),
        });
      } else {
        const ruleById = storeRules.find(
          rule => rule.ruleId === routeParam?.ruleId,
        );
        if (!ruleById) {
          setPageError({
            errorMessage: formatMessage({
              id: 'RULE_NOT_FOUND',
              defaultMessage: 'Rule not found',
            }),
          });
        } else {
          setSelectedRule(ruleById);
        }
      }
      setRules(storeRules);
    }
    setErrors(errors);
    setRuleClaimForms(CLAIM_RULE_FORMS);
  }, [formMode, routeParam?.ruleRefId, routeParam?.ruleId]);

  const validateSave = () => {
    const validationErrors = validateRule(selectedRule);
    setErrors(validationErrors);
    return isEmpty(validationErrors);
  };

  // Save rule to list of rules or update existing rule in list of rules
  const onRuleSave = () => {
    if (!validateSave()) {
      return;
    }
    const newRules = store.uiState.rules.getRules();
    // add new rule to list
    if (!selectedRule.ruleId) {
      selectedRule.ruleId = getUniqueKey();
      newRules.push(selectedRule);
    } else {
      // update existing rule in list
      const index = newRules.findIndex(
        rule => rule.ruleId === selectedRule.ruleId,
      );
      newRules[index] = selectedRule;
    }
    // sort by priority
    newRules.sort((a, b) => b.priority - a.priority);
    store.uiState.rules.setRules(newRules);

    snackbar.showMessage(
      formatMessage({
        id: 'RULE_SAVED_SUCCESSFULLY',
        defaultMessage: 'Rule saved successfully',
      }),
    );
  };

  const onNewRule = () => {
    navigate('/rules/create');
  };

  const onCancel = ruleId => {
    if (!ruleId) {
      setSelectedRule(emptyRule());
    } else {
      const originalRule = store.uiState.rules
        .getRules()
        .find(rule => rule.ruleId === ruleId);
      setSelectedRule(originalRule);
    }
    setErrors({});
  };

  // Sort conditions by combinator and conditionId, this required to keep order by combinator and condition
  const sortConditions = conditions => {
    conditions.sort((a, b) => (a.combinator ? 1 : -1));
    conditions.sort((a, b) => a.conditionId - b.conditionId);
  };

  // Add new condition
  const addCondition = decisionId => {
    const newRule = { ...selectedRule };
    if (!newRule.decision) {
      newRule.decision = emptyRuleDecision();
    }
    const node = findNodeByDecisionId(decisionId, newRule.decision);
    if (node) {
      node.conditions.push(emptyCondition());
    }
    sortConditions(newRule.decision.conditions);
    setSelectedRule(newRule);
  };

  const addGroup = () => {
    const newRule = { ...selectedRule };
    if (!newRule.decision) {
      newRule.decision = emptyRuleDecision();
    } else {
      newRule.decision.conditions.push(emptyRuleDecision());
    }
    sortConditions(newRule.decision.conditions);
    setSelectedRule(newRule);
  };

  const deleteGroup = decisionId => {
    const newRule = { ...selectedRule };
    newRule.decision.conditions = newRule.decision.conditions.filter(
      condition => condition.decisionId !== decisionId,
    );
    setSelectedRule(newRule);
  };

  // Add delete condition
  const deleteCondition = (decisionId, conditionId) => {
    const newRule = { ...selectedRule };
    const node = findNodeByDecisionId(decisionId, newRule.decision);
    if (node) {
      node.conditions = node.conditions.filter(
        condition => condition.conditionId !== conditionId,
      );
    }
    setSelectedRule(newRule);
  };

  // Update field, operator and value of condition
  const onFactFieldChange = ({ conditionId, value }) => {
    const { type } = factFieldMap[value];
    const operators = operatorsByTypeMap[type];
    const operator = operators?.length > 0 ? operators[0].value : null;
    const newRule = { ...selectedRule };
    const condition = findConditionById(
      conditionId,
      newRule.decision.conditions,
    );
    if (condition) {
      condition.field = value;
      condition.operator = operator;
      condition.value = null;
    }
    setSelectedRule(newRule);
  };

  const onOperatorFieldChange = ({ conditionId, value }) => {
    const newRule = { ...selectedRule };
    const condition = findConditionById(
      conditionId,
      newRule.decision.conditions,
    );
    if (condition) {
      condition.operator = value;
    }
    setSelectedRule(newRule);
  };

  const onCombinatorChange = ({ decisionId, value }) => {
    const newRule = { ...selectedRule };
    const node = findNodeByDecisionId(decisionId, newRule.decision);
    if (node) {
      node.combinator = value;
    }
    setSelectedRule(newRule);
  };

  const onValueFieldChange = ({ conditionId, value }) => {
    const newRule = { ...selectedRule };
    const condition = findConditionById(
      conditionId,
      newRule.decision.conditions,
    );
    if (condition) {
      condition.value = value;
    }
    setSelectedRule(newRule);
  };

  const onValueSourceChange = ({ conditionId, value }) => {
    const newRule = { ...selectedRule };
    const condition = findConditionById(
      conditionId,
      newRule.decision.conditions,
    );
    if (condition) {
      condition.valueSource = value;
    }
    setSelectedRule(newRule);
  };

  const onValueFactFieldChange = ({ conditionId, value }) => {
    const newRule = { ...selectedRule };
    const condition = findConditionById(
      conditionId,
      newRule.decision.conditions,
    );
    if (condition) {
      condition.value = {
        field: value,
      };
    }
    setSelectedRule(newRule);
  };

  const breadCrumb = [
    {
      label: formatMessage({
        id: 'CLAIM_RULES_SEARCH_MENU',
        defaultMessage: 'Claim Rules',
      }),
      href: '/rules/search?type=claim',
    },
  ];

  breadCrumb.push({
    label: formatMessage({
      id: 'CLAIM_RULE_DEFINITION',
      defaultMessage: 'Claim Rule Definition',
    }),
  });

  return (
    <Layout AppModule={AppModule}>
      <Header sx={{ mb: 2 }} />

      <div>
        <Grid2 container pt={0} pb={0} alignItems="center">
          <Grid2 xs={6}>
            <Breadcrumbs links={breadCrumb} />
          </Grid2>
          <Grid2 textAlign="right" xs={6}>
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                window.history.back();
              }}
              startIcon={<BackButton />}>
              <FormattedMessage id="BACK_BUTTON" defaultMessage="Back" />
            </Button>
          </Grid2>
        </Grid2>
      </div>
      {pageError && (
        <Grid2 mt={4}>
          <SpecialSection color="error.main">
            <Typography color="error">{pageError.errorMessage}</Typography>
          </SpecialSection>
        </Grid2>
      )}
      <Grid2 mt={3}>
        <Grid2>
          <PageSection
            label={formatMessage({
              id: 'CLAIM_RULE_DEFINITION',
              defaultMessage: 'Claim Rule Definition',
            })}
            headerIcon={RuleEditorIcon}>
            <RuleContainer
              rule={selectedRule}
              level={level}
              errors={errors}
              combinators={COMBINATORS}
              onRuleEdit={setSelectedRule}
              onRuleSave={onRuleSave}
              onCancel={onCancel}
              onNewRule={onNewRule}
              addCondition={addCondition}
              deleteCondition={deleteCondition}
              addGroup={addGroup}
              deleteGroup={deleteGroup}
              onFactFieldChange={onFactFieldChange}
              onOperatorFieldChange={onOperatorFieldChange}
              onCombinatorChange={onCombinatorChange}
              onValueFieldChange={onValueFieldChange}
              onValueSourceChange={onValueSourceChange}
              onValueFactFieldChange={onValueFactFieldChange}
              factFieldMap={factFieldMap}
              factFields={factFields}
              operatorsByTypeMap={operatorsByTypeMap}
              operatorsByValueMap={operatorsByValueMap}
            />
          </PageSection>
        </Grid2>
      </Grid2>
      {selectedRule?.ruleId && (
        <Grid2 sx={{ mt: 1 }}>
          <PageSection
            label={formatMessage({
              id: 'CLAIM_FORMS',
              defaultMessage: 'Claim Forms',
            })}
            headerIcon={RuleEditorIcon}>
            <RuleClaimForm store={store} data={ruleClaimForms} />
          </PageSection>
        </Grid2>
      )}
    </Layout>
  );
}
export default observer(RulesEditor);
