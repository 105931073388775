import { Box, Chip } from '@mui/material';
import { red } from '@mui/material/colors';
import { getFullWeekdayName, getColumnId } from './lib/utils';

function EventSlot({ dated, endTime, data }) {
  const weekday = getFullWeekdayName(dated);

  const endId = getColumnId({
    dated,
    weekday,
    time: endTime,
  });
  const endElement = document.getElementById(`${endId}`);

  if (endElement) {
    const endStart = endElement.getBoundingClientRect();

    const left = endStart?.left;
    const top = endStart?.top;
    const width = endStart.right + window.scrollX - 2 - left;
    const height = '8px';

    const keyName = JSON.stringify(endStart);

    return (
      <Box
        key={keyName}
        sx={{
          left,
          top,
          height,
          width,
          position: 'absolute',
          textAlign: 'center',
          fontSize: '2px',
          background: red[500],
        }}>
        <Chip label={data?.label} sx={{ marginTop: '-10px' }} color="error" />
      </Box>
    );
  }
  return null;
}

export default EventSlot;
