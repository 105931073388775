const CLAIM_RULE_FORMS = [
  {
    claimFormName: 'FiveStar-2024',
    enabled: 1,
    outcome: 'CLAIM_REVIEW',
    stopEngine: 0,
  },
  {
    claimFormName: 'FoodLoss-2024',
    enabled: 1,
    outcome: 'CLAIM_REVIEW',
    stopEngine: 0,
  },
  {
    claimFormName: 'BasicOEM-2024',
    enabled: 1,
    outcome: 'CLAIM_REVIEW',
    stopEngine: 0,
  },
  {
    claimFormName: 'BasicHVAC-2024',
    enabled: 1,
    outcome: 'CLAIM_REVIEW',
    stopEngine: 0,
  },
  {
    claimFormName: 'BasicClaim-2024',
    enabled: 1,
    outcome: 'CLAIM_REVIEW',
    stopEngine: 0,
  },
];

export default CLAIM_RULE_FORMS;
