import { Button } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

function NextJobButton({
  serviceJobNumber,
  nextJob = () => undefined,
  loading,
}) {
  return (
    <Button
      disabled={serviceJobNumber === null || loading}
      onClick={() => {
        nextJob(serviceJobNumber);
      }}
      variant="outlined"
      size="small"
      sx={{
        minWidth: '0px',
        padding: '0 2px',
        position: 'absolute',
        right: '33px',
        top: '5px',
      }}>
      <ChevronRightIcon />
    </Button>
  );
}

export default NextJobButton;
