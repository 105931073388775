const getAdditionalComponents = AppModule => {
  const { Components } = AppModule;
  const { Brands, Cities, States } = Components;

  const serviceAdministrator = 'OnPoint Warranty';
  const serviceAdministratorClientNumber = 'OPW';
  const addressCountryCode = 'US';
  const addressState = null;

  return {
    BrandsSelectFormControl: props => (
      <Brands
        {...props}
        serviceAdministrator={serviceAdministrator}
        serviceAdministratorClientNumber={serviceAdministratorClientNumber}
      />
    ),
    StatesSelectFormControl: props => <States {...props} />,
    CitiesSelectFormControl: props => (
      <Cities
        defaultCountryCode={addressCountryCode}
        defaultStateCode={addressState}
        {...props}
      />
    ),
  };
};

export default getAdditionalComponents;
