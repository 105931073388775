const Facts = [
  {
    label: 'Claim',
    options: [
      {
        field: 'addressStreet',
        label: 'Customer Address Street',
        type: 'text',
        path: '$.customer.customerAddress.addressStreet',
      },
      {
        field: 'serviceCompletionDate',
        label: 'Service Completion Date',
        type: 'date',
        path: '$.serviceCompletionDate',
      },
      {
        field: 'customerLastName',
        label: 'Customer Last Name',
        type: 'text',
        path: '$.customer.customerNameLast',
      },
      {
        field: 'customerCompanyName',
        label: 'Customer Company Name',
        type: 'text',
        path: '$.customer.customerCompanyName',
      },
      {
        field: 'serviceRequestedDate',
        label: 'Service Requested Date',
        type: 'date',
        path: '$.serviceRequestedDate',
      },
      {
        field: 'modelNumber',
        label: 'Model Number',
        type: 'text',
        path: '$.modelNumber',
      },
      {
        field: 'serialNumber',
        label: 'Serial Number',
        type: 'text',
        path: '$.serialNumber',
      },
      {
        field: 'serviceType',
        label: 'Service Type',
        type: 'text',
        path: '$.serviceTypeCodeId',
      },
      {
        field: 'serviceAdministratorClient',
        label: 'Service Administrator Client',
        type: 'text',
        path: '$.serviceAdministratorClient.id',
      },
      {
        field: 'serviceProviderJobNumber',
        label: 'Service Provider Job Number',
        type: 'text',
      },
      {
        field: 'serviceAdministratorJobNumber',
        label: 'Service Administrator Job Number',
        type: 'text',
      },
      {
        field: 'serviceAdministratorServiceContractNumber',
        label: 'Service Administrator Contract Number',
        type: 'text',
      },
      {
        field: 'currentClaim',
        label: 'Current Claim',
        type: 'multiselect',
        options: [
          {
            label: 'Model Number',
            value: 'modelNumber',
          },
          {
            label: 'Serial Number',
            value: 'serialNumber',
          },
          {
            label: 'Service Type',
            value: 'serviceType',
          },
          {
            label: 'Service Administrator Client',
            value: 'serviceAdministratorClient',
          },
          {
            label: 'Service Completion Date',
            value: 'serviceCompletionDate',
          },
          {
            label: 'Service Provider Job Number',
            value: 'serviceProviderJobNumber',
          },
          {
            label: 'Service Administrator Job Number',
            value: 'serviceAdministratorJobNumber',
          },
          {
            label: 'Service Administrator Contract Number',
            value: 'serviceAdministratorServiceContractNumber',
          },
        ],
      },
      {
        field: 'laborApprovedAmount',
        label: 'Labor Approved Amount',
        type: 'number',
      },
      {
        field: 'partsApprovedAmount',
        label: 'Parts Approved Amount',
        type: 'number',
      },
      {
        field: 'totalApprovedAmount',
        label: 'Total Approved Amount',
        type: 'number',
        path: '$.totalApprovedAmount',
      },
    ],
  },
  {
    label: 'Service Provider Maintenance',
    options: [
      {
        field: 'sendAllClaimsToReview',
        label: 'Send All Claims to Review',
        type: 'radio',
        options: [
          {
            label: 'Yes',
            value: 'YES',
          },
          {
            label: 'No',
            value: 'NO',
          },
        ],
      },
    ],
  },
  {
    label: 'Custom Functions',
    options: [
      {
        field: 'isTimeToSubmitClaimExceeded',
        label: 'isTimeToSubmitClaimExceeded',
        type: 'radio',
        options: [
          {
            label: 'True',
            value: 'TRUE',
          },
          {
            label: 'False',
            value: 'FALSE',
          },
        ],
      },
    ],
  },
  {
    label: 'Authorized Amounts',
    options: [
      {
        field: 'laborAuthorizedAmount',
        label: 'Labor Authorized Amount',
        type: 'number',
      },
      {
        field: 'partsAuthorizedAmount',
        label: 'Parts Authorized Amount',
        type: 'number',
      },
      {
        field: 'totalAuthorizedAmount',
        label: 'Total Authorized Amount',
        type: 'number',
      },
    ],
  },
];

export default Facts;
