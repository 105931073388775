import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { observer } from 'mobx-react';
import { Box } from '@mui/material';
import {
  LanguageSelector,
  NotificationButton,
  NotificationType,
  RecentHistoryButton,
  UserMenu,
  useDataProvider,
  useStore,
} from '@servicexcelerator/ui-design-system';
import { useIntl } from 'react-intl';
import { useAuth } from 'react-oidc-context';
import getDomain from '@utils/GetDomain';

function Header({ children, ...props }) {
  const auth = useAuth();
  const provider =
    process.env.NODE_ENV === 'development' ? 'serviceJob' : 'default';

  const { formatMessage } = useIntl();
  const store = useStore();
  const { getOne } = useDataProvider();
  const { getOne: getOneSJ } = useDataProvider(provider);

  const user = {
    firstName: auth?.user?.profile?.given_name,
    lastName: auth?.user?.profile?.family_name,
    subText: 'Xcelerator',
  };

  const showInCurrentIteration = false;

  // TODO: To be added later
  // eslint-disable-next-line no-unused-vars
  const settings = {
    name: 'settings',
    label: formatMessage({
      id: 'SETTINGS_MENU',
      defaultMessage: 'Settings',
    }),
    onClick: () => undefined,
    icon: SettingsIcon,
  };

  const menuItems = [
    {
      name: 'logout',
      label: formatMessage({
        id: 'LOGOUT_MENU',
        defaultMessage: 'Logout',
      }),
      onClick: async () => {
        await store.clearStoredData();
        await auth.signoutRedirect({
          post_logout_redirect_uri: getDomain(
            process.env.REACT_APP_API_HOST,
            '',
            false,
          ),
        });
      },
      icon: LogoutIcon,
    },
  ];

  const languageChanged = async (event, language) => {
    if (language) {
      const claimResult = await getOne(
        '/claims/v1/claim/precreate/meta',
        null,
        {
          headers: {
            common: {
              'x-lang': language,
              'Accept-Language': language,
            },
          },
        },
      );

      const servicejobResult = await getOneSJ(
        '/servicejob/v1/servicejob/precreate/meta',
        null,
        {
          headers: {
            common: {
              'x-lang': language,
              'Accept-Language': language,
            },
          },
        },
      );

      const masterDataClaimResult = claimResult?.data || {};
      const masterDataServiceJobResult = servicejobResult?.data || {};
      const masterDataFinal = {
        ...masterDataClaimResult,
        ...masterDataServiceJobResult,
      };

      store.domain.masterData.set(masterDataFinal);
      store.domain.locale.set(language);
    }
  };

  return (
    <Grid2 sx={{ mt: 2, ml: 2, ...props?.sx }} container>
      <Grid2 xs alignSelf="center" sx={{ mr: 2 }}>
        {children}
      </Grid2>
      <Grid2
        container
        xs="auto"
        spacing={2}
        alignItems="center"
        justifyContent="end">
        <Grid2>
          <Box
            sx={{
              width: '1px',
              height: '40px',
              backgroundColor: 'secondary.dark',
              display: 'flex',
            }}>
            &nbsp;
          </Box>
        </Grid2>
        <Grid2>
          <LanguageSelector
            defaultLanguage={store.domain.locale.language}
            languagesSupported={store.domain.locale.getSupportedLanguages()}
            onChange={(event, language) => languageChanged(event, language)}
          />
        </Grid2>
        {showInCurrentIteration && (
          <Grid2>
            <RecentHistoryButton
              sideBarTitle={formatMessage({
                id: 'RECENT_HISTORY_BUTTON',
                defaultMessage: 'Recent History',
              })}>
              &nbsp;
            </RecentHistoryButton>
          </Grid2>
        )}
        {showInCurrentIteration && (
          <Grid2>
            <NotificationButton type={NotificationType.UNREAD} count={2} />
          </Grid2>
        )}
        <Grid2>
          <UserMenu menuItems={menuItems} user={user} />
        </Grid2>
      </Grid2>
    </Grid2>
  );
}

export default observer(Header);
