const NETWORK_TYPE = {
  IN_NETWORK: 'IN_NETWORK',
  OUT_NETWORK_COMMUNITY: 'OUT_NETWORK_COMMUNITY',
  OUT_NETWORK_INDEPENDENT: 'OUT_NETWORK_INDEPENDENT',
};

const SERVICE_JOB_STATUS = {
  INC: 'INC',
  PAC: 'PAC',
  CAN: 'CAN',
  ACC: 'ACC',
  COM: 'COM',
  PRA: 'PRA',
  PPT: 'PPT',
  PCS: 'PCS',
  REJ: 'REJ',
};

const SERVICE_JOB_STATUS_LABEL = {
  INC: 'Incomplete',
  PAC: 'Pending Acceptance',
  CAN: 'Cancelled',
  ACC: 'Accepted',
  REJ: 'Rejected',
  COM: 'Completed',
  PRA: 'Pending Preauthorization',
  PPT: 'Pending Parts',
  PCS: 'Pending Customer Scheduling',
};

const RESEND_MESSAGE_OPTIONS = {
  JOB_SCHEDULED: 'Job Scheduled',
  PARTS_ORDERED: 'Parts Ordered',
  PARTS_DELAYED: 'Parts Delayed',
  PARTS_RECEIVED: 'Parts Received',
  JOB_REMINDER: 'Job Reminder',
  TECHNICIAN_ASSIGNED: 'Technician Assigned',
  JOB_COMPLETE: 'Job Complete',
};

export {
  NETWORK_TYPE,
  SERVICE_JOB_STATUS,
  SERVICE_JOB_STATUS_LABEL,
  RESEND_MESSAGE_OPTIONS,
};
