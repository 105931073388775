import { Box, Chip, Rating, Typography, colors, styled } from '@mui/material';
import { getFullWeekdayName, getColumnId, convertTime } from './lib/utils';

const Event = styled(Box)(({ theme }) => ({
  textAlign: 'left',
  padding: '10px',
  background: theme.palette.primary.light,
  display: 'block',
  borderRadius: 8,
  opacity: '0.8',
  border: `1px solid ${theme.palette.primary.dark}`,
}));

const StyledRating = styled(Rating, {
  shouldForwardProp: props => !['special'].includes(props),
})(({ theme, special }) => ({
  '& .MuiRating-iconFilled': {
    color: special ? theme.palette.success.dark : theme.palette.primary.dark,
  },
  '& .MuiRating-iconHover': {
    color: theme.palette.secondary.main,
  },
}));

function BookingSlot({ dated, startTime, endTime, data }) {
  const weekday = getFullWeekdayName(dated);
  const startId = getColumnId({
    dated,
    weekday,
    time: startTime,
  });
  const endId = getColumnId({
    dated,
    weekday,
    time: endTime,
  });
  const startElement = document.getElementById(`${startId}`);
  const endElement = document.getElementById(`${endId}`);
  if (startElement && endElement) {
    const rectStart = startElement.getBoundingClientRect();
    const endStart = endElement.getBoundingClientRect();
    const left = rectStart.left + 2;
    const top = rectStart.top + 2;
    const width = endStart.right - 2 - left;
    const height = endStart.bottom - 2 - top;

    const color = data?.stars === 5 ? colors.green[100] : null;

    return (
      <Event
        sx={{ left, top, width, height, position: 'absolute', bgcolor: color }}>
        <Typography variant="body1" fontWeight={500}>
          {convertTime(startTime).toLowerCase()} &#x2022;{' '}
          {convertTime(endTime).toLowerCase()}
        </Typography>
        <Box>
          <StyledRating
            readOnly
            size="small"
            value={data?.stars}
            special={data?.stars === 5}
          />
          {data?.stars === 5 && (
            <Chip label="Recommended" size="small" color="success" />
          )}
        </Box>
      </Event>
    );
  }
  return null;
}

export default BookingSlot;
