import { Autocomplete, FormControl, TextField } from '@mui/material';

function OperatorField(props) {
  const {
    conditionId,
    field,
    operator,
    onOperatorFieldChange,
    operatorError,
    factFieldMap,
    operatorsByTypeMap,
    operatorsByValueMap,
  } = props;
  const filter = field ? factFieldMap[field] : null;
  const options = filter ? operatorsByTypeMap[filter.type] : [];
  const operatorValue = operator ? operatorsByValueMap[operator] : null;

  return (
    <FormControl fullWidth style={{ minWidth: 270 }}>
      <Autocomplete
        size="small"
        fullWidth
        style={{ width: 'auto' }}
        disableClearable
        groupBy={option => option.group}
        getOptionLabel={option => option.label}
        isOptionEqualToValue={(option, value) => option.value === value?.value}
        options={options}
        renderInput={params => (
          <TextField
            {...params}
            placeholder="Operator"
            size="small"
            variant="outlined"
            error={!!operatorError?.errorMessage}
            helperText={operatorError?.errorMessage}
          />
        )}
        value={operatorValue}
        onChange={(event, selected) => {
          const { value } = selected;
          onOperatorFieldChange({ conditionId, value });
        }}
      />
    </FormControl>
  );
}
export default OperatorField;
