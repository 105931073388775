import { Button, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {
  DYNAMIC_FORM_MODE,
  SelectFormControl,
  useDataProvider,
  useSnackbar,
  useStore,
} from '@servicexcelerator/ui-design-system';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

function ResendMessage(props) {
  const provider =
    process.env.NODE_ENV === 'development' ? 'notification' : 'default'; // serviceJob

  const store = useStore();
  const snackbar = useSnackbar();
  const { formatMessage } = useIntl();
  const { createOne } = useDataProvider(provider);
  const [error, setError] = useState(false);

  const { AppModule, customProps, serviceJobNumber, formId } = props;
  const { Constants, Components } = AppModule;
  const { RESEND_MESSAGE_OPTIONS, COMPANY_TYPE } = Constants;
  const { useUserAccess } = Components;

  const userAccess = useUserAccess();

  const isSA = userAccess.companyType === COMPANY_TYPE.SERVICE_ADMINISTRATOR;

  const resendMessageOptions = Object.entries(RESEND_MESSAGE_OPTIONS).map(
    ([key, value]) => ({ label: value, value: key }),
  );

  const sendMessage = async () => {
    const messageType = store.domain.formState.getState(formId)?.resendMessage;

    if (messageType) {
      setError(false);
      const data = await createOne('/notifications/v1/notifications', {
        refNumber: serviceJobNumber,
        eventType: messageType,
      });
      if (data?.data) {
        snackbar.showMessage(
          formatMessage({
            id: 'MESSAGE_RESENT_SUCCESSFULLY',
            defaultMessage: 'Message was resent successfully',
          }),
        );
      } else {
        snackbar.showMessage({
          type: 'error',
          data: formatMessage({
            id: 'MESSAGE_COULD_NOT_BE_SENT',
            defaultMessage: 'Error: Message could not be sent',
          }),
        });
      }
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    const messageType = store.domain.formState.getState(formId)?.resendMessage;

    if (messageType) {
      setError(false);
    }
  }, [store.domain.formState.getState(formId)?.resendMessage]);

  if (!isSA) {
    return null;
  }

  return (
    <Grid2 container spacing={2} {...customProps}>
      <Grid2 xs>
        <SelectFormControl
          key="resendMessage"
          staticOptions={resendMessageOptions}
          fullWidth
          {...props}
          formMode={DYNAMIC_FORM_MODE.EDIT}
        />
        {error && (
          <Typography color="error.main">
            <FormattedMessage
              id="PLEASE_SELECT_MESSAGE_TYPE"
              defaultMessage="Please select message type"
            />
          </Typography>
        )}
      </Grid2>
      <Grid2 xs>
        <Button
          sx={{ marginTop: '30px' }}
          size="small"
          variant="contained"
          onClick={() => sendMessage()}>
          <FormattedMessage
            id="RESEND_MESSAGE"
            defaultMessage="Resend Message"
          />
        </Button>
      </Grid2>
    </Grid2>
  );
}

export default observer(ResendMessage);
