import { FormControl, TextField, Autocomplete } from '@mui/material';
import { DatePicker } from '@servicexcelerator/ui-design-system';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

function ValueField(props) {
  const { field, conditionId, fieldValue, onValueFieldChange, factFieldMap } =
    props;
  const filter = field ? { ...factFieldMap[field] } : { type: null };

  const readNumericValue = numericValue =>
    numericValue !== null && numericValue !== undefined ? numericValue : '';

  switch (filter.type) {
    case 'date':
      return (
        <DatePicker
          size="small"
          fullWidth
          clearable
          slotProps={{ textField: { size: 'small' } }}
          value={fieldValue}
          onChange={dateValue => {
            onValueFieldChange({ conditionId, value: dateValue });
          }}
        />
      );
    case 'integer':
      return (
        <FormControl fullWidth>
          <TextField
            type="number"
            size="small"
            value={readNumericValue(fieldValue)}
            onChange={event => {
              onValueFieldChange({
                conditionId,
                value: event.target.value,
              });
            }}
            onKeyDown={event => {
              if (/\.|,/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </FormControl>
      );
    case 'number':
      return (
        <FormControl fullWidth>
          <TextField
            type="number"
            size="small"
            value={readNumericValue(fieldValue)}
            onChange={event => {
              onValueFieldChange({
                conditionId,
                value: event.target.value,
              });
            }}
          />
        </FormControl>
      );
    case 'multiselect':
      return (
        <FormControl fullWidth style={{ minWidth: 270 }}>
          <Autocomplete
            filterSelectedOptions
            fullWidth
            multiple
            openOnFocus
            disableCloseOnSelect
            getOptionLabel={option => option.label}
            limitTags={-1}
            options={filter.options}
            renderInput={params => (
              <TextField {...params} size="small" variant="outlined" />
            )}
            size="small"
            style={{ width: 'auto' }}
            value={filter.options.filter(op => fieldValue?.includes(op.value))}
            onChange={(event, selected) => {
              onValueFieldChange({
                conditionId,
                value: (selected || []).map(item => item.value),
              });
            }}
          />
        </FormControl>
      );
    case 'radio':
      return (
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={fieldValue || 'YES'}
            onChange={event => {
              onValueFieldChange({
                conditionId,
                value: event.target.value,
              });
            }}>
            {filter.options.map(item => (
              <FormControlLabel
                key={item.value}
                value={item.value}
                control={<Radio />}
                label={item.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      );
    default:
      return (
        <FormControl fullWidth>
          <TextField
            size="small"
            value={fieldValue || ''}
            onChange={event => {
              onValueFieldChange({
                conditionId,
                value: event.target.value,
              });
            }}
          />
        </FormControl>
      );
  }
}

export default ValueField;
