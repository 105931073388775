import OutNetworkCommunity from './symbol/OutNetworkCommunity';
import OutNetworkIndependent from './symbol/OutNetworkIndependent';
import InNetwork from './symbol/InNetwork';
import describeArc from '../../lib/describeArc';

function ServiceProvider({
  serviceProviderId,
  services,
  onClickHandler,
  theme,
  AppModule,
  index,
  id,
  mapObj,
}) {
  const { Constants } = AppModule;
  const { NETWORK_TYPE } = Constants;

  const radius = 20;
  const strokeWidth = 4;
  const viewBoxSize = radius * 2 + strokeWidth * 2;

  const arcs = {
    newJob: { startAngle: 0, endAngle: 90 },
    zip: { startAngle: 90, endAngle: 180 },
    brand: { startAngle: 180, endAngle: 270 },
    productType: { startAngle: 270, endAngle: 0 },
  };

  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  svg.setAttribute('id', id);
  svg.setAttribute('data-index', index);
  svg.setAttribute('class', `map_clickable`);
  svg.setAttribute('width', viewBoxSize);
  svg.setAttribute('height', viewBoxSize);
  svg.setAttribute('viewBox', `0 0 ${viewBoxSize} ${viewBoxSize}`);

  const outerCircle = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'circle',
  );

  outerCircle.setAttribute('cx', viewBoxSize / 2);
  outerCircle.setAttribute('cy', viewBoxSize / 2);
  outerCircle.setAttribute('r', radius);
  outerCircle.setAttribute('stroke', theme.palette.warning.main); // '#90cee3'
  outerCircle.setAttribute('stroke-width', strokeWidth);
  outerCircle.setAttribute('id', 'outer');
  outerCircle.setAttribute('display', 'none');

  svg.appendChild(outerCircle);

  const innerCircle = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'circle',
  );

  innerCircle.setAttribute('cx', viewBoxSize / 2);
  innerCircle.setAttribute('cy', viewBoxSize / 2);
  innerCircle.setAttribute('r', radius - strokeWidth);
  innerCircle.setAttribute('fill', 'white');
  innerCircle.setAttribute('stroke', '#dedede');
  innerCircle.setAttribute('stroke-width', strokeWidth);

  svg.appendChild(innerCircle);

  ['newJob', 'zip', 'brand', 'productType'].every(item => {
    if (services[item]) {
      const d = describeArc(
        viewBoxSize / 2,
        viewBoxSize / 2,
        radius - strokeWidth,
        arcs[item].startAngle,
        arcs[item].endAngle,
      );
      const path = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path',
      );
      path.setAttribute('d', d);
      path.setAttribute('fill', 'none');
      path.setAttribute('stroke', '#009ACD');
      path.setAttribute('stroke-width', strokeWidth);
      svg.appendChild(path);
    }
    return true;
  });

  if (NETWORK_TYPE.OUT_NETWORK_COMMUNITY === services.networkType) {
    svg.appendChild(OutNetworkCommunity(theme));
  } else if (NETWORK_TYPE.OUT_NETWORK_INDEPENDENT === services.networkType) {
    svg.appendChild(OutNetworkIndependent(theme));
  } else if (NETWORK_TYPE.IN_NETWORK === services.networkType) {
    svg.appendChild(InNetwork(theme));
  }

  const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
  text.setAttribute('fill', '#000000');
  text.setAttribute('font-weight', '500');
  text.setAttribute('x', 24);
  text.setAttribute('y', 27);

  const textNode = document.createTextNode((index + 1).toString());
  text.appendChild(textNode);

  svg.appendChild(text);

  svg.addEventListener('click', e => {
    onClickHandler(e, mapObj, serviceProviderId, svg);
  });

  return svg;
}

export default ServiceProvider;
