import { Button } from '@mui/material';
import { useTheme } from '@emotion/react';
import MyLocationIcon from '@mui/icons-material/MyLocation';

export default function CenterMapButton({ map, lat, lng, zoom }) {
  const theme = useTheme();
  return (
    <div>
      <Button
        variant="contained"
        sx={{
          boxShadow: theme.shadows[4],
          position: 'absolute',
          right: '10px',
          top: '50px',
          background: '#ffffff',
          paddingLeft: '10px',
          paddingRight: '10px',
          minWidth: '0px',
          color: '#000000',
          ':hover': {
            background: '#efefef',
          },
        }}
        onClick={() =>
          map.flyTo({
            center: [lng, lat],
            zoom,
            speed: 7,
            essential: true,
          })
        }>
        <MyLocationIcon fontSize="small" sx={{ marginLeft: '5px' }} />
      </Button>
    </div>
  );
}
