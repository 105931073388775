import uiSchema from './index.json';
import uiSchemaCustomer from './customer_section.json';
import uiSchemaProduct from './product_section.json';
import uiSchemaService from './service_section.json';
import uiSchemaBasicInfo from './basic_info.json';
import uiAuthorization from './authorization_section.json';
import uiAdditionalInfo from './additional_section.json';
import uiNotesAttachment from './notesattachment_section.json';
import uiServiceHistory from './service_history_section.json';
import uiDispatch from './dispatch_section.json';
import uiSchemaCustomerSPPAC from './customer_section_sp_pac.json';
import uiSchemaCustomerSPACC from './customer_section_sp_acc.json';
import uiSchemaProductSPPAC from './product_section_sp_pac.json';
import uiSchemaServiceSPPAC from './service_section_sp_pac.json';
import uiJob from './job_section.json';
import uiParts from './parts_section.json';
import createAjv from './ajv/create.json';
import updateAjv from './ajv/update.json';

const getSAUISchema = () => {
  uiSchema['ui:children'] = [
    uiSchemaBasicInfo,
    uiSchemaCustomer,
    uiSchemaProduct,
    uiSchemaService,
    uiJob,
    uiDispatch,
    uiParts,
    uiAuthorization,
    uiServiceHistory,
    uiNotesAttachment,
    uiAdditionalInfo,
  ];
  return uiSchema;
};

const getSPUISchema = status => {
  if (status === 'PAC') {
    uiSchema['ui:children'] = [
      uiSchemaCustomerSPPAC,
      uiSchemaProductSPPAC,
      uiSchemaServiceSPPAC,
    ];
    return uiSchema;
  }

  uiSchema['ui:children'] = [
    uiSchemaBasicInfo,
    uiSchemaCustomerSPACC,
    uiSchemaProduct,
    uiSchemaService,
    uiJob,
    uiParts,
    uiAuthorization,
    uiServiceHistory,
    uiNotesAttachment,
    uiAdditionalInfo,
  ];
  return uiSchema;
};

const getAjvSchema = () => ({
  create: createAjv,
  update: updateAjv,
});

const getSchema = (isSA, status) => {
  if (isSA) {
    return {
      ui: getSAUISchema(),
      ajv: getAjvSchema(),
    };
  }
  if (!isSA) {
    return {
      ui: getSPUISchema(status),
      ajv: getAjvSchema(),
    };
  }

  return null;
};

export default getSchema;
