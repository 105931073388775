import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function SidebarCloseButton({ setOpen, open }) {
  return (
    <IconButton
      sx={{
        position: 'absolute',
        right: '0px',
        top: '0px',
        color: '#000000',
      }}
      onClick={() => {
        setOpen(!open);
      }}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );
}

export default SidebarCloseButton;
