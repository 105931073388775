import { useParams } from 'react-router-dom';
import { useDataProvider } from '@servicexcelerator/ui-design-system';
import {
  Backdrop,
  Box,
  CircularProgress,
  Popover,
  Tab,
  Tabs,
  useTheme,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { useEffect, useRef, useState } from 'react';
import SXCLMap from '../../ui/SXCLMap';
import {
  SidebarWidget,
  LegendsWidget,
  JobDetailsTabContent,
  FilterTabContent,
  BackButton,
  CenterMapButton,
  MapLib,
  ServiceProviderInfoWidget,
} from '../../ui/MapControls';
import CustomTabPanel from './CustomTabPanel';

function ServiceJobScheduling({ AppModule }) {
  const defaults = {
    zoom: 14,
    lat: 40.56378846993402,
    lng: -99.9599284137568,
  };
  const mapElements = useRef({ sj: null, sps: [], selectedSPMarker: null });
  const [map, setMap] = useState(null);

  const routeParam = useParams();
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const [spPopOver, setSPPopOver] = useState({ open: false, top: 0, left: 0 });
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [jobInfo, setJobInfo] = useState(null);
  const [currentSJNumber, setCurrentSJNumber] = useState(
    routeParam?.serviceJobId,
  );
  const [selectedServiceProvider, setSelectedServiceProvider] = useState(null);

  const provider =
    process.env.NODE_ENV === 'development' ? 'serviceJob' : 'default';
  const { getOne } = useDataProvider(provider);

  const getServiceJob = async serviceJobNumber => {
    const result = await getOne(
      `/servicejob/v1/servicejob/scheduling/${serviceJobNumber}`,
    );

    return result?.data;
  };

  const nextJob = serviceJobNumber => {
    setCurrentSJNumber(serviceJobNumber);
  };

  const previousJob = serviceJobNumber => {
    setCurrentSJNumber(serviceJobNumber);
  };

  const onServiceProviderClick = (e, pageMap, spId, marker) => {
    mapElements.current.selectedSPMarker = marker;

    const index = marker.getAttribute('data-index');
    const layerName = MapLib.serviceProvider.serviceJob.getLayerName(index);
    const serviceProviders = jobInfo?.current?.serviceProviders;

    MapLib.serviceProvider.unHighlightAllMarkers(serviceProviders, [spId]);
    MapLib.serviceProvider.serviceJob.unHighlightAllLayers(
      pageMap,
      serviceProviders,
      [spId],
      currentSJNumber,
    );

    MapLib.serviceProvider.highlightMarker(spId);
    MapLib.serviceProvider.serviceJob.highlightLayer(pageMap, layerName);

    setSelectedServiceProvider(
      jobInfo?.current.serviceProviders.find(
        sp => sp.serviceProviderId === spId,
      ),
    );

    setSPPopOver({
      open: true,
      left: e.clientX,
      top: e.clientY + 10,
    });
  };

  const renderMapInfo = async mapObj => {
    const lng = jobInfo?.current?.longitude;
    const lat = jobInfo?.current?.latitude;

    MapLib.core.clean({ mapElements });

    MapLib.serviceJob.applyToMapAsMarker({
      lng,
      lat,
      mapElements,
      mapObj,
      zoom: defaults.zoom,
      coordinatesToBoundTo: jobInfo?.current?.serviceProviders.map(sp => [
        sp.latitude,
        sp.longitude,
      ]),
    });

    await MapLib.serviceProvider.applyToMapAsMarkers({
      mapObj,
      serviceProviders: jobInfo?.current.serviceProviders,
      onServiceProviderClick,
      mapElements,
      theme,
      AppModule,
      currentSJNumber,
    });

    jobInfo?.current?.serviceProviders.every((sp, index) => {
      const layerName = MapLib.serviceProvider.serviceJob.getLayerName(index);

      map.on('click', layerName, MapLib.serviceProvider.serviceJob.onClick);
      return true;
    });
  };

  const onMapReady = async mapObj => {
    MapLib.mapObj = mapObj;
    setMap(mapObj);
    // attachEventForServiceProviderServiceJob(false, mapObj);
  };

  useEffect(() => {
    const fetchInfo = async () => {
      setLoading(true);
      const result = await getServiceJob(currentSJNumber);

      jobInfo?.current?.serviceProviders.every((sp, index) => {
        const layerName = MapLib.serviceProvider.serviceJob.getLayerName(index);
        map.off('click', layerName, MapLib.serviceProvider.serviceJob.onClick);
        return true;
      });

      MapLib.jobInfo = result;
      setJobInfo(result);
      setLoading(false);
    };

    if (routeParam?.serviceJobId) {
      if (map) {
        fetchInfo();
      }
    } else {
      throw new Error(
        formatMessage({
          id: 'SERVICEJOB_INFO_NOT_AVAILABLE',
          defaultMessage: 'Could not find job information.',
        }),
      );
    }
  }, [currentSJNumber, map]);

  useEffect(() => {
    if (jobInfo?.current) {
      renderMapInfo(map);
    }
  }, [jobInfo?.current]);

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: themeobj => themeobj.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Popover
        open={spPopOver.open}
        onClose={() => {
          setSPPopOver({
            open: false,
            left: 0,
            top: 0,
          });

          const index =
            mapElements.current.selectedSPMarker.getAttribute('data-index');
          const layerName =
            MapLib.serviceProvider.serviceJob.getLayerName(index);
          MapLib.serviceProvider.serviceJob.unHighlightLayer(map, layerName);
          mapElements.current.selectedSPMarker
            .getElementById('outer')
            .setAttribute('display', 'none');
        }}
        anchorReference="anchorPosition"
        anchorPosition={{ top: spPopOver.top, left: spPopOver.left }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <ServiceProviderInfoWidget
          serviceProvider={selectedServiceProvider}
          AppModule={AppModule}
        />
      </Popover>

      <SXCLMap onReady={mapObj => onMapReady(mapObj)} {...defaults} />

      {!loading && (
        <CenterMapButton
          map={map}
          lat={jobInfo?.current?.latitude}
          lng={jobInfo?.current?.longitude}
          zoom={defaults.zoom}
        />
      )}
      <LegendsWidget />
      <BackButton />
      <SidebarWidget
        AppModule={AppModule}
        jobData={jobInfo}
        nextJob={nextJob}
        previousJob={previousJob}
        loading={loading}>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              marginBottom: '20px',
            }}>
            <Tabs
              value={tabValue}
              onChange={(event, newValue) => setTabValue(newValue)}>
              <Tab
                label={formatMessage({
                  id: 'SERVICEJOB_DETAILS',
                  description: 'Job Details',
                })}
                id="sidebar-tabpanel-0"
              />
              <Tab
                label={formatMessage({
                  id: 'SERVICEJOB_MAP_FILTER',
                  description: 'Filter',
                })}
                id="sidebar-tabpanel-1"
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={tabValue} index={0}>
            <JobDetailsTabContent
              job={jobInfo?.current}
              AppModule={AppModule}
              loading={loading}
            />
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            <FilterTabContent loading={loading} job={jobInfo?.current} />
          </CustomTabPanel>
        </Box>
      </SidebarWidget>
    </div>
  );
}

export default ServiceJobScheduling;
