/* eslint-disable no-restricted-syntax */
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import {
  LoadingEvent,
  useDataProvider,
  useStore,
} from '@servicexcelerator/ui-design-system';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { capitalCase } from 'case-anything';
import AuditTable from './AuditTable';

function AuditFeed({ claimNumber, formId, AppModule }) {
  const { formatMessage } = useIntl();
  const store = useStore();
  const defaultMessage = formatMessage({
    id: 'NO_DATA_EXISTS',
    defaultMessage: 'No data exists',
  });
  const { Constants } = AppModule;
  const { AUDIT_FIELD_MAPPER } = Constants;

  const { getOne } = useDataProvider();
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const result = await getOne(
        `/servicejob/v1/servicejob/${claimNumber}/audit`,
      );
      if (result) {
        const dataSet = [];
        if (result?.data?.auditData) {
          // eslint-disable-next-line no-unsafe-optional-chaining
          for (const auditRow of result?.data?.auditData) {
            for (const row of auditRow.auditDetails) {
              dataSet.push({
                createdBy: auditRow.createdBy,
                createdDateTime: auditRow.createdDateTime,
                ...row,
                id: capitalCase(row.fieldName),
                ...AUDIT_FIELD_MAPPER[row.fieldName],
              });
            }
          }
        }
        setTableData(dataSet);
      }
      setLoading(false);
    };

    if (claimNumber) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [claimNumber, store.domain.formState.formState[formId]?.recordVersion]);

  return (
    <Grid2>
      {loading ? (
        <LoadingEvent>
          <FormattedMessage id="FETCHING_DATA" defaultMessage="Fetching Data" />
        </LoadingEvent>
      ) : (
        <AuditTable
          data={tableData}
          defaultMessage={defaultMessage}
          AppModule={AppModule}
        />
      )}
    </Grid2>
  );
}

export default observer(AuditFeed);
