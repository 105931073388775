import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {
  AsyncSelectFormControl,
  useDataProvider,
  useStore,
} from '@servicexcelerator/ui-design-system';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';

function States(props) {
  const { getMany } = useDataProvider();
  const store = useStore();
  const { formId, customProps } = props;

  const [currentCountryCode, setCurrentCountryCode] = useState(null);

  const getStates = async (input, countryCode) => {
    if (countryCode) {
      const result = await getMany(`/claims/v1/location/${countryCode}/states`);
      const states = result?.data || [];
      const matchingStates =
        states.filter(
          item =>
            item?.name?.toLowerCase()?.indexOf(input?.toLowerCase()) > -1 ||
            item?.abbreviation?.toLowerCase()?.indexOf(input?.toLowerCase()) >
              -1,
        ) || states;
      return matchingStates;
    }
    return [];
  };

  useEffect(() => {
    const cc =
      store.domain.formState.getState(formId)?.customer?.customerAddress
        ?.addressCountryCode;

    setCurrentCountryCode(cc);
  }, [
    store.domain.formState.formState[formId]?.customer?.customerAddress
      ?.addressCountryCode,
  ]);

  return (
    <Grid2 xs {...customProps}>
      <AsyncSelectFormControl
        key={currentCountryCode}
        optionLabelKey="name"
        optionValueKey="abbreviation"
        fullWidth
        fetchOptions={input => getStates(input, currentCountryCode)}
        fetchOptionsById={input => getStates(input, currentCountryCode)}
        {...props}
      />
    </Grid2>
  );
}

export default observer(States);
