import { Box } from '@mui/material';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      style={{ overflow: 'auto', padding: '10px' }}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}>
      {children}
    </Box>
  );
}

export default CustomTabPanel;
