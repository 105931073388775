import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button } from '@mui/material';

function PreviousDateButton({ selectedDate, setSelectedDate }) {
  return (
    <Button
      onClick={() => {
        const newDate = selectedDate.subtract(1, 'day');
        setSelectedDate(newDate);
      }}
      variant="outlined"
      size="small"
      sx={{
        minWidth: '0px',
        marginLeft: '5px',
        marginRight: '5px',
        marginTop: '5px',
        padding: '0 2px',
      }}>
      <ChevronLeftIcon />
    </Button>
  );
}

export default PreviousDateButton;
