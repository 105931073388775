const acceptServiceJob = async (
  sj,
  confirmationPopUpRef,
  formatMessage,
  snackbar,
  updateOne,
  ErrorMessage,
) => {
  const showError = err => {
    snackbar.showMessage({
      type: 'error',
      data:
        err?.message ||
        formatMessage({
          id: 'ERROR_COULD_NOT_ASSIGN_JOB',
          defaultMessage: 'Error: Could not assign the job',
        }),
    });
  };
  confirmationPopUpRef.current.init({
    message: formatMessage({
      id: 'DO_YOU_WANT_TO_ACCEPT_THIS_JOB',
      defaultMessage: 'Do you want to accept this job?',
    }),
    positiveButtonText: formatMessage({
      id: 'YES',
      defaultMessage: 'Yes',
    }),
    negativeButtonText: formatMessage({
      id: 'CANCEL',
      defaultMessage: 'Cancel',
    }),
    loadingMessage: formatMessage({
      id: 'PROCESSING',
      defaultMessage: 'processing...',
    }),
    title: formatMessage({
      id: 'ACCEPT_JOB',
      defaultMessage: 'Accept Job',
    }),
    onChange: async value => {
      if (value) {
        confirmationPopUpRef.current.setLoading(true);
        try {
          const result = await updateOne(
            `servicejob/v1/servicejob/${sj.serviceJobNumber}/operation/accept`,
          );

          if (result?.data) {
            window.location.reload();
          } else {
            showError(ErrorMessage(result));
          }
        } catch (err) {
          showError(err);
        }
      }
      confirmationPopUpRef.current.setOpen(false);
    },
  });
  confirmationPopUpRef.current.setOpen(true);
};

export default acceptServiceJob;
