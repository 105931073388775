import axios from 'axios';
import { AttachFileRounded, Delete, FilePresent } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {
  ConfirmationPopUp,
  useDataProvider,
  useSnackbar,
} from '@servicexcelerator/ui-design-system';
import { capitalCase } from 'case-anything';
import { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAuth } from 'react-oidc-context';

function FileView(props) {
  const {
    id = null,
    serviceJobNumber,
    noteId: noteIdProps = null,
    attachmentId: attachmentIdProp = null,
    attachmentType: attachmentTypeProp = '',
    description: descriptionProp = '',
    fileName: fileNameProp = '',
    uploadedBy = null,
    reference,
    removeAttachment,
    onNoteChanged,
    AppModule,
  } = props;

  const provider =
    process.env.NODE_ENV === 'development' ? 'serviceJob' : 'default';

  const { Constants } = AppModule;
  const { ATTACHMENT_TYPES, SUPPORTED_NOTES_ATTACHMENT_FILETYPES } = Constants;

  const auth = useAuth();
  const theme = useTheme();
  const { getOne, updateOne, deleteOne } = useDataProvider(provider);
  const confirmationPopUpRef = useRef();
  const { formatMessage } = useIntl();
  const snackbar = useSnackbar();

  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [fetchingDownloadUrl, setFetchingDownloadUrl] = useState(true);
  const [savedFile, setSavedFile] = useState({
    fileName: fileNameProp,
    attachmentType: attachmentTypeProp,
    id,
    description: descriptionProp,
    attachmentId: attachmentIdProp,
    noteId: noteIdProps,
  });
  const [showOther, setShowOther] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null);

  const currentUser = {
    firstName: auth?.user?.profile?.given_name,
    lastName: auth?.user?.profile?.family_name,
  };

  const getDownloadUrl = async ({ sjNumber, noteId, attachmentid }) => {
    setFetchingDownloadUrl(true);
    let url = null;
    if (sjNumber && noteId && attachmentid) {
      const apiUrl = `/servicejob/v1/servicejob/${sjNumber}/note/${noteId}/attachment/${attachmentid}/operation/download/presign`;
      const response = await getOne(apiUrl);
      if (response?.data) {
        url = response?.data?.presignedUrl || null;
      }
    }
    setDownloadUrl(url);
    setFetchingDownloadUrl(false);
  };

  const getPresignedUrl = async ({
    sjNumber,
    noteId,
    fileName,
    attachmentType,
    description,
  }) => {
    const url = `/servicejob/v1/servicejob/${sjNumber}/note/${noteId}/attachment/operation/upload/presign?attachmenttype=${attachmentType}&filename=${fileName}&description=${description}`;
    const response = await getOne(url);
    if (response.data) {
      return response.data;
    }
    return null;
  };

  const acknowledgeUpload = async ({
    sjNumber,
    noteId,
    attachmentId,
    fileLink,
  }) => {
    const url = `/servicejob/v1/servicejob/${sjNumber}/note/${noteId}/attachment/${attachmentId}/operation/upload/acknowledge?filelink=${fileLink}`;
    const response = await updateOne(url);
    if (response.data) {
      return response.data === 'Success';
    }
    return false;
  };

  const uploadPrivateDocument = async ({
    sjNumber,
    noteId,
    fileName,
    file,
    attachmentType,
    description,
  }) => {
    try {
      const awsPresignUrlResponse = await getPresignedUrl({
        sjNumber,
        noteId,
        fileName,
        attachmentType,
        description,
      });

      if (awsPresignUrlResponse) {
        await axios({
          method: 'put',
          url: awsPresignUrlResponse.presignedUrl,
          data: file,
          headers: {
            'Content-Type': 'application/octet-stream',
          },
        });

        await acknowledgeUpload({
          sjNumber,
          noteId,
          attachmentId: awsPresignUrlResponse.attachmentId,
          fileLink: awsPresignUrlResponse.fileLink,
        });

        const newSavedFile = {
          fileName,
          attachmentType,
          id,
          description,
          attachmentId: awsPresignUrlResponse.attachmentId,
          noteId,
        };

        await getDownloadUrl({
          sjNumber: serviceJobNumber,
          noteId,
          attachmentid: awsPresignUrlResponse.attachmentId,
        });

        setSavedFile(newSavedFile);

        return Promise.resolve(true);
      }

      throw new Error(
        formatMessage({
          id: 'COULD_NOT_UPLOAD_FILE',
          defaultMessage: 'Could not upload file',
        }),
      );
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const deleteFile = fileId => {
    confirmationPopUpRef.current.init({
      message: formatMessage({
        id: 'DO_YOU_WANT_TO_DELETE_ATTACHMENT',
        defaultMessage: 'Do you want to delete this attachment?',
      }),
      positiveButtonText: formatMessage({
        id: 'YES',
        defaultMessage: 'Yes',
      }),
      negativeButtonText: formatMessage({
        id: 'CANCEL',
        defaultMessage: 'Cancel',
      }),
      loadingMessage: formatMessage({
        id: 'DELETING_ATTACHMENT',
        defaultMessage: 'Deleting the attachment...',
      }),
      title: formatMessage({
        id: 'DELETE_ATTACHMENT',
        defaultMessage: 'Delete Attachment',
      }),
      onChange: async value => {
        if (value && savedFile?.attachmentId) {
          confirmationPopUpRef.current.setLoading(true);
          try {
            const result = await deleteOne(
              `/servicejob/v1/servicejob/${serviceJobNumber}/note/${savedFile.noteId}/attachment/${savedFile.attachmentId}/operation/delete`,
            );
            if (result?.data) {
              snackbar.showMessage(
                formatMessage({
                  id: 'ATTACHMENT_DELETED_SUCCESSFULLY',
                  defaultMessage: 'Attachment was deleted successfully',
                }),
              );
              removeAttachment(fileId);
              onNoteChanged();
            } else {
              throw new Error(
                formatMessage({
                  id: 'ATTACHMENT_COULD_NOT_BE_DELETED',
                  defaultMessage: 'Attachment could not be deleted',
                }),
              );
            }
          } catch (e) {
            snackbar.showMessage({
              type: 'error',
              data: e.message,
            });
          }
        } else if (value) {
          removeAttachment(fileId);
        }
        confirmationPopUpRef.current.setOpen(false);
      },
    });
    confirmationPopUpRef.current.setOpen(true);
  };

  const { control, getValues, setValue, trigger, watch } = useForm({
    defaultValues: {
      attachmentType: attachmentTypeProp,
      description: descriptionProp,
      fileName: fileNameProp,
    },
  });

  const { open, acceptedFiles, getInputProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: SUPPORTED_NOTES_ATTACHMENT_FILETYPES,
    multiple: false,
  });

  const onSubmit = async (data, savedNote) => {
    setError(null);
    setProcessing(true);
    try {
      const result = await uploadPrivateDocument({
        sjNumber: serviceJobNumber,
        noteId: savedNote.id,
        fileName: acceptedFiles[0].path,
        attachmentType: data.attachmentType,
        description: data.description,
        file: acceptedFiles[0],
      });
      if (result) {
        setProcessing(false);
        return result;
      }
    } catch (e) {
      setError(e.message);
    }
    setProcessing(false);
    return false;
  };

  useImperativeHandle(reference, () => ({
    submit(savedNote) {
      if (downloadUrl) {
        return true;
      }
      return onSubmit(getValues(), savedNote);
    },
    trigger() {
      if (downloadUrl) {
        return true;
      }
      return trigger();
    },
  }));

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      setValue('fileName', acceptedFiles[0].path);
      setError(null);
    } else {
      setValue('fileName', '');
    }
    trigger('fileName');
  }, [acceptedFiles]);

  useEffect(() => {
    const subscription = watch(value => {
      setShowOther(value?.attachmentType === 'OTHER');
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    getDownloadUrl({
      sjNumber: serviceJobNumber,
      noteId: noteIdProps,
      attachmentid: savedFile?.attachmentId,
    });
  }, []);

  if (savedFile.attachmentId) {
    return (
      <Box mt={2} mb={1}>
        <ConfirmationPopUp reference={confirmationPopUpRef} />
        {!fetchingDownloadUrl ? (
          <Grid2 container spacing={2}>
            <Grid2 xs={2}>
              <Stack direction="row" alignItems="center" gap={3}>
                <Stack direction="row" alignItems="center" gap={1}>
                  <FilePresent style={{ color: theme.palette.primary.main }} />
                  <Link
                    target="_blank"
                    rel="noreferrer"
                    href={downloadUrl}
                    variant="body2">
                    {savedFile?.fileName}
                  </Link>
                </Stack>
              </Stack>
            </Grid2>
            <Grid2 xs={4}>
              <Typography variant="text-body-1">
                {capitalCase(savedFile?.attachmentType, {
                  keepSpecialCharacters: false,
                })}
                {savedFile?.description ? ` : ${savedFile?.description}` : ''}
              </Typography>
            </Grid2>
            {uploadedBy ? (
              <Grid2 xs={3}>
                <Typography variant="text-body-1">{`${uploadedBy?.firstName} ${uploadedBy?.lastName}`}</Typography>
              </Grid2>
            ) : (
              <Grid2 xs={3}>
                <Typography variant="text-body-1">{`${currentUser?.firstName} ${currentUser?.lastName}`}</Typography>
              </Grid2>
            )}
            <Grid2 xs>
              <Box textAlign="right" mr={2}>
                <IconButton
                  style={{
                    background: theme.palette.secondary.light,
                    marginLeft: '20px',
                  }}
                  size="small"
                  onClick={() => {
                    deleteFile(id);
                  }}>
                  <Delete
                    fontSize="small"
                    style={{ color: theme.palette.error.main }}
                  />
                </IconButton>
              </Box>
            </Grid2>
          </Grid2>
        ) : (
          formatMessage({
            id: 'FETCHING_ATTACHMENT',
            description: 'Fetching attachment',
          })
        )}
      </Box>
    );
  }

  return (
    <Box mt={2} mb={1}>
      <ConfirmationPopUp reference={confirmationPopUpRef} />
      <input {...getInputProps()} />
      <Grid2 container spacing={2}>
        <Grid2 xs={2}>
          {acceptedFiles[0]?.path ? (
            <Stack direction="row" alignItems="center" gap={3}>
              <Stack direction="row" alignItems="center" gap={1}>
                <FilePresent style={{ color: theme.palette.primary.main }} />
                <Typography>{acceptedFiles[0]?.path || ''} </Typography>
              </Stack>
              {processing && <CircularProgress size="1.2rem" />}
            </Stack>
          ) : (
            <Button
              sx={{ textTransform: 'none' }}
              onClick={() => open()}
              size="small"
              variant="contained"
              startIcon={<AttachFileRounded />}>
              <FormattedMessage id="UPLOAD_FILE" defaultMessage="Upload file" />
            </Button>
          )}
          <Controller
            rules={{
              required: {
                value: true,
                message: formatMessage({
                  id: 'REQUIRED',
                  defaultMessage: 'Required',
                }),
              },
            }}
            control={control}
            name="fileName"
            render={({ field, fieldState }) => (
              <Box>
                <input type="hidden" {...field} />
                <FormHelperText
                  sx={{ fontSize: '1em', ml: 2 }}
                  {...(fieldState?.error?.message && {
                    error: !!fieldState?.error?.message,
                  })}>
                  {fieldState?.error?.message ? fieldState?.error?.message : ''}
                </FormHelperText>
              </Box>
            )}
          />
        </Grid2>
        {!processing && (
          <Grid2 xs={4}>
            <Box>
              <Grid2 container spacing={2}>
                <Grid2 xs={6}>
                  <Controller
                    rules={{
                      required: {
                        value: true,
                        message: formatMessage({
                          id: 'REQUIRED',
                          defaultMessage: 'Required',
                        }),
                      },
                    }}
                    control={control}
                    name="attachmentType"
                    render={({ field, fieldState }) => (
                      <Box>
                        <FormControl fullWidth>
                          <Select size="small" {...field}>
                            {ATTACHMENT_TYPES.map(aType => (
                              <MenuItem key={aType.value} value={aType.value}>
                                {aType.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormHelperText
                          sx={{ fontSize: '1em', ml: 2 }}
                          {...(fieldState?.error?.message && {
                            error: !!fieldState?.error?.message,
                          })}>
                          {fieldState?.error?.message
                            ? fieldState?.error?.message
                            : ''}
                        </FormHelperText>
                      </Box>
                    )}
                  />
                </Grid2>
                {showOther && (
                  <Grid2 xs={6}>
                    <Controller
                      rules={{
                        validate: v => {
                          const needDescription =
                            getValues('attachmentType') === 'OTHER';
                          if (needDescription) {
                            if (v.trim() === '') {
                              return formatMessage({
                                id: 'REQUIRED',
                                defaultMessage: 'Required',
                              });
                            }
                            return true;
                          }
                          return true;
                        },
                      }}
                      control={control}
                      name="description"
                      render={({ field, fieldState }) => (
                        <Box>
                          <FormControl fullWidth>
                            <TextField
                              placeholder={formatMessage({
                                id: 'DESCRIPTION',
                                description: 'Description',
                              })}
                              size="small"
                              {...field}
                            />
                          </FormControl>
                          <FormHelperText
                            sx={{
                              fontSize: fieldState?.error?.message
                                ? '1em'
                                : '0.9em',
                              ml: 2,
                            }}
                            {...(fieldState?.error?.message && {
                              error: !!fieldState?.error?.message,
                            })}>
                            {fieldState?.error?.message
                              ? fieldState?.error?.message
                              : formatMessage({
                                  id: 'PLEASE_PROVIDE_DESCRIPTION',
                                  defaultMessage:
                                    'Please provide a description',
                                })}
                          </FormHelperText>
                        </Box>
                      )}
                    />
                  </Grid2>
                )}
              </Grid2>
            </Box>
          </Grid2>
        )}

        {error && (
          <Grid2 xs>
            <Typography color="error">{error}</Typography>
          </Grid2>
        )}

        {!processing && (
          <Grid2 xs>
            <Box textAlign="right" mr={2}>
              <IconButton
                style={{
                  background: theme.palette.secondary.light,
                  marginLeft: '20px',
                }}
                size="small"
                onClick={() => {
                  deleteFile(id);
                }}>
                <Delete
                  fontSize="small"
                  style={{ color: theme.palette.error.main }}
                />
              </IconButton>
            </Box>
          </Grid2>
        )}
      </Grid2>
    </Box>
  );
}

export default FileView;
