import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {
  AsyncSelectFormControl,
  useDataProvider,
} from '@servicexcelerator/ui-design-system';

function ServiceProvider({ ...props }) {
  const provider =
    process.env.NODE_ENV === 'development' ? 'serviceJob' : 'default';
  const { getMany, getOne } = useDataProvider(provider);

  const getServiceProviderByName = async (inputValue = '') => {
    const result = await getMany(
      `/servicejob/v1/servicejob/serviceproviders?control=1&byName=${inputValue}`,
    );
    return result?.data || [];
  };

  const getServiceProviderById = async (inputValue = '') => {
    const result = await getOne(
      `/servicejob/v1/servicejob/serviceproviders?control=1&serviceProviderId=${inputValue}`,
    );
    return result.data ? result.data : [];
  };

  return (
    <Grid2 xs>
      <AsyncSelectFormControl
        fullWidth
        fetchOptions={input => getServiceProviderByName(input)}
        fetchOptionsById={input => getServiceProviderById(input)}
        {...props}
      />
    </Grid2>
  );
}

export default ServiceProvider;
