function LongDateDisplay(props, fieldName, AppModule) {
  const {
    row: { original },
  } = props;
  const { Utils } = AppModule;
  const { toLongDate } = Utils;

  return original[fieldName] ? toLongDate(original[fieldName]) : '';
}

const getColumns = (formatMessage, AppModule) => [
  {
    accessorKey: 'companyName',
    header: formatMessage({
      id: 'SERVICE_PROVIDER_NAME',
      defaultMessage: 'Service Provider Name',
    }),
  },
  {
    accessorKey: 'statusDateTime',
    header: formatMessage({
      id: 'STATUS_DATE_TIME',
      defaultMessage: 'Status Date/Time',
    }),
    Cell: props => {
      const {
        row: { original },
      } = props;
      const { Utils } = AppModule;
      const { toLongDate } = Utils;

      const dated = new Date(original.notificationDateTime);
      // dated.setMinutes(dated.getMinutes()+1);

      return original.notificationDateTime ? toLongDate(dated) : '';
    },
  },
  {
    accessorKey: 'note',
    header: formatMessage({
      id: 'NOTES',
      defaultMessage: 'Notes',
    }),
  },
];

export default getColumns;
