import { nanoid } from 'nanoid';
import Feeds from '../../../ui/Feeds';
import NotesAttachment from '../../../ui/NotesAttachment';
import PartsTotalCalculated from '../../../ui/PartsSummary/PartsTotalCalculated';
import ServiceHistory from '../../../ui/ServiceHistory';
import PaymentSimpleTableControl from '../../../ui/PaymentSimpleTableControl';
import PaymentAuthorizedCalculated from '../../../ui/PaymentSummary/PaymentAuthorizedCalculated';
import DispatchHistory from '../../../ui/DispatchHistory';
import ResendMessage from '../../../ui/ResendMessage';
import ServiceProvider from '../../../ui/ServiceProvider';

const getAdditionalComponents = (serviceJobData, formMode, AppModule) => {
  const { Components } = AppModule;
  const { Brands, Cities, States, LinkTextControl } = Components;

  const serviceAdministratorClientNumber =
    serviceJobData?.serviceAdministratorClientNumber;
  const addressCountryCode =
    serviceJobData?.customer?.customerAddress?.addressCountryCode;
  const addressState = serviceJobData?.customer?.customerAddress?.addressState;
  const claimNumber = serviceJobData?.claimNumber;
  return {
    ServiceProviderControl: props => (
      <ServiceProvider
        key={nanoid()}
        AppModule={AppModule}
        formMode={formMode}
        {...props}
      />
    ),
    Feeds: props => (
      <Feeds
        key={nanoid()}
        claimNumber={serviceJobData?.serviceJobNumber}
        AppModule={AppModule}
        {...props}
      />
    ),
    NotesAttachment: () => (
      <NotesAttachment
        key={nanoid()}
        AppModule={AppModule}
        formMode={formMode}
        serviceJobData={serviceJobData}
      />
    ),
    ServiceHistoryTable: () => (
      <ServiceHistory
        key={nanoid()}
        AppModule={AppModule}
        claimNumber={serviceJobData?.claimNumber}
        modelNumber={serviceJobData?.modelNumber}
        serialNumber={serviceJobData?.serialNumber}
        serviceAdministratorContractNumber={
          serviceJobData?.claimAdditional
            ?.serviceAdministratorServiceContractNumber
        }
      />
    ),
    DispatchTable: () => (
      <DispatchHistory
        key={nanoid()}
        AppModule={AppModule}
        serviceJobData={serviceJobData}
      />
    ),

    BrandsSelectFormControl: props => (
      <Brands
        key={nanoid()}
        {...props}
        serviceAdministrator={null}
        serviceAdministratorClientNumber={serviceAdministratorClientNumber}
      />
    ),
    StatesSelectFormControl: props => <States key={nanoid()} {...props} />,
    CitiesSelectFormControl: props => (
      <Cities
        key={nanoid()}
        defaultCountryCode={addressCountryCode}
        defaultStateCode={addressState}
        {...props}
      />
    ),
    ResendMessageControl: props => (
      <ResendMessage
        key={nanoid()}
        AppModule={AppModule}
        serviceJobNumber={serviceJobData?.serviceJobNumber}
        {...props}
      />
    ),
    CalculatedPaymentAuthorizedFormControl: props => (
      <PaymentAuthorizedCalculated key={nanoid()} {...props} />
    ),
    PartsTotalCalculated: props => (
      <PartsTotalCalculated
        key={nanoid()}
        formMode={formMode}
        AppModule={AppModule}
        {...props}
      />
    ),
    PaymentSimpleTableControl: props => (
      <PaymentSimpleTableControl
        key={nanoid()}
        AppModule={AppModule}
        {...props}
      />
    ),
    LinkTextControl: props => (
      <LinkTextControl
        key={nanoid()}
        {...props}
        to={`/claim/edit/${claimNumber}`}>
        {claimNumber}
      </LinkTextControl>
    ),
  };
};

export default getAdditionalComponents;
