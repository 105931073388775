import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {
  IconButton,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  Button,
  FormHelperText,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Condition from '../Condition';
import { getUniqueKey } from '../../common/util';

function RuleGroup(props) {
  const {
    conditions = [],
    combinator = 'and',
    level = 0,
    errors = {},
    decisionId,
    combinators,
    addCondition,
    deleteCondition,
    onFactFieldChange,
    addGroup,
    deleteGroup,
    onOperatorFieldChange,
    onCombinatorChange,
    onValueFieldChange,
    onValueSourceChange,
    onValueFactFieldChange,
    factFieldMap,
    factFields,
    operatorsByTypeMap,
    operatorsByValueMap,
  } = props;
  return (
    <Grid2
      container
      spacing={1}
      direction="column"
      sx={{ sl: level > 0 ? 10 : 0 }}>
      <Grid2 container>
        <Grid2>
          {level > 0 && (
            <IconButton size="small" onClick={() => deleteGroup(decisionId)}>
              <RemoveCircleOutlineIcon
                color={level > 0 ? 'error' : 'disabled'}
              />
            </IconButton>
          )}
        </Grid2>
        <Grid2>
          <ToggleButtonGroup
            exclusive
            size="small"
            value={combinator}
            onChange={(event, value) => {
              if (value) {
                onCombinatorChange({ decisionId, value });
              }
            }}>
            {combinators.map(item => (
              <ToggleButton
                key={getUniqueKey()}
                value={item.value}
                color="primary">
                <Typography variant="body2">{item.label}</Typography>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Grid2>
        <Grid2>
          <Button
            startIcon={<AddIcon />}
            size="medium"
            onClick={() => addCondition(decisionId)}>
            Condition
          </Button>
        </Grid2>
        <Grid2>
          {level === 0 && (
            <Button startIcon={<AddIcon />} size="medium" onClick={addGroup}>
              Group
            </Button>
          )}
        </Grid2>
        {errors?.decisionErrors && errors.decisionErrors[decisionId] && (
          <Grid2>
            <FormHelperText sx={{ color: 'error.main' }}>
              {errors?.decisionErrors[decisionId]}
            </FormHelperText>
          </Grid2>
        )}
      </Grid2>
      <Grid2>
        {conditions?.map(condition =>
          !condition.combinator ? (
            <Condition
              combinator={combinator}
              level={level}
              combinators={combinators}
              addCondition={addCondition}
              key={condition.conditionId}
              condition={condition}
              deleteCondition={deleteCondition}
              onFactFieldChange={onFactFieldChange}
              onOperatorFieldChange={onOperatorFieldChange}
              decisionId={decisionId}
              onValueFieldChange={onValueFieldChange}
              conditionErrors={errors?.conditionErrors}
              onValueSourceChange={onValueSourceChange}
              onValueFactFieldChange={onValueFactFieldChange}
              factFieldMap={factFieldMap}
              factFields={factFields}
              operatorsByTypeMap={operatorsByTypeMap}
              operatorsByValueMap={operatorsByValueMap}
            />
          ) : (
            <RuleGroup
              key={condition.decisionId}
              conditions={condition.conditions}
              combinator={condition.combinator}
              level={level + 1}
              combinators={combinators}
              errors={errors}
              addCondition={addCondition}
              deleteCondition={deleteCondition}
              onFactFieldChange={onFactFieldChange}
              onOperatorFieldChange={onOperatorFieldChange}
              decisionId={condition.decisionId}
              deleteGroup={deleteGroup}
              onCombinatorChange={onCombinatorChange}
              onValueFieldChange={onValueFieldChange}
              onValueSourceChange={onValueSourceChange}
              onValueFactFieldChange={onValueFactFieldChange}
              factFieldMap={factFieldMap}
              factFields={factFields}
              operatorsByTypeMap={operatorsByTypeMap}
              operatorsByValueMap={operatorsByValueMap}
            />
          ),
        )}
      </Grid2>
    </Grid2>
  );
}

export default RuleGroup;
