import { Button } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

function PreviousJobButton({ serviceJobNumber, previousJob, loading }) {
  return (
    <Button
      disabled={serviceJobNumber === null || loading}
      onClick={() => {
        previousJob(serviceJobNumber);
      }}
      variant="outlined"
      size="small"
      sx={{
        minWidth: '0px',
        padding: '0 2px',
        position: 'absolute',
        left: '2px',
        top: '5px',
      }}>
      <ChevronLeftIcon />
    </Button>
  );
}

export default PreviousJobButton;
