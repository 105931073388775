import { Link } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { RULE_OUTCOME } from '../../../common/constants';

function ClaimFormLink(props) {
  const {
    row: {
      original: { claimFormName },
    },
  } = props;
  return <Link href="/#">{claimFormName}</Link>;
}

function RuleOutcome(props) {
  const {
    row: {
      original: { outcome },
    },
  } = props;
  const ruleOutcome = RULE_OUTCOME.find(item => item.value === outcome);
  return (
    <FormattedMessage
      id={ruleOutcome.label}
      defaultMessage={ruleOutcome.label}
    />
  );
}

function RuleClaimFormEnabled(props) {
  const {
    row: {
      original: { enabled },
    },
  } = props;
  return enabled ? (
    <FormattedMessage id="YES" defaultMessage="Yes" />
  ) : (
    <FormattedMessage id="No" defaultMessage="No" />
  );
}

const getColumns = formatMessage => [
  {
    accessorKey: 'claimFormName',
    header: formatMessage({
      id: 'CLAIM_FORMS',
      defaultMessage: 'Claim forms',
    }),
    minSize: 320,
    enableColumnActions: false,
    Cell: props => ClaimFormLink(props),
  },
  {
    accessorKey: 'outcome',
    header: formatMessage({
      id: 'RULE_OUTCOME',
      defaultMessage: 'Outcome',
    }),
    enableColumnActions: false,
    Cell: props => RuleOutcome(props),
  },
  {
    accessorKey: 'enabled',
    header: formatMessage({
      id: 'RULE_CLAIM_FORM_ENABLED',
      defaultMessage: 'Enabled',
    }),
    enableColumnActions: false,
    Cell: props => RuleClaimFormEnabled(props),
  },
  {
    accessorKey: 'stopEngine',
    header: formatMessage({
      id: 'CONTINUE_PROCESSING_WHEN_RULE_TRIGGERED',
      defaultMessage: 'Continue Processing when rule triggered',
    }),
    enableColumnActions: false,
    Cell: props => RuleClaimFormEnabled(props),
  },
];

export default getColumns;
