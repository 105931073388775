/* eslint-disable no-param-reassign */
import { LngLat, LngLatBounds, Marker } from 'maplibre-gl';
import ServiceProvider from '../markers/ServiceProvider';
import ServiceProviderServiceJob from '../markers/ServiceProviderServiceJob';

const MapLib = {
  mapObj: null,
  jobInfo: null,
  core: {
    clean: ({ mapElements }) => {
      if (mapElements?.current?.sj) {
        mapElements?.current?.sj?.remove();
        mapElements.current.sj = null;
      }
      if (Array.isArray(mapElements?.current?.sps)) {
        mapElements?.current?.sps?.every(sp => {
          sp.remove();
          return true;
        });
        mapElements.current.sps = [];
      }
      if (mapElements?.current?.selectedSPMarker) {
        mapElements.current.selectedSPMarker = null;
      }
    },
    boundMap: ({ mapObj, coordinates }) => {
      // Extract latitude and longitude values from coordinates
      const lats = coordinates.map(coord => coord[0]);
      const lngs = coordinates.map(coord => coord[1]);

      // Find minimum and maximum latitude and longitude
      const minLat = Math.min(...lats);
      const maxLat = Math.max(...lats);
      const minLng = Math.min(...lngs);
      const maxLng = Math.max(...lngs);

      // Define rectangle corners
      const southwest = new LngLat(minLng, minLat);
      const northeast = new LngLat(maxLng, maxLat);

      const bbox = new LngLatBounds(southwest, northeast);
      // Add rectangle to map
      mapObj.fitBounds(bbox, {
        padding: { top: 40, bottom: 40, left: 40, right: 40 },
      });
    },
    createLayer: ({
      layerId,
      sourceName,
      markerName,
      layoutProps = {},
      paintProps = {},
    }) => ({
      id: layerId,
      type: 'symbol',
      source: sourceName,
      layout: {
        'icon-image': markerName,
        ...layoutProps,
      },
      paint: {
        ...paintProps,
      },
    }),
    showLayer: ({ name, mapObj }) => {
      mapObj.setLayoutProperty(name, 'visibility', 'visible');
    },
    hideLayer: ({ name, mapObj }) => {
      mapObj.setLayoutProperty(name, 'visibility', 'none');
    },
    createSource: ({ features }) => ({
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features,
      },
    }),
    svgObjectToImageSrc(svgObj) {
      const xmlSerializer = new XMLSerializer();
      const svgString = xmlSerializer.serializeToString(svgObj);
      return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
        svgString,
      )}`;
    },
  },
  serviceJob: {
    applyToMapAsMarker: ({
      lng,
      lat,
      mapElements,
      mapObj,
      zoom,
      coordinatesToBoundTo,
    }) => {
      // eslint-disable-next-line no-param-reassign
      mapElements.current.sj = new Marker({ color: 'red' })
        .setLngLat([lng, lat])
        .addTo(mapObj);

      mapObj.jumpTo({
        center: [lng, lat],
        speed: 8,
        zoom,
        essential: true,
      });

      MapLib.core.boundMap({
        mapObj,
        coordinates: coordinatesToBoundTo,
      });
    },
  },
  serviceProvider: {
    getMarkerId: serviceProviderId => `SP_${serviceProviderId}`,
    highlightMarker: serviceProviderId => {
      const marker = document.getElementById(
        MapLib.serviceProvider.getMarkerId(serviceProviderId),
      );
      marker.getElementById('outer').setAttribute('display', 'block');
    },
    unHighlightMarker: serviceProviderId => {
      const marker = document.getElementById(
        MapLib.serviceProvider.getMarkerId(serviceProviderId),
      );
      marker.getElementById('outer').setAttribute('display', 'none');
    },
    unHighlightAllMarkers: (serviceProviders, ignoreServiceProviders = []) => {
      serviceProviders.every(sp => {
        if (!ignoreServiceProviders.includes(sp.serviceProviderId)) {
          MapLib.serviceProvider.unHighlightMarker(sp.serviceProviderId);
        }
        return true;
      });
    },
    applyToMapAsMarkers: async ({
      mapObj,
      mapElements,
      serviceProviders,
      onServiceProviderClick,
      theme,
      AppModule,
    }) => {
      await serviceProviders.every(async (sp, index) => {
        const markerElement = ServiceProvider({
          services: sp.services,
          onClickHandler: onServiceProviderClick,
          serviceProviderId: sp.serviceProviderId,
          theme,
          AppModule,
          index,
          id: MapLib.serviceProvider.getMarkerId(sp.serviceProviderId),
          mapObj,
        });

        mapElements.current.sps.push(
          new Marker({
            element: markerElement,
          })
            .setLngLat([sp.longitude, sp.latitude])
            .addTo(mapObj),
        );

        await MapLib.serviceProvider.serviceJob.applyToMapAsLayer({
          mapObj,
          serviceJobLocations: sp?.serviceJobs,
          serviceProvider: sp,
          index,
        });

        return true;
      });
    },
    serviceJob: {
      getLayerName: index => `spServiceJobLayer${index}`,
      isHighlightedLayer: (mapObj, layerName) =>
        mapObj.getPaintProperty(layerName, 'icon-opacity') === 1,
      highlightLayer: (mapObj, layerName) => {
        mapObj.setPaintProperty(layerName, 'icon-opacity', 1);
      },
      unHighlightLayer: (mapObj, layerName) => {
        mapObj.setPaintProperty(layerName, 'icon-opacity', 0.5);
      },
      unHighlightAllLayers: (
        mapObj,
        serviceProviders,
        ignoreServiceProviders,
      ) => {
        serviceProviders.every((sp, index) => {
          if (!ignoreServiceProviders.includes(sp.serviceProviderId)) {
            MapLib.serviceProvider.serviceJob.unHighlightLayer(
              mapObj,
              MapLib.serviceProvider.serviceJob.getLayerName(index),
            );
          }
          return true;
        });
      },
      applyToMapAsLayer: async ({
        mapObj,
        serviceJobLocations,
        serviceProvider,
        index,
      }) => {
        const SOURCE_NAME = `serviceProviderSource${index}`;
        const MARKER_NAME = `serviceProviderMarkers${index}`;
        const LAYER_ID = MapLib.serviceProvider.serviceJob.getLayerName(index);

        const features = MapLib.serviceProvider.serviceJob.getFeatures({
          serviceJobLocations,
          serviceProvider,
          index,
          layerId: LAYER_ID,
        });

        const source = MapLib.core.createSource({
          sourceName: SOURCE_NAME,
          mapObj,
          features,
        });

        if (!mapObj.hasImage(MARKER_NAME)) {
          const svgMarker = ServiceProviderServiceJob({
            index,
          });

          const svgImage = new Image(23, 23);
          svgImage.src = MapLib.core.svgObjectToImageSrc(svgMarker);
          svgImage.onload = () => {
            mapObj.addImage(MARKER_NAME, svgImage);
          };
        } else {
          mapObj.removeLayer(LAYER_ID);
          mapObj.removeSource(SOURCE_NAME);
        }

        mapObj.addSource(SOURCE_NAME, source);

        mapObj.addLayer(
          MapLib.core.createLayer({
            layerId: LAYER_ID,
            sourceName: SOURCE_NAME,
            markerName: MARKER_NAME,
            paintProps: {
              'icon-opacity': 0.5,
            },
          }),
        );

        return mapObj;
      },
      getFeatures({ serviceJobLocations, serviceProvider, index, layerId }) {
        return serviceJobLocations?.map(serviceJobLocation => ({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [
              serviceJobLocation.longitude,
              serviceJobLocation.latitude,
            ],
          },
          properties: {
            serviceProviderId: serviceProvider.serviceProviderId,
            index,
            layerId,
          },
        }));
      },
      onClick(e) {
        const { serviceProviderId, layerId } = e.features[0].properties;
        const serviceProviders = MapLib.jobInfo?.current?.serviceProviders;
        // Deselect any previous layers
        MapLib.serviceProvider.unHighlightAllMarkers(serviceProviders, [
          serviceProviderId,
        ]);
        MapLib.serviceProvider.serviceJob.unHighlightAllLayers(
          MapLib.mapObj,
          serviceProviders,
          [serviceProviderId],
        );
        // Select the clicked layer
        if (
          MapLib.serviceProvider.serviceJob.isHighlightedLayer(
            MapLib.mapObj,
            layerId,
          )
        ) {
          MapLib.serviceProvider.unHighlightMarker(serviceProviderId);
          MapLib.serviceProvider.serviceJob.unHighlightLayer(
            MapLib.mapObj,
            layerId,
          );
        } else {
          MapLib.serviceProvider.highlightMarker(serviceProviderId);
          MapLib.serviceProvider.serviceJob.highlightLayer(
            MapLib.mapObj,
            layerId,
          );
        }
      },
    },
    applyToMapAsLayer: async ({ mapObj, serviceProviders, markerUrl }) => {
      const SOURCE_NAME = 'serviceProviderSource';
      const MARKER_NAME = 'serviceProviderMarkers';
      const LAYER_ID = 'serviceProvidersLayer';

      const features = MapLib.serviceProvider.getFeatures({ serviceProviders });

      const source = MapLib.core.createSource({
        sourceName: SOURCE_NAME,
        mapObj,
        features,
      });

      if (!mapObj.hasImage(MARKER_NAME)) {
        const image = await mapObj.loadImage(markerUrl);
        mapObj.addImage(MARKER_NAME, image.data);
      } else {
        mapObj.removeLayer(LAYER_ID);
        mapObj.removeSource(SOURCE_NAME);
      }

      mapObj.addSource(SOURCE_NAME, source);

      mapObj.addLayer(
        MapLib.core.createLayer({
          layerId: LAYER_ID,
          sourceName: SOURCE_NAME,
          markerName: MARKER_NAME,
        }),
      );

      return mapObj;
    },
    getFeatures: ({ serviceProviders }) =>
      serviceProviders?.map(serviceProvider => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [serviceProvider.longitude, serviceProvider.latitude],
        },
        properties: {
          serviceProviderId: serviceProvider.serviceProviderId,
        },
      })),
  },
};

export default MapLib;
