const CLAIM_FORMS = [
  {
    claimFormName: 'FiveStar-2024',
    claimFormId: '1',
    status: 'Active',
    addressCountryCode: ['US'],
    industryId: ['MA'],
    warrantyTypeId: ['SC'],
    serviceTypeId: ['REPAIR'],
    serviceAdministratorClientNumber: ['Five Star'],
    checkOrder: 1,
  },
  {
    claimFormName: 'FoodLoss-2024',
    claimFormId: '2',
    status: 'Active',
    addressCountryCode: ['US'],
    industryId: ['MA'],
    warrantyTypeId: ['SC'],
    serviceTypeId: ['FOODLOSS'],
    serviceAdministratorClientNumber: [],
    checkOrder: 2,
  },
  {
    claimFormName: 'BasicOEM-2024',
    claimFormId: '3',
    status: 'Active',
    addressCountryCode: ['US'],
    industryId: ['MA', 'CE'],
    warrantyTypeId: ['OE'],
    serviceTypeId: ['REPAIR'],
    serviceAdministratorClientNumber: [],
    checkOrder: 3,
  },
  {
    claimFormName: 'BasicHVAC-2024',
    claimFormId: '4',
    status: 'Active',
    addressCountryCode: ['US'],
    industryId: ['HV'],
    warrantyTypeId: ['SC'],
    serviceTypeId: ['REPAIR'],
    serviceAdministratorClientNumber: [],
    checkOrder: 4,
  },
  {
    claimFormName: 'BasicClaim-2024',
    claimFormId: '5',
    status: 'Active',
    addressCountryCode: [],
    industryId: [],
    warrantyTypeId: [],
    serviceTypeId: [],
    serviceAdministratorClientNumber: [],
    checkOrder: 5,
  },
];

export default CLAIM_FORMS;
