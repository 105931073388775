export const CLAIM_REJECT_RULE_OUTCOME = 'CLAIM_REJECT';
export const CLAIM_REVIEW_RULE_OUTCOME = 'CLAIM_REVIEW';
export const CLAIM_ERROR_RULE_OUTCOME = 'CLAIM_ERROR';

export const RULE_OUTCOME = [
  { label: 'Claim Reject', value: CLAIM_REJECT_RULE_OUTCOME },
  { label: 'Claim Review', value: CLAIM_REVIEW_RULE_OUTCOME },
  { label: 'Claim Error', value: CLAIM_ERROR_RULE_OUTCOME },
];

export const COMBINATORS = [
  { label: 'AND', value: 'and' },
  { label: 'OR', value: 'or' },
];

export const VALUE_SOURCES = [
  { label: 'Value', value: 'VALUE' },
  { label: 'Fact', value: 'FACT' },
];
