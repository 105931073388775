import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { MainMenu, Page, useStore } from '@servicexcelerator/ui-design-system';
import { CreateClaimModal } from '@servicexcelerator/ui-claim-module';
import { CreateServiceJobModal } from '@servicexcelerator/ui-servicejob-module';
import Icons from '@components/Icons';
import { COMPANY_TYPE, PERMISSIONS } from '@constants/index';
import { useUserAccess, hasPermission } from '../UserAccessProvider';

function Layout({ children, AppModule }) {
  const {
    ClaimSearch,
    Claims,
    Home,
    NewClaim,
    ServiceJob,
    SystemConfiguration,
    ClaimRules,
    ClaimForms,
    ServiceProvider,
  } = Icons;
  const store = useStore();
  const { formatMessage } = useIntl();
  const userAccess = useUserAccess();
  const permissions = userAccess?.permissions || {};
  const canCreateClaim = hasPermission(PERMISSIONS.MODIFY_CLAIMS, permissions);
  const isSA = userAccess.companyType === COMPANY_TYPE.SERVICE_ADMINISTRATOR;

  const menuItems = [
    {
      name: 'home',
      label: formatMessage({
        id: 'HOME_MENU',
        defaultMessage: 'Home',
      }),
      icon: Home,
      onClick: () => {
        window.location.href = '/dashboard';
      },
      hasPermission: true,
    },
    {
      name: 'claims',
      label: formatMessage({
        id: 'CLAIMS_MENU',
        defaultMessage: 'Claims',
      }),
      icon: Claims,
      hasPermission: true,
      children: [
        {
          name: 'claims-create',
          label: formatMessage({
            id: 'CREATE_NEW_CLAIM_MENU',
            defaultMessage: 'Create New Claim',
          }),
          icon: NewClaim,
          onClick: () => {
            store.uiState.createClaimModal.setOpen(true);
          },
          hasPermission: canCreateClaim,
        },
        {
          name: 'claims-search',
          label: formatMessage({
            id: 'SEARCH_CLAIMS_MENU',
            defaultMessage: 'Search Claims',
          }),
          icon: ClaimSearch,
          onClick: () => {
            store.uiState.claimSearchForm.set({});
            window.location.href = '/claims/search';
          },
          hasPermission: true,
        },
      ],
    },
    {
      name: 'servicejobs',
      label: formatMessage({
        id: 'SERVICEJOBS_MENU',
        defaultMessage: 'Jobs',
      }),
      icon: ServiceJob,
      hasPermission: true,
      children: [
        {
          name: 'servicejob-create',
          label: formatMessage({
            id: 'CREATE_NEW_SERVICEJOB_MENU',
            defaultMessage: 'Create New Job',
          }),
          icon: ServiceJob,
          onClick: () => {
            store.uiState.customerSearchForm.set({});
            window.location.href = '/servicejob/create/selectcustomer';
          },
          hasPermission: isSA,
        },
        {
          name: 'servicejob-search',
          label: formatMessage({
            id: 'SEARCH_SERVICEJOBS_MENU',
            defaultMessage: 'Search Jobs',
          }),
          icon: ServiceJob,
          onClick: () => {
            store.uiState.serviceJobSearchForm.set({});
            window.location.href = '/servicejobs/search';
          },
          hasPermission: true,
        },
      ],
    },
    {
      name: 'systemConfigurations',
      label: formatMessage({
        id: 'SYSTEM_CONFIGURATIONS_MENU',
        defaultMessage: 'System Config',
      }),
      icon: SystemConfiguration,
      hasPermission: isSA,
      children: [
        {
          name: 'claimrulessearch',
          label: formatMessage({
            id: 'CLAIM_RULES_SEARCH_MENU',
            defaultMessage: 'Claim Rules',
          }),
          icon: ClaimRules,
          onClick: () => {
            store.uiState.claimSearchForm.set({});
            window.location.href = '/rules/search?type=claim';
          },
          hasPermission: true,
        },
        {
          name: 'claimformssearch',
          label: formatMessage({
            id: 'CLAIM_FORMS_SEARCH_MENU',
            defaultMessage: 'Claim Forms',
          }),
          icon: ClaimForms,
          onClick: () => {
            window.location.href = '/claimforms/search';
          },
          hasPermission: true,
        },
        {
          name: 'servicejobmaintenance',
          label: formatMessage({
            id: 'SERVICE_PROVIDERS',
            defaultMessage: 'Service Providers',
          }),
          icon: ServiceProvider,
          onClick: () => {
            window.location.href = '/servicejob/demosetup';
          },
          hasPermission: true,
        },
      ],
    },
  ];

  return (
    <Page>
      <Grid2 container>
        <Grid2>
          <MainMenu menuItems={menuItems} logoHref="/dashboard" />
        </Grid2>
        <Grid2 xs>{children}</Grid2>
      </Grid2>
      <CreateClaimModal AppModule={AppModule} />
      <CreateServiceJobModal AppModule={AppModule} />
    </Page>
  );
}

export default observer(Layout);
