import {
  Breadcrumbs,
  CALENDAR_TYPE,
  Calendar,
  Section,
  useStore,
} from '@servicexcelerator/ui-design-system';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { FormattedMessage, useIntl } from 'react-intl';

import { Box, Button, Typography, useTheme } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import RequestAlternateTimeModal from '../../modals/RequestAlternateTimeModal';

function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getRandomMultipleOf100(min, max) {
  // Generate a random multiple of 100 between min and max
  const range = (max - min) / 100 + 1; // Number of possible multiples of 100
  return min + Math.floor(Math.random() * range) * 100;
}

function getTwoRandomMultiplesOf100(min, max) {
  const startTime = getRandomMultipleOf100(min, max);
  const endTime = getRandomMultipleOf100(startTime, max);
  return { startTime, endTime };
}

function ServiceJobAppointment({ AppModule }) {
  const { Components, Icons, Utils } = AppModule;
  const { BackButton } = Icons;
  const { Layout, Header, DashboardHeader } = Components;
  const { toMediumDate } = Utils;
  const routeParam = useParams();

  const { formatMessage } = useIntl();
  const theme = useTheme();
  const store = useStore();

  const events = [];
  const countOfEvents = 30;
  const minTick = 100;
  for (let i = 0; i < countOfEvents; i += 1) {
    const { startTime, endTime } = getTwoRandomMultiplesOf100(500, 1700);
    events.push({
      startTime,
      endTime:
        // eslint-disable-next-line no-nested-ternary
        startTime === endTime
          ? endTime + minTick * 2
          : startTime - endTime > 100
          ? endTime
          : endTime + minTick * 2,
      dated: dayjs().add(i, 'day').format('YYYY-MM-DD'),
      type: CALENDAR_TYPE.BOOKING_SLOT,
      data: {
        stars: getRandomNumber(2, 5),
      },
    });
  }

  events.push({
    startTime: null,
    endTime: 1200,
    dated: dayjs(new Date()).format('YYYY-MM-DD'),
    type: CALENDAR_TYPE.EVENT_SLOT,
    data: {
      label: 'Parts Arrival',
    },
  });

  const fetchData = startDate => {
    const startRange = dayjs(startDate).format('YYYY-MM-DD');
    const endRange = dayjs(startDate).add(6, 'day').format('YYYY-MM-DD');
    return (
      events.filter(
        item =>
          dayjs(item.dated).format('YYYY-MM-DD') >= startRange &&
          dayjs(item.dated).format('YYYY-MM-DD') <= endRange,
      ) || []
    );
  };

  const breadCrumb = [
    {
      label: formatMessage({
        id: 'DASHBOARD',
        defaultMessage: 'Dashboard',
      }),
      href: '/dashboard',
    },
  ];

  breadCrumb.push({
    label: formatMessage({
      id: 'APPOINTMENT',
      defaultMessage: 'Appointment',
    }),
  });

  return (
    <Layout AppModule={AppModule}>
      <RequestAlternateTimeModal
        AppModule={AppModule}
        serviceJobNumber={routeParam?.serviceJobNumber}
      />
      <Header sx={{ mb: 2 }}>
        <DashboardHeader />
      </Header>

      <Box mb={2}>
        <Grid2 container pt={0} pb={0} alignItems="center">
          <Grid2 xs={6}>
            <Breadcrumbs links={breadCrumb} />
          </Grid2>
          <Grid2 textAlign="right" xs={6}>
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                window.history.back();
              }}
              startIcon={<BackButton />}>
              <FormattedMessage id="BACK_BUTTON" defaultMessage="Back" />
            </Button>
          </Grid2>
        </Grid2>
      </Box>
      <Section
        label={
          <Grid2 container>
            <Grid2>
              <FormattedMessage id="SCHEDULING" defaultMessage="Scheduling" />
            </Grid2>
            <Grid2>
              <Typography
                fontWeight={500}
                color={theme.palette.success.light}
                sx={{ marginTop: '4px', marginLeft: '20px' }}>
                <FormattedMessage id="TODAY" defaultMessage="Today" /> &#x2022;{' '}
                {toMediumDate(new Date())}
              </Typography>
            </Grid2>
          </Grid2>
        }
        headerIcon={CalendarMonthIcon}
        headerContent={
          <Grid2 textAlign="right">
            <Button
              variant="contained"
              onClick={() =>
                store.uiState.requestAlternateTimeModal.setOpen(true)
              }>
              <FormattedMessage
                id="REQUEST_ALTERNATE_TIME"
                defaultMessage="Request Alternate Time"
              />
            </Button>
          </Grid2>
        }>
        <Calendar
          fetchData={dated => fetchData(dated)}
          startTime={900}
          endTime={1700}
          minTick={minTick}
          datesToDisplay={7}
          paginationCount={7}
        />
      </Section>
    </Layout>
  );
}

export default observer(ServiceJobAppointment);
