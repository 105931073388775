import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {
  LoadingEvent,
  useDataProvider,
} from '@servicexcelerator/ui-design-system';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

function EntitlementFeed({ claimNumber }) {
  const { formatMessage } = useIntl();
  const defaultMessage = {
    message: formatMessage({
      id: 'NO_DATA_EXISTS',
      defaultMessage: 'No data exists',
    }),
  };
  const { getOne } = useDataProvider();
  const [value, setValue] = useState(defaultMessage);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await getOne(
        `/servicejob/v1/servicejob/${claimNumber}/audit/entitlement`,
      );
      setValue(result?.data || defaultMessage);
      setLoading(false);
    };

    if (claimNumber) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [claimNumber]);

  return (
    <Grid2>
      {loading ? (
        <LoadingEvent>
          <FormattedMessage id="FETCHING_DATA" defaultMessage="Fetching Data" />
        </LoadingEvent>
      ) : (
        <pre>{JSON.stringify(value, 1, 2)}</pre>
      )}
    </Grid2>
  );
}

export default EntitlementFeed;
