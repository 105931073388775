import {
  LoggerUtil,
  useDataProvider,
  useSnackbar,
  useStore,
} from '@servicexcelerator/ui-design-system';
import { useIntl } from 'react-intl';
import { MaterialReactTable } from 'material-react-table';
import { useEffect, useMemo, useState } from 'react';
import getColumns from './lib/customerColumns';

const CUSTOMER_LIMIT = 5;

function getExistingParamsFromUrlOrStore(store) {
  const currentUrl = new URL(window.location.href);
  const page = currentUrl?.searchParams?.get('page') || null;
  const limit = currentUrl?.searchParams?.get('limit') || null;
  const filters = currentUrl?.searchParams?.get('filters') || null;
  const sort = currentUrl?.searchParams?.get('sorting') || null;
  if (page || limit || filters || sort) {
    currentUrl?.searchParams?.delete('page');
    currentUrl?.searchParams?.delete('limit');
    currentUrl?.searchParams?.delete('filters');
    currentUrl?.searchParams?.delete('sorting');
    return {
      page: page ? parseInt(page, 10) : 0,
      limit: limit ? parseInt(limit, 10) : CUSTOMER_LIMIT,
      filters: filters ? JSON.parse(filters) : [],
      sort: sort ? JSON.parse(sort) : [],
    };
  }
  return store.uiState.demoCustomerForm.get();
}

function columnFiltersArrayToObject(columnFilters) {
  const columnFiltersForService = columnFilters?.reduce(
    (acc, item) => ({
      ...acc,
      [item.id]: item.value,
    }),
    {},
  );
  return columnFiltersForService;
}

function CustomerDataTable({ AppModule }) {
  const { formatMessage } = useIntl();
  const store = useStore();
  const provider =
    process.env.NODE_ENV === 'development' ? 'serviceJob' : 'default';
  const { getMany, getApiUrl, updateOne } = useDataProvider(provider);

  const snackbar = useSnackbar();

  const params = getExistingParamsFromUrlOrStore(store);

  // data and fetching state
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);

  // table state
  const [rowCount, setRowCount] = useState(params?.rowCount || 0);
  const [columnFilters, setColumnFilters] = useState(params?.filters || []);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState(params?.sorting || []);
  const [pagination, setPagination] = useState({
    pageIndex: params?.page || 0,
    pageSize: params?.limit || CUSTOMER_LIMIT,
  });

  const columns = useMemo(
    () => getColumns(formatMessage, AppModule),
    [formatMessage],
  );

  async function fetchData() {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    const convertedFilters = {
      ...columnFiltersArrayToObject(columnFilters),
      ...(globalFilter && { globalfilter: globalFilter }),
    };

    const apiUrl = new URL(`${getApiUrl()}/servicejob/v1/servicejob/customers`);

    apiUrl.searchParams.set('page', `${pagination.pageIndex + 1}`);
    apiUrl.searchParams.set('limit', `${pagination.pageSize}`);
    apiUrl.searchParams.set('filters', JSON.stringify(convertedFilters || {}));
    apiUrl.searchParams.set('sorting', JSON.stringify(sorting || []));

    try {
      const response = await getMany(apiUrl.href);
      if (response?.data) {
        const responseData = response?.data;
        setData(responseData.data);
        const tempRowCount =
          responseData?.pagination?.totalItems || response?.data.length;
        setRowCount(tempRowCount);
        store.uiState.demoCustomerForm.set({
          page: pagination?.pageIndex,
          limit: pagination?.pageSize,
          filters: columnFilters,
          rowCount: tempRowCount,
          sorting,
        });
      }
    } catch (error) {
      setIsError(true);
      LoggerUtil.log('Customer Search', error);
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  }

  useEffect(() => {
    fetchData();
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  const breadCrumb = [
    {
      label: formatMessage({
        id: 'DASHBOARD',
        defaultMessage: 'Dashboard',
      }),
      href: '/dashboard',
    },
  ];

  breadCrumb.push({
    label: formatMessage({
      id: 'DEMO_SETUP_MENU',
      defaultMessage: 'Demo Setup',
    }),
  });

  const handleCancelRowEdits = () => {};

  const handleSaveRowEdits = async rowData => {
    const result = {
      message: {
        message:
          'The customer infor could Not be submitted due to Errors on the Form',
      },
      errors: null,
      success: false,
      data: null,
    };

    const serviceJobUpdateResponse = await updateOne(
      `/servicejob/v1/servicejob/${rowData.row.original.customerId}/customer/operation/update`,
      { ...rowData.values },
    );

    // Todo: Need to figure out when we get errors vs error
    if (serviceJobUpdateResponse?.errors) {
      result.errors = serviceJobUpdateResponse.errors;
      snackbar.showMessage({
        type: 'error',
        data: 'Error: Could not save',
      });
    } else {
      snackbar.showMessage('Service Provider was saved');
    }

    rowData.exitEditingMode();

    fetchData();
  };

  return (
    <MaterialReactTable
      editingMode="row"
      enableEditing
      onEditingRowSave={handleSaveRowEdits}
      onEditingRowCancel={handleCancelRowEdits}
      localization={store.domain.locale.getForMuiTable()}
      columns={columns}
      data={data}
      getRowId={row => row.serviceJobNumber}
      rowCount={rowCount}
      manualFiltering
      manualPagination
      enableGlobalFilter
      manualSorting={false}
      onColumnFiltersChange={setColumnFilters}
      onGlobalFilterChange={setGlobalFilter}
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
      enableSorting={false}
      enableColumnFilters={false}
      enableFilters
      initialState={{
        showColumnFilters: false,
        columnVisibility: {
          serviceProviderNumber: false,
          contractNumber: false,
          productPurchaseDate: false,
          modelSerialNumber: false,
          brandProductType: false,
          customeraddress: false,
        },
      }}
      state={{
        columnFilters,
        globalFilter,
        isLoading,
        showAlertBanner: isError,
        showProgressBars: isRefetching,
        sorting,
        pagination,
      }}
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: 'error',
              children: formatMessage({
                id: 'ERROR_LOADING_DATA',
                defaultMessage: 'Error loading data',
              }),
            }
          : undefined
      }
      muiTablePaperProps={{ elevation: 0 }}
    />
  );
}

export default CustomerDataTable;
