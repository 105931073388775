import { Typography } from '@mui/material';
import { observer } from 'mobx-react';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {
  ConfirmationPopUp,
  DYNAMIC_FORM_MODE,
  DynamicForm,
  LoadingEvent,
  ScrollingProvider,
  SpecialSection,
  StackedFloatingButtons,
  useDataProvider,
  useSnackbar,
  useStore,
} from '@servicexcelerator/ui-design-system';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import getPageButtons from './lib/getPageButtons';
import HeaderSection from './Header/HeaderSection';
import getSteps from './lib/getSteps';
import getFormSchemas from './lib/getFormSchemas';
import getAdditionalComponents from './lib/getAdditionalComponents';
import submitServiceJob from './lib/submitServiceJob';
import createServiceJob from './lib/createServiceJob';
import updateServiceJob from './lib/updateServiceJob';
import CancelServiceJobModal from '../../modals/CancelServiceJobModal';
import acceptServiceJob from './lib/acceptServiceJob';
import rejectServiceJob from './lib/rejectServiceJob';

function getServiceJobIdFromRoute({ formMode, routeParam }) {
  if (
    formMode === DYNAMIC_FORM_MODE.EDIT ||
    formMode === DYNAMIC_FORM_MODE.VIEW
  ) {
    return routeParam?.serviceJobId;
  }
  return routeParam?.serviceJobRefId;
}

function ServiceJobPage({ formMode = DYNAMIC_FORM_MODE.VIEW, AppModule }) {
  const { Components, Constants, Utils } = AppModule;
  const { Layout, useUserAccess } = Components;
  const { COMPANY_TYPE } = Constants;
  const { ErrorMessage } = Utils;

  const provider =
    process.env.NODE_ENV === 'development' ? 'serviceJob' : 'default';

  const routeParam = useParams();
  const { updateOne, createOne, getOne } = useDataProvider(provider);
  const store = useStore();
  const { formatMessage } = useIntl();
  const snackbar = useSnackbar();
  const formRef = useRef();
  const cancelServiceJobPopUpRef = useRef();
  const confirmationPopUpRef = useRef();
  const navigate = useNavigate();
  const userAccess = useUserAccess();

  const [loading, setLoading] = useState(true);
  const [serviceJobNumber, setServiceJobNumber] = useState(null);
  const [pageError, setPageError] = useState(null);
  const [serviceJobData, setServiceJobData] = useState({});
  const [formSchemas, setFormSchemas] = useState({});
  const [mode, setMode] = useState(formMode);
  const [errorBlock, setErrorBlock] = useState([]);
  const [formSubmitting, setFormSubmitting] = useState(false);

  const masterData = store.domain.masterData.get();

  const isSA = userAccess.companyType === COMPANY_TYPE.SERVICE_ADMINISTRATOR;

  const fetchServiceJobData = async serviceJobNumberArg => {
    if (formMode === DYNAMIC_FORM_MODE.CREATE) {
      // eslint-disable-next-line no-unused-vars
      return store.domain.serviceJobs.getProspectiveServiceJob(
        serviceJobNumberArg,
      );
    }

    const result = await getOne(
      `servicejob/v1/servicejob/${serviceJobNumberArg}`,
    );
    return result?.data;
  };

  // TODO: Fix this, effect should be per form, have global effect functions and then apply them based on form
  const effectFunctions = () => ({
    handlePaymentApprovedFields: true,
    handlePAREditMode: false,
    readOnly: true,
    showAddButtonForParts: true,
    isNotSA: true,
  });

  useEffect(() => {
    async function init() {
      setErrorBlock(null);
      const serviceJobIdFromRoute = getServiceJobIdFromRoute({
        formMode,
        routeParam,
      });
      if (!serviceJobIdFromRoute) {
        setPageError({
          errorMessage: formatMessage({
            id: 'SERVICEJOB_NUMBER_NOT_PROVIDED',
            defaultMessage: 'Job not provided',
          }),
        });
      } else {
        setLoading(true);
        setServiceJobNumber(serviceJobIdFromRoute);

        const serviceJobDataFound = await fetchServiceJobData(
          serviceJobIdFromRoute,
          formMode,
        );

        if (serviceJobDataFound) {
          setServiceJobData({
            ...serviceJobDataFound,
            ...(mode === DYNAMIC_FORM_MODE.CREATE && {}),
          });

          const formSchemasByServiceJobFormId = getFormSchemas(
            serviceJobDataFound?.serviceJobFormId,
            isSA,
            serviceJobDataFound?.serviceJobStatus.key,
          );

          setFormSchemas(formSchemasByServiceJobFormId);
          if (
            ['ACC', 'CAN'].includes(serviceJobDataFound.serviceJobStatus.key)
          ) {
            setMode(DYNAMIC_FORM_MODE.VIEW);
          } else {
            setMode(formMode);
          }
        }
        setLoading(false);
      }
    }

    init();
  }, [formMode, routeParam?.serviceJobRefId, routeParam?.serviceJobId]);

  const onSubmitSuccess = async (data, additionalData) => {
    setFormSubmitting(true);
    setErrorBlock(null);

    const formData = {
      ...serviceJobData,
      ...data,
    };

    let result = null;
    const isSubmit =
      additionalData === 'submit' &&
      (mode === DYNAMIC_FORM_MODE.CREATE || mode === DYNAMIC_FORM_MODE.EDIT);

    if (isSubmit) {
      result = await submitServiceJob(formData, additionalData, {
        updateOne,
      });
    } else if (DYNAMIC_FORM_MODE.EDIT === mode) {
      result = await updateServiceJob(formData, additionalData, {
        updateOne,
        serviceJobNumber,
      });
    } else if (DYNAMIC_FORM_MODE.CREATE === mode) {
      result = await createServiceJob(formData, additionalData, {
        createOne,
      });
    }
    if (result) {
      if (additionalData !== 'saveOnlyScheduleFlow') {
        snackbar.showMessage({
          type: result.success ? '' : 'error',
          data: formatMessage(result.message.message, result.message.params),
        });
      }

      if (
        result.data &&
        result.data.serviceJobNumber &&
        additionalData === 'saveOnlyScheduleFlow'
      ) {
        navigate(`/servicejob/appointment/${result.data.serviceJobNumber}`);
      } else if (
        result.data &&
        result.data.serviceJobNumber &&
        serviceJobNumber !== result.data.serviceJobNumber
      ) {
        navigate(`/servicejob/edit/${result.data.serviceJobNumber}`, {
          replace: true,
        });
      } else if (result.data) {
        if (
          serviceJobData.serviceJobStatus.key !==
          result.data.serviceJobStatus.key
        ) {
          if (['ACC', 'CAN'].includes(result.data.serviceJobStatus.key)) {
            setMode(DYNAMIC_FORM_MODE.VIEW);
          } else {
            setMode(DYNAMIC_FORM_MODE.EDIT);
          }
        }

        setServiceJobData(result.data);
        formRef.current.update(result.data);
      }
      const newErrors = [];

      setErrorBlock(newErrors);
      formRef?.current?.setErrors(result.errors);
    }
    setFormSubmitting(false);
  };

  const cancelServiceJob = async () => {
    cancelServiceJobPopUpRef.current.setOpen(true);
  };

  const acceptServiceJobCallback = sj => {
    acceptServiceJob(
      sj,
      confirmationPopUpRef,
      formatMessage,
      snackbar,
      updateOne,
      ErrorMessage,
    );
  };

  const rejectServiceJobCallback = sj => {
    rejectServiceJob(
      sj,
      confirmationPopUpRef,
      formatMessage,
      snackbar,
      updateOne,
      navigate,
      ErrorMessage,
    );
  };

  const validSchema =
    formSchemas?.ui &&
    formSchemas?.ajv[DYNAMIC_FORM_MODE.CREATE === mode ? 'create' : 'update'];

  return (
    <Layout AppModule={AppModule}>
      <ScrollingProvider top={100}>
        <HeaderSection
          AppModule={AppModule}
          pageData={serviceJobData}
          steps={getSteps(
            formatMessage,
            serviceJobData?.serviceJobFormId,
            AppModule,
            isSA,
            serviceJobData?.serviceJobStatus?.key,
          )}
        />

        {loading && (
          <LoadingEvent mt={4}>
            <FormattedMessage id="PROCESSING" defaultMessage="Processing..." />
          </LoadingEvent>
        )}
        {!loading && !pageError && (
          <Grid2>
            <ConfirmationPopUp reference={confirmationPopUpRef} />
            <CancelServiceJobModal
              AppModule={AppModule}
              serviceJobNumber={serviceJobData?.serviceJobNumber}
              reference={cancelServiceJobPopUpRef}
              setMode={setMode}
              setServiceJobData={setServiceJobData}
            />
            {errorBlock}

            {validSchema ? (
              <DynamicForm
                key={serviceJobNumber}
                ignoreUnSaved={false}
                formId={serviceJobNumber}
                uiSchema={formSchemas.ui}
                jsonSchema={
                  formSchemas.ajv[
                    DYNAMIC_FORM_MODE.CREATE === mode ? 'create' : 'update'
                  ]
                }
                onSubmitSuccess={(data, additionalData) =>
                  onSubmitSuccess(data, additionalData)
                }
                onSubmitError={(data, rest) => {
                  // eslint-disable-next-line no-console
                  console.log('failure', data, rest, serviceJobData);
                  setFormSubmitting(false);
                  // setErrorBlock(data);
                }}
                reference={formRef}
                additionalComponents={getAdditionalComponents(
                  serviceJobData,
                  mode,
                  AppModule,
                )}
                initialFormValues={serviceJobData}
                formMode={mode}
                masterData={masterData}
                initialized={!loading}
                loadingComponent={<LoadingEvent>Loading Schema</LoadingEvent>}
                errors={[]}
                effectFunctions={effectFunctions()}
                unsavedChangeMessage={formatMessage({
                  id: 'UNSAVED_CHANGES_MESSAGE',
                  defaultMessage:
                    'There are some unsaved changes in the form. Do you want to discard all the changes?',
                })}
                formatMessage={formatMessage}
                memoParams={[serviceJobData?.serviceJobNumber, mode]}
              />
            ) : (
              <SpecialSection style={{ marginTop: '100px' }} color="error.main">
                <Typography mt={3} mb={3} color="error.main">
                  <FormattedMessage
                    id="FORM_SCHEMA_NOT_FOUND"
                    defaultMessage="Error: No schema information found to show the form"
                  />
                </Typography>
              </SpecialSection>
            )}

            <StackedFloatingButtons
              position={{ bottom: true, right: true }}
              buttons={getPageButtons({
                formSubmitting,
                formRef,
                setFormSubmitting,
                formatMessage,
                AppModule,
                serviceJobData,
                cancelServiceJob,
                acceptServiceJobCallback,
                rejectServiceJobCallback,
                navigate,
                serviceJobRefNumber: routeParam?.serviceJobRefId,
                isSA,
              })}
            />
          </Grid2>
        )}
      </ScrollingProvider>
    </Layout>
  );
}

export default observer(ServiceJobPage);
