import { Button, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

function MenuButton({ setOpen }) {
  const theme = useTheme();
  return (
    <Button
      variant="contained"
      sx={{
        boxShadow: theme.shadows[4],
        position: 'absolute',
        left: '10px',
        top: '10px',
        background: '#ffffff',
        paddingLeft: '10px',
        paddingRight: '10px',
        minWidth: '0px',
        color: '#000000',
        ':hover': {
          background: '#efefef',
        },
      }}
      onClick={() => setOpen(true)}>
      <MenuIcon />
    </Button>
  );
}

export default MenuButton;
