import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {
  LoadingEvent,
  useDataProvider,
} from '@servicexcelerator/ui-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { Avatar, Box, Card, Typography, styled, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

const ChatCard = styled(Card)({
  borderRadius: '15px',
  minWidth: '30%',
  maxWidth: '45%',
  display: 'inline-block',
  padding: '10px',
});

function ConversationRight({ message, createdTime, AppModule }) {
  const { Utils } = AppModule;
  const { toLongDate } = Utils;
  return (
    <Box mt={1} mb={1} textAlign="right">
      <Grid2 container>
        <Grid2 xs>
          <ChatCard variant="outlined">
            <Box textAlign="right">
              <Typography sx={{ fontWeight: 400 }} variant="body1">
                {message}
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption">
                {toLongDate(createdTime)}
              </Typography>
            </Box>
          </ChatCard>
        </Grid2>
        <Grid2 alignSelf="center">
          <Avatar sx={{ marginLeft: '8px' }}>SP</Avatar>
        </Grid2>
      </Grid2>
    </Box>
  );
}

function ConversationLeft({ message, createdTime, AppModule }) {
  const { Utils } = AppModule;
  const { toLongDate } = Utils;
  const theme = useTheme();
  return (
    <Box mt={1} mb={1} textAlign="left">
      <Grid2 container>
        <Grid2 alignSelf="center">
          <Avatar
            sx={{
              marginRight: '8px',
              bgcolor: theme.palette.primary.main,
            }}>
            CX
          </Avatar>
        </Grid2>
        <Grid2 xs>
          <ChatCard variant="outlined">
            <Box textAlign="left">
              <Typography sx={{ fontWeight: 400 }} variant="body1">
                {message}
              </Typography>
            </Box>
            <Box>
              <Typography textAlign="right" variant="caption">
                {toLongDate(createdTime)}
              </Typography>
            </Box>
          </ChatCard>
        </Grid2>
      </Grid2>
    </Box>
  );
}

function Conversation({ message, userType, createdTime, AppModule }) {
  if (userType === 'SERVICE_PROVIDER') {
    return (
      <ConversationRight
        message={message}
        userType={userType}
        createdTime={createdTime}
        AppModule={AppModule}
      />
    );
  }
  return (
    <ConversationLeft
      message={message}
      userType={userType}
      createdTime={createdTime}
      AppModule={AppModule}
    />
  );
}

function Conversations({ conversations, defaultMessage, AppModule }) {
  if (Array.isArray(conversations) && conversations.length === 0) {
    return <Typography>{defaultMessage}</Typography>;
  }

  return conversations.map(c => (
    <Conversation
      key={c?.createdTime}
      message={c?.message}
      userType={c?.user?.type}
      status={c?.status}
      createdTime={c?.createdTime}
      direction={c?.direction}
      AppModule={AppModule}
    />
  ));
}

function TwilioConversationFeed({ serviceJobData, AppModule }) {
  const { formatMessage } = useIntl();
  const defaultMessage = formatMessage({
    id: 'NO_DATA_EXISTS',
    defaultMessage: 'No data exists',
  });

  const provider =
    process.env.NODE_ENV === 'development' ? 'serviceJob' : 'default';
  const { getOne } = useDataProvider(provider);

  const [loading, setLoading] = useState(true);
  const [conversationData, setConversationData] = useState([]);

  const formatPhoneNumber = phoneNumber =>
    phoneNumber
      ? encodeURIComponent(`+1${phoneNumber.replace(/[\s-]/g, '')}`)
      : '';

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      if (serviceJobData?.customer?.customerPhoneManaged?.phone) {
        const spNumber =
          serviceJobData?.assignedServiceProvider?.managedPhoneNumber;

        const customerManagePhoneNumber = formatPhoneNumber(
          serviceJobData?.customer?.customerPhoneManaged?.phone,
        );
        const serviceProviderManagePhoneNumber = formatPhoneNumber(
          serviceJobData?.assignedServiceProvider?.managedPhoneNumber,
        );

        const spParam = spNumber
          ? `&serviceProviderManagePhoneNumber=${serviceProviderManagePhoneNumber}`
          : '';

        const result = await getOne(
          `/notifications/v1/conversation?customerManagePhoneNumber=${customerManagePhoneNumber}${spParam}&sendAfter=${serviceJobData?.serviceCreatedDate}`,
        );
        if (result) {
          setConversationData(result?.data?.conversation?.data || []);
        }
      }

      setLoading(false);
    };

    if (serviceJobData?.serviceJobNumber) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [serviceJobData?.serviceJobNumber]);

  return (
    <Grid2>
      {loading ? (
        <LoadingEvent>
          <FormattedMessage id="FETCHING_DATA" defaultMessage="Fetching Data" />
        </LoadingEvent>
      ) : (
        <Conversations
          conversations={conversationData}
          defaultMessage={defaultMessage}
          AppModule={AppModule}
        />
      )}
    </Grid2>
  );
}

export default TwilioConversationFeed;
