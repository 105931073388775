/*
 * Api domain differs for localhost, but is the same for AWS Environments
 */
function getDomain(host, postfix = 'api', forApi = true) {
  if (host && forApi) {
    if (postfix) {
      return `${host}/${postfix}`;
    }
    return `${host}`;
  }
  const { protocol, port, hostname } = window.location;
  const fullDomainPath = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
  return postfix ? `${fullDomainPath}/${postfix}` : fullDomainPath;
}

export default getDomain;
