import { isEmpty } from 'lodash';
import {
  CLAIM_ERROR_RULE_OUTCOME,
  CLAIM_REJECT_RULE_OUTCOME,
  CLAIM_REVIEW_RULE_OUTCOME,
} from './constants';

let idCounter = 0;
/**
 * @description Generate a unique key
 * @returns {string} A unique key
 */
export function getUniqueKey() {
  idCounter += 1;
  return idCounter.toString();
}

export function emptyCondition() {
  return {
    field: null,
    conditionId: getUniqueKey(),
    operator: null,
    value: '',
    valueSource: 'VALUE',
  };
}

export function emptyRuleDecision() {
  return {
    decisionId: getUniqueKey(),
    combinator: 'and',
    conditions: [],
  };
}

export function emptyRuleOutcome() {
  return {
    type: CLAIM_ERROR_RULE_OUTCOME,
    code: '',
    text: '',
  };
}

export function emptyRule() {
  const decision = emptyRuleDecision();
  decision.conditions.push(emptyCondition());
  return {
    ruleId: null,
    ruleName: '',
    priority: '',
    decision,
    outcome: emptyRuleOutcome(),
  };
}

/**
 * @param {array} filters
 * @returns {Array} A list of flattened filters
 */
export function generateFlattenedFilters(filters) {
  const sortedFilters = filters.map(({ label, options }) => ({
    label,
    options: options.sort((a, b) => a.label.localeCompare(b.label)),
  }));

  const list = [];
  sortedFilters.forEach(filter => {
    filter.options.forEach(option => {
      list.push({
        group: filter.label,
        ...option,
      });
    });
  });
  return list;
}

/**
 * @param {array} Facts
 * @returns {Object} A map of filters by value
 * @throws {Error} If a filter is duplicated
 * @example
 * const facts = [{ label: 'Claim', options: [{ field: 'laborApprovedAmount', label: 'Labor Approved Amount', type: 'number', path: "$.claimAmounts[LABOR]" }] }];
 * const map = generateFactMapByField(filters);
 * output : { laborApprovedAmount : {field: 'laborApprovedAmount', label: 'Labor Approved Amount', type: 'number', path: "$.claimAmounts[LABOR]"}}
 */
export function generateFactMapByField(facts) {
  const map = {};
  facts.forEach(fact => {
    const options = fact.options || [];
    options.forEach(option => {
      const { field } = option;
      if (Object.prototype.hasOwnProperty.call(map, field)) {
        throw new Error(`Duplicated filter: ${field}`);
      }
      map[field] = { ...option };
    });
  });
  return map;
}

/**
 * @param {array} operators
 * @returns {Object} A map of operators by value
 * @throws {Error} If an operator is duplicated
 * @example
 * const operators = [{ label: 'Equals', value: 'eq', types: ['number', 'text'] }];
 * const map = generateOperatorsMapByValue(operators);
 * output : { eq : { label: 'Equals', value: 'eq', types: ['number', 'text'] }}
 */
export function generateOperatorsMapByValue(operators) {
  const map = {};
  operators.forEach(operator => {
    const { value } = operator;
    if (Object.prototype.hasOwnProperty.call(map, value)) {
      throw new Error(`Duplicated operator: ${value}`);
    }
    map[value] = { ...operator };
  });
  return map;
}

/**
 * @param {array} operators
 * @returns {Object} A map of operators by type
 * @example
 * const operators = [{ label: 'Equals', value: 'eq', types: ['number', 'text'] }];
 * const map = generateOperatorsByType(operators);
 * output : { number: [{ label: 'Equals', value: 'eq', types: ['number', 'text'] }], text: [{ label: 'Equals', value: 'eq', types: ['number', 'text'] }] }
 */
export function generateOperatorsMapByType(operators) {
  const map = {};
  const types = [
    ...new Set([].concat(...operators.map(operator => operator.types))),
  ].sort();

  types.forEach(type => {
    if (!Object.prototype.hasOwnProperty.call(map, type)) {
      map[type] = [];
    }
    operators.forEach(operator => {
      if (operator.types.includes(type)) {
        map[type].push({
          label: operator.label,
          value: operator.value,
        });
      }
    });
  });
  Object.keys(map).forEach(key => {
    map[key] = map[key].sort((a, b) => a.label.localeCompare(b.label));
  });
  return map;
}

/**
 * Find a condition by id
 * @param {string} id
 * @param {array} conditions
 * @returns {object}
 */
export function findConditionById(id, conditions) {
  for (let idx = 0; idx < conditions.length; idx += 1) {
    const condition = conditions[idx];
    if (condition.conditionId === id) {
      return condition;
    }
    if (condition.conditions) {
      const found = findConditionById(id, condition.conditions);
      if (found) {
        return found;
      }
    }
  }
  return null;
}

/**
 * Find a node by decision id
 * @param {string} id
 * @param {object} node
 * @returns {object}
 */
export function findNodeByDecisionId(id, node) {
  if (node.decisionId === id) {
    return node;
  }
  if (node.conditions) {
    for (let idx = 0; idx < node.conditions.length; idx += 1) {
      const childNode = node.conditions[idx];
      if (childNode.combinator && childNode.decisionId === id) {
        return childNode;
      }
    }
  }
  return null;
}

export function getRuleOutcomeLabels(outcome) {
  const outcomeLabelOptionsMap = {
    [CLAIM_REJECT_RULE_OUTCOME]: {
      codeLabel: 'Reject Code',
      textLabel: 'Reject Reason',
    },
    [CLAIM_REVIEW_RULE_OUTCOME]: {
      codeLabel: 'Review Code',
      textLabel: 'Review Reason',
    },
    [CLAIM_ERROR_RULE_OUTCOME]: {
      codeLabel: 'Error Code',
      textLabel: 'Error Message',
    },
  };
  return outcomeLabelOptionsMap[outcome];
}

export function validateRuleOutcome(outcome) {
  const validationErrors = {};
  if (!outcome?.code) {
    validationErrors.outcomeCode = 'Rule outcome code is required';
  }
  if (!outcome?.text) {
    validationErrors.outcomeText = 'Rule outcome text is required';
  }
  return validationErrors;
}

export function validateCondition(condition) {
  const { field, operator } = condition;
  const validationErrors = {};
  if (!field) {
    validationErrors.fieldError = {
      errorMessage: 'Fact is required',
    };
  }
  if (!operator) {
    validationErrors.operatorError = {
      errorMessage: 'Operator is required',
    };
  }
  return validationErrors;
}

// Validate group with its own condition array
export function validateDecision(decision) {
  const decisionErrors = {};
  const conditionErrors = {};
  if (!decision.conditions || decision.conditions.length === 0) {
    decisionErrors[decision.decisionId] = 'Atleast one condition is required';
  } else {
    decision.conditions.forEach(condition => {
      const validateConditionResult = validateCondition(condition);
      if (!isEmpty(validateConditionResult)) {
        conditionErrors[condition.conditionId] = validateConditionResult;
      }
    });
  }
  return { decisionErrors, conditionErrors };
}

export function validateRule(rule) {
  let validationErrors = {};
  let conditionErrors = {};
  let decisionErrors = {};

  // check if rule name is empty
  if (!rule.ruleName) {
    validationErrors.ruleName = 'Rule Name is required';
  }

  // check if rule outcome has code and text
  const validateRuleOutcomeResult = validateRuleOutcome(rule.outcome);
  if (!isEmpty(validateRuleOutcomeResult)) {
    validationErrors = { ...validationErrors, ...validateRuleOutcomeResult };
  }

  if (rule.decision) {
    if (rule.decision.conditions.length === 0) {
      decisionErrors[rule.decision.decisionId] =
        'Atleast one condition is required';
    } else {
      rule.decision.conditions.forEach(condition => {
        // if conbinator is present in condition then its a group with multiple conditions
        if (condition.combinator) {
          const validateDecisionResult = validateDecision(condition);
          if (!isEmpty(validateDecisionResult.conditionErrors)) {
            conditionErrors = {
              ...conditionErrors,
              ...validateDecisionResult.conditionErrors,
            };
          }
          if (!isEmpty(validateDecisionResult.decisionErrors)) {
            decisionErrors = {
              ...decisionErrors,
              ...validateDecisionResult.decisionErrors,
            };
          }
        } else {
          const validateConditionResult = validateCondition(condition);
          if (!isEmpty(validateConditionResult)) {
            conditionErrors[condition.conditionId] = validateConditionResult;
          }
        }
      });
    }
  }
  if (!isEmpty(conditionErrors)) {
    validationErrors.conditionErrors = conditionErrors;
  }
  if (!isEmpty(decisionErrors)) {
    validationErrors.decisionErrors = decisionErrors;
  }

  return validationErrors;
}
