import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { useStore } from '@servicexcelerator/ui-design-system';
import { BrandProductType, Customer, Problem } from './columns';
import DataItemBlock from './DataItemBlock';

export default function JobDetails({ job, AppModule, loading }) {
  const { formatMessage } = useIntl();
  const { Utils } = AppModule;
  const { toMediumDate, MasterDataUtil } = Utils;
  const store = useStore();
  const masterData = store.domain.masterData.get();

  const convertTo12HourTime = militaryTime =>
    militaryTime ? dayjs(militaryTime, 'HHmm').format('hh:mm A') : null;

  const getTime = time => {
    const startTime = convertTo12HourTime(time);

    if (startTime) {
      return `after ${startTime}`;
    }
    return '';
  };

  const saClientName = MasterDataUtil.getSAClientById(
    masterData,
    job?.serviceAdministratorClientNumber,
  )?.label;

  const items = [
    {
      id: 'jobNumber',
      label: formatMessage({
        id: 'SERVICEJOB_NUMBER',
        description: 'Service Job Number',
      }),
      value: (
        <Typography color="primary.main" variant="h6">
          {job?.serviceJobNumber}
        </Typography>
      ),
      boxSize: 12,
    },
    {
      id: 'serviceDate',
      label: formatMessage({
        id: 'APPOINTMENT_DATE',
        description: 'Appointment Date',
      }),
      value: `${toMediumDate(job?.appointmentDate)} ${getTime(
        job?.arrivalWindowStartTime,
      )}`,
      boxSize: 6,
    },
    {
      id: 'client',
      label: formatMessage({
        id: 'CLIENT',
        description: 'Client',
      }),
      value: saClientName,
      boxSize: 6,
    },
    {
      id: 'customer',
      label: formatMessage({
        id: 'CUSTOMER',
        description: 'Customer',
      }),
      value: Customer(job),
      boxSize: 12,
    },
    {
      id: 'brandProductType',
      label: formatMessage({
        id: 'BRAND_AND_PRODUCT_TYPE',
        description: 'Brand/Product Type',
      }),
      value: BrandProductType(job),
      boxSize: 6,
    },
    {
      id: 'warrantyType',
      label: formatMessage({
        id: 'WARRANTY_TYPE',
        description: 'Warranty Type',
      }),
      value: job?.warrantyTypeId,
      boxSize: 6,
    },
    {
      id: 'problem',
      label: formatMessage({
        id: 'PROBLEM',
        description: 'Problem',
      }),
      value: Problem(job, formatMessage),
      boxSize: 12,
    },
    {
      id: 'status',
      label: formatMessage({
        id: 'STATUS',
        description: 'Status',
      }),
      value: job?.serviceJobStatus?.value,
      boxSize: 6,
    },
  ];

  return (
    <Box>
      <Grid2 container spacing={0}>
        {!loading &&
          items.map(item => (
            <DataItemBlock
              sx={{ marginBottom: '10px' }}
              xs={item.boxSize}
              key={item.id}
              label={item.label}
              value={item.value}
            />
          ))}
      </Grid2>
    </Box>
  );
}
