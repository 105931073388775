import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useImperativeHandle, useState } from 'react';
import { DialogTitle, Typography } from '@mui/material';
import {
  DYNAMIC_FORM_MODE,
  LoadingEvent,
  useDataProvider,
  useSnackbar,
} from '@servicexcelerator/ui-design-system';

function CancelServiceJobModal({
  reference,
  serviceJobNumber,
  AppModule,
  setMode,
  setServiceJobData,
}) {
  const { Utils } = AppModule;
  const { ErrorMessage } = Utils;
  const { formatMessage } = useIntl();
  const snackbar = useSnackbar();
  const provider =
    process.env.NODE_ENV === 'development' ? 'serviceJob' : 'default';
  const { updateOne } = useDataProvider(provider);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const showError = err => {
    snackbar.showMessage({
      type: 'error',
      data:
        err?.message ||
        formatMessage({
          id: 'ERROR_COULD_NOT_CANCEL_SERVICE_JOB',
          defaultMessage: 'Error: Could not cancel the job',
        }),
    });
  };

  const onChange = async () => {
    try {
      const result = await updateOne(
        `servicejob/v1/servicejob/${serviceJobNumber}/operation/cancel`,
      );
      if (result?.data) {
        snackbar.showMessage(
          formatMessage({
            id: 'SERVICE_JOB_CANCELLED_SUCCESSFULLY',
            defaultMessage: 'Job was cancelled successfully',
          }),
        );
        setServiceJobData(result.data);
        setMode(DYNAMIC_FORM_MODE.VIEW);
        setOpen(false);
      } else {
        showError(ErrorMessage(result));
      }
    } catch (err) {
      showError(err);
    }
  };

  useImperativeHandle(reference, () => ({
    setOpen(value) {
      setOpen(value);
    },
  }));

  useEffect(() => {
    if (!open) {
      setLoading(false);
    }
  }, [open]);

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: 400,
        bgcolor: 'background.paper',
      }}>
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        open={open}>
        {!loading && (
          <DialogTitle style={{ padding: '8px 20px 30px', fontSize: '18px' }}>
            <FormattedMessage
              id="CANCEL_SERVICE_JOB"
              defaultMessage="Cancel Job"
            />
          </DialogTitle>
        )}
        {loading ? (
          <DialogContent>
            <LoadingEvent>
              <FormattedMessage
                id="CANCELLING_SERVICE_JOB"
                defaultMessage="Cancelling Job..."
              />
            </LoadingEvent>
          </DialogContent>
        ) : (
          <DialogContent>
            <Typography>
              {formatMessage(
                {
                  id: 'DO_YOU_WANT_TO_CANCEL_SERVICE_JOB_X',
                  defaultMessage:
                    // eslint-disable-next-line no-template-curly-in-string
                    'Do you want to cancel the service job {serviceJobNumber}?',
                },
                {
                  serviceJobNumber,
                },
              )}
            </Typography>
          </DialogContent>
        )}
        {!loading ? (
          <DialogActions
            style={{
              justifyContent: 'center',
              marginTop: '10px',
              marginBottom: '10px',
            }}>
            <Button variant="contained" onClick={() => onChange(true)}>
              <FormattedMessage id="CANCEL_BUTTON" defaultMessage="Cancel" />
            </Button>
            <Button
              variant="outlined"
              style={{ marginLeft: '20px' }}
              onClick={() => setOpen(false)}>
              <FormattedMessage id="BACK_BUTTON" defaultMessage="Back" />
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
    </Box>
  );
}

export default CancelServiceJobModal;
