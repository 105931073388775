function ShortDateDisplay(props, fieldName, AppModule) {
  const {
    row: { original },
  } = props;
  const { Utils } = AppModule;
  const { toMediumDate } = Utils;

  return original[fieldName] ? toMediumDate(original[fieldName]) : '';
}

function BrandProductType(props) {
  const {
    row: { original },
  } = props;

  const name = [];
  if (original?.brand?.brandName) {
    name.push(original?.brand?.brandName);
  }
  if (original?.productType) {
    name.push(original?.productType);
  }
  return name.map((item, index) => {
    if (index !== name.length - 1) {
      return (
        <div key={JSON.stringify(item)}>
          {item}
          <br />
        </div>
      );
    }
    return <div key={JSON.stringify(item)}>{item}</div>;
  });
}

function ModelNumberSerialNumber(props) {
  const {
    row: { original },
  } = props;

  const data = [];
  if (original?.modelNumber) {
    data.push(original?.modelNumber);
  }
  if (original?.serialNumber) {
    data.push(original?.serialNumber);
  }
  return data.map((item, index) => {
    if (index !== data.length - 1) {
      return (
        <div key={JSON.stringify(item)}>
          {item}
          <br />
        </div>
      );
    }
    return <div key={JSON.stringify(item)}>{item}</div>;
  });
}

function Address(props) {
  const {
    row: { original },
  } = props;

  const address = [];
  if (original?.customerAddress?.addressStreet) {
    address.push(original?.customerAddress?.addressStreet);
  }
  if (original?.customerAddress?.addressCity) {
    address.push(original?.customerAddress?.addressCity);
  }
  if (original?.customerAddress?.addressState) {
    address.push(original?.customerAddress?.addressState);
  }
  if (original?.customerAddress?.addressPostalCode) {
    address.push(original?.customerAddress?.addressPostalCode);
  }
  return address?.join(', ');
}

const getColumns = (formatMessage, AppModule) => [
  {
    accessorKey: 'customerNameFirst',
    header: 'First Name',
  },
  {
    accessorKey: 'customerNameLast',
    header: 'Last Name',
  },
  {
    accessorKey: 'customeraddress',
    header: formatMessage({
      id: 'ADDRESS',
      defaultMessage: 'Address',
    }),
    enableEditing: false,
    Cell: props => Address(props),
  },
  {
    accessorKey: 'email',
    header: formatMessage({
      id: 'Email',
      defaultMessage: 'email',
    }),
  },
  {
    accessorKey: 'customerPhoneAlternate.phone',
    header: formatMessage({
      id: 'MOBILE_PHONE',
      defaultMessage: 'Mobile Phone',
    }),
  },
  {
    accessorKey: 'customerPhoneManaged.phone',
    header: 'Managed Phone',
  },
  {
    accessorKey: 'brandProductType',
    header: `${formatMessage({
      id: 'BRAND',
      defaultMessage: 'Brand',
    })} / ${formatMessage({
      id: 'PRODUCT_TYPE',
      defaultMessage: 'Product Type',
    })}`,
    enableEditing: false,
    Cell: props => BrandProductType(props),
  },
  {
    accessorKey: 'modelSerialNumber',
    header: `${formatMessage({
      id: 'MODEL_NUMBER',
      defaultMessage: 'Model Number',
    })} / ${formatMessage({
      id: 'SERIAL_NUMBER',
      defaultMessage: 'Serial Number',
    })}`,
    enableEditing: false,
    Cell: props => ModelNumberSerialNumber(props),
  },
  {
    accessorKey: 'productPurchaseDate',
    header: formatMessage({
      id: 'PURCHASE_DATE',
      defaultMessage: 'Purchase Date',
    }),
    enableEditing: false,
    Cell: props => ShortDateDisplay(props, 'productPurchaseDate', AppModule),
  },

  {
    accessorKey: 'contractNumber',
    header: formatMessage({
      id: 'CONTRACT_NUMBER',
      defaultMessage: 'Contract Number',
    }),
    enableEditing: false,
  },
];

export default getColumns;
