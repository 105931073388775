import { makeAutoObservable } from 'mobx';
import { CUSTOMER_SEARCH_SCHEMA } from '@servicexcelerator/ui-servicejob-module';
import { clearPersistedStore, makePersistable } from 'mobx-persist-store';

class CustomerSearchForm {
  params = JSON.stringify({});

  schemaType = JSON.stringify(CUSTOMER_SEARCH_SCHEMA.SIMPLE);

  constructor(rootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);

    makePersistable(this, {
      name: 'CustomerSearchFormStore',
      properties: ['params', 'schemaType'],
    });
  }

  set(newParams) {
    this.params = JSON.stringify(newParams);
  }

  get() {
    return JSON.parse(this.params);
  }

  setSchemaType(schemaType = CUSTOMER_SEARCH_SCHEMA.SIMPLE) {
    this.schemaType = JSON.stringify(schemaType);
  }

  getSchemaType() {
    return JSON.parse(this.schemaType) || CUSTOMER_SEARCH_SCHEMA.SIMPLE;
  }

  async clearStore() {
    await clearPersistedStore(this);
  }
}

export default CustomerSearchForm;
