import { makeAutoObservable } from 'mobx';
import { clearPersistedStore, makePersistable } from 'mobx-persist-store';

class RulesStore {
  rules = JSON.stringify([]);

  constructor(rootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);

    makePersistable(this, {
      name: 'ruleEditorStore',
      properties: ['rules'],
    });
  }

  setRules(rules) {
    this.rules = JSON.stringify(rules);
  }

  getRules() {
    return JSON.parse(this.rules);
  }

  async clearStore() {
    await clearPersistedStore(this);
  }
}

export default RulesStore;
