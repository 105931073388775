import { makeAutoObservable } from 'mobx';
import { clearPersistedStore, makePersistable } from 'mobx-persist-store';

class ClaimForms {
  claimForms = JSON.stringify([]);

  constructor(rootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);

    makePersistable(this, {
      name: 'claimFormsStore',
      properties: ['claimForms'],
    });
  }

  setClaimForms(claimForms) {
    this.claimForms = JSON.stringify(claimForms);
  }

  getClaimForms() {
    return JSON.parse(this.claimForms);
  }

  async clearStore() {
    await clearPersistedStore(this);
  }
}

export default ClaimForms;
