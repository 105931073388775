import { useState } from 'react';
import Typography from '@mui/material/Typography';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Card, Divider, IconButton, Slide } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import ServiceIcon from './ServiceIcon';
import LegendBlock from './LegendBlock';

export default function Legend() {
  const [open, setOpen] = useState(false);
  const { formatMessage } = useIntl();

  return (
    <div>
      <Button
        variant="contained"
        sx={{
          position: 'absolute',
          bottom: '55px',
          right: '50px',
          zIndex: 2,
          textTransform: 'none',
          padding: '2px 6px',
          fontSize: '12px',
          background: '#ffffff',
          color: '#000000',
          ':hover': {
            background: '#efefef',
          },
        }}
        onClick={() => setOpen(true)}>
        <FormattedMessage id="SERVICEJOB_SHOW_LEGEND" description="Legend" />
        <KeyboardDoubleArrowUpIcon
          fontSize="small"
          sx={{ marginLeft: '5px' }}
        />
      </Button>
      <Slide direction="up" in={open} mountOnEnter unmountOnExit>
        <Card
          elevation={8}
          sx={{
            position: 'absolute',
            bottom: '10px',
            right: '50px',
            padding: '5px 8px',
            minWidth: '300px',
            zIndex: 2,
          }}>
          <Grid2 sx={{ marginBottom: '10px' }}>
            <IconButton
              onClick={() => setOpen(false)}
              sx={{
                position: 'absolute',
                right: 0,
                top: '-2px',
              }}
              size="small"
              variant="contained">
              <HighlightOffRoundedIcon />
            </IconButton>
            <Typography
              variant="h6"
              sx={{ fontSize: '16px', color: 'primary.main' }}>
              <FormattedMessage
                id="SERVICE_PROVIDER_MATCH_LEGEND"
                description="Service Provider Match Legend"
              />
            </Typography>
            <Divider />
          </Grid2>

          <Grid2>
            <LegendBlock
              item={{
                name: formatMessage({
                  id: 'LEGEND_NEW_JOB',
                  description: 'New Job',
                }),
                Icon: ServiceIcon,
                iconProps: { startAngle: 0, endAngle: 90 },
              }}
            />
            <LegendBlock
              item={{
                name: formatMessage({
                  id: 'LEGEND_SERVICES_ZIP_CODE',
                  description: 'Services Zip Code',
                }),
                Icon: ServiceIcon,
                iconProps: { startAngle: 90, endAngle: 180 },
              }}
            />
            <LegendBlock
              item={{
                name: formatMessage({
                  id: 'LEGEND_SERVICES_BRAND',
                  description: 'Services Brand',
                }),
                Icon: ServiceIcon,
                iconProps: { startAngle: 180, endAngle: 270 },
              }}
            />
            <LegendBlock
              item={{
                name: formatMessage({
                  id: 'LEGEND_SERVICES_PRODUCT_TYPE',
                  description: 'Services Product Type',
                }),
                Icon: ServiceIcon,
                iconProps: { startAngle: 270, endAngle: 0 },
              }}
            />
            <Divider sx={{ marginBottom: '10px' }} />
            <LegendBlock
              item={{
                name: formatMessage({
                  id: 'LEGEND_IN_NETWORK',
                  description: 'In Network',
                }),
                Icon: StarBorderIcon,
                iconProps: { sx: { color: 'success.main' } },
              }}
            />
            <LegendBlock
              item={{
                name: formatMessage({
                  id: 'LEGEND_OUT_OF_NETWORK_COMMUNITY',
                  description: 'Out of Network/Community',
                }),
                Icon: ReceiptLongIcon,
                iconProps: { sx: { color: 'warning.light' } },
              }}
            />
            <LegendBlock
              item={{
                name: formatMessage({
                  id: 'LEGEND_OUT_OF_NETWORK_INDEPENDENT',
                  description: 'Out of Network/Independent',
                }),
                Icon: WarningAmberIcon,
                iconProps: { sx: { color: 'error.main' } },
              }}
            />
          </Grid2>
        </Card>
      </Slide>
    </div>
  );
}
