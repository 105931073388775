import { Button, Divider, FormLabel } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import InfoBlock from './InfoBlock';
import FormLabelCustom from './FormLabel';

function ServiceProviderInfo({ serviceProvider, AppModule }) {
  const { Constants } = AppModule;
  const { NETWORK_TYPE } = Constants;
  const { formatMessage } = useIntl();

  return (
    <Grid2 sx={{ padding: '0px 10px 10px 10px', minWidth: '280px' }}>
      <Grid2 sx={{ marginBottom: '5px' }}>
        <FormLabel>
          <FormattedMessage
            id="SERVICE_PROVIDER"
            description="Service Provider"
          />
        </FormLabel>
      </Grid2>
      <Grid2>
        <InfoBlock
          label={formatMessage({
            id: 'LEGEND_IN_NETWORK',
            description: 'In Network',
          })}
          value={
            serviceProvider?.services?.networkType === NETWORK_TYPE.IN_NETWORK
          }
        />
        <InfoBlock
          label={formatMessage({
            id: 'LEGEND_NEW_JOB',
            description: 'New Job',
          })}
          value={serviceProvider?.services?.newJob}
        />
        <InfoBlock
          label={formatMessage({
            id: 'LEGEND_SERVICES_ZIP_CODE',
            description: 'Services Zip Code',
          })}
          value={serviceProvider?.services?.zip}
        />
        <InfoBlock
          label={formatMessage({
            id: 'LEGEND_SERVICES_BRAND',
            description: 'Services Brand',
          })}
          value={serviceProvider?.services?.brand}
        />
        <InfoBlock
          label={formatMessage({
            id: 'LEGEND_SERVICES_PRODUCT_TYPE',
            description: 'Services Product Type',
          })}
          value={serviceProvider?.services?.productType}
        />
      </Grid2>
      <Divider sx={{ marginTop: '5px', marginBottom: '5px' }} />
      <Grid2 sx={{ marginBottom: '5px' }}>
        <FormLabel>
          <FormattedMessage id="CONTACT_INFO" description="Contact Info" />
        </FormLabel>
      </Grid2>
      <Grid2>
        <FormLabelCustom>
          {serviceProvider.firstName} {serviceProvider.lastName}
        </FormLabelCustom>
        <FormLabelCustom>{serviceProvider.email}</FormLabelCustom>
        <Button sx={{ textTransform: 'none' }} variant="text">
          <FormattedMessage id="SEND_MESSAGE" description="Send Message" />
        </Button>
      </Grid2>
      <Grid2 textAlign="right">
        <Button size="small" variant="contained">
          <FormattedMessage id="FORCE_BOOK" description="Force Book" />
        </Button>
      </Grid2>
    </Grid2>
  );
}

export default ServiceProviderInfo;
