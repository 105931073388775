import { makeAutoObservable } from 'mobx';
import { clearPersistedStore, makePersistable } from 'mobx-persist-store';

class ServiceJobListStore {
  pendingAcceptance = JSON.stringify([]);

  constructor(rootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);

    makePersistable(this, {
      name: 'ServiceJobListStore',
      properties: ['pendingAcceptance'],
    });
  }

  setPendingAcceptance(newParams) {
    this.pendingAcceptance = JSON.stringify(newParams);
  }

  getPendingAcceptance() {
    return JSON.parse(this.pendingAcceptance);
  }

  async clearStore() {
    await clearPersistedStore(this);
  }
}

export default ServiceJobListStore;
