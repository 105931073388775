import { LoggerUtil } from '@servicexcelerator/ui-design-system';

const hasServiceJobErrors = responseData =>
  responseData?.serviceJobErrors &&
  Array.isArray(responseData?.serviceJobErrors) &&
  responseData?.serviceJobErrors.length > 0;

async function submitServiceJob(data, additionalData, { updateOne }) {
  const result = {
    message: {
      message: {
        id: 'ERROR_SAVING_SERVICE_JOB',
        defaultMessage:
          'The Job Could Not be Submitted due to Errors on the Form',
      },
    },
    errors: null,
    success: false,
    data: null,
  };

  const serviceJobSubmitResponse = await updateOne(
    `/servicejob/v1/servicejob/operation/submit`,
    data,
  );

  // Todo: Need to figure out when we get errors vs error
  if (serviceJobSubmitResponse?.errors) {
    result.errors = serviceJobSubmitResponse.errors;
  } else if (serviceJobSubmitResponse?.error) {
    result.errors = serviceJobSubmitResponse.error;
  } else if (
    serviceJobSubmitResponse?.data &&
    hasServiceJobErrors(serviceJobSubmitResponse?.data)
  ) {
    result.errors = serviceJobSubmitResponse?.data?.serviceJobErrors;
    result.data = serviceJobSubmitResponse?.data;
  } else {
    result.data = serviceJobSubmitResponse?.data;
    result.success = true;
    result.message = {
      message: {
        id: 'SERVICE_JOB_X_SUBMITTED_SUCCESSFULLY',
        defaultMessage: 'Job {serviecJobNumber} was submitted successfully',
      },
      params: {
        serviecJobNumber: serviceJobSubmitResponse?.data?.serviecJobNumber,
      },
    };
  }

  LoggerUtil.log('Submit Response', {
    additionalData,
    request: data,
    response: serviceJobSubmitResponse,
    actionResult: result,
  });

  return result;
}

export default submitServiceJob;
