import { Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';

function MediumDateDisplay(props, fieldName, AppModule) {
  const {
    row: { original },
  } = props;
  const { Utils } = AppModule;
  const { toMediumDate } = Utils;

  return original[fieldName] ? toMediumDate(original[fieldName]) : '';
}

function CustomerName(props) {
  const {
    row: { original },
  } = props;

  const name = [];
  if (original?.customerNameFirst) {
    name.push(original?.customerNameFirst);
  }
  if (original?.customerNameLast) {
    name.push(original?.customerNameLast);
  }
  return name?.join(' ');
}

function ModelNumberSerialNumber(props) {
  const {
    row: { original },
  } = props;

  const data = [];
  if (original?.modelNumber) {
    data.push(original?.modelNumber);
  }
  if (original?.serialNumber) {
    data.push(original?.serialNumber);
  }
  return data.map((item, index) => {
    if (index !== data.length - 1) {
      return (
        <div key={JSON.stringify(item)}>
          {item}
          <br />
        </div>
      );
    }
    return <div key={JSON.stringify(item)}>{item}</div>;
  });
}

function Address(props) {
  const {
    row: { original },
  } = props;

  const address = [];
  if (original?.customerAddress?.addressStreet) {
    address.push(original?.customerAddress?.addressStreet);
  }
  if (original?.customerAddress?.addressCity) {
    address.push(original?.customerAddress?.addressCity);
  }
  if (original?.customerAddress?.addressState) {
    address.push(original?.customerAddress?.addressState);
  }
  if (original?.customerAddress?.addressPostalCode) {
    address.push(original?.customerAddress?.addressPostalCode);
  }
  return address?.join(', ');
}

function CustomerActions(props, onCustomerSelect) {
  const {
    row: { original: customerInfo },
  } = props;

  return (
    <Button
      size="small"
      variant="contained"
      onClick={() => onCustomerSelect(customerInfo)}>
      <FormattedMessage id="SELECT" defaultMessage="Select" />
    </Button>
  );
}

const getColumns = (formatMessage, AppModule, onCustomerSelect) => [
  {
    accessorKey: 'customerId',
    header: null,
    enableColumnActions: false,
    Cell: props => CustomerActions(props, onCustomerSelect),
  },
  {
    accessorKey: 'customername',
    header: formatMessage({
      id: 'NAME',
      defaultMessage: 'Name',
    }),
    Cell: props => CustomerName(props),
  },
  {
    accessorKey: 'customeraddress',
    header: formatMessage({
      id: 'ADDRESS',
      defaultMessage: 'Address',
    }),
    Cell: props => Address(props),
  },
  {
    accessorKey: 'customerPhone.phone',
    header: formatMessage({
      id: 'PHONE',
      defaultMessage: 'Phone',
    }),
  },
  {
    accessorKey: 'brand',
    header: `${formatMessage({
      id: 'BRAND',
      defaultMessage: 'Brand',
    })}`,
    Cell: props => {
      const {
        row: { original },
      } = props;
      return original?.brand?.brandName || '';
    },
  },
  {
    accessorKey: 'productType',
    header: `${formatMessage({
      id: 'PRODUCT_TYPE',
      defaultMessage: 'Product Type',
    })}`,
  },
  {
    accessorKey: 'modelSerialNumber',
    header: `${formatMessage({
      id: 'MODEL_NUMBER',
      defaultMessage: 'Model Number',
    })} / ${formatMessage({
      id: 'SERIAL_NUMBER',
      defaultMessage: 'Serial Number',
    })}`,
    Cell: props => ModelNumberSerialNumber(props),
  },

  {
    accessorKey: 'productPurchaseDate',
    header: formatMessage({
      id: 'PURCHASE_DATE',
      defaultMessage: 'Purchase Date',
    }),
    Cell: props => MediumDateDisplay(props, 'productPurchaseDate', AppModule),
  },

  {
    accessorKey: 'contractNumber',
    header: formatMessage({
      id: 'CONTRACT_NUMBER',
      defaultMessage: 'Contract Number',
    }),
  },
];

export default getColumns;
