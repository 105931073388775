import { makeAutoObservable } from 'mobx';
import { clearPersistedStore, makePersistable } from 'mobx-persist-store';

class CreateServiceJobModalStore {
  open = false;

  data = null;

  constructor(rootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);

    makePersistable(this, {
      name: 'CreateServiceJobModalStore',
      properties: ['open', 'data'],
    });
  }

  setOpen(open, data = null) {
    this.open = open;
    this.data = open ? data : null;
  }

  getData() {
    return this.data;
  }

  async clearStore() {
    await clearPersistedStore(this);
  }
}

export default CreateServiceJobModalStore;
