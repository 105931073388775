import Typography from '@mui/material/Typography';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

function LegendBlock({ item }) {
  return (
    <Grid2 container>
      <Grid2 xs={10}>
        <Typography variant="subtitle2">{item.name}</Typography>
      </Grid2>
      <Grid2 xs={2} textAlign="right">
        <item.Icon {...item.iconProps} />
      </Grid2>
    </Grid2>
  );
}

export default LegendBlock;
