import { useIntl } from 'react-intl';
import { useState, useMemo, useEffect } from 'react';
import { observer } from 'mobx-react';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { MaterialReactTable } from 'material-react-table';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { PageSection, useStore } from '@servicexcelerator/ui-design-system';
import ClaimFormSearch from '../../ui/ClaimFormSearch';
import getColumns from './lib/columns';
import CLAIM_FORMS from '../../common/claimforms';

function getExistingParamsFromUrlOrStore(store) {
  const currentUrl = new URL(window.location.href);
  const page = currentUrl?.searchParams?.get('page') || null;
  const limit = currentUrl?.searchParams?.get('limit') || null;
  const filters = currentUrl?.searchParams?.get('filters') || null;
  const sort = currentUrl?.searchParams?.get('sorting') || null;
  if (page || limit || filters || sort) {
    currentUrl?.searchParams?.delete('page');
    currentUrl?.searchParams?.delete('limit');
    currentUrl?.searchParams?.delete('filters');
    currentUrl?.searchParams?.delete('sorting');
    return {
      page: page ? parseInt(page, 10) : 0,
      limit: limit ? parseInt(limit, 10) : 20,
      filters: filters ? JSON.parse(filters) : [],
      sort: sort ? JSON.parse(sort) : [],
    };
  }
  return store.uiState.claimFormsSearch.get();
}

function columnFiltersArrayToObject(filters) {
  const columnFilterObject = filters?.reduce(
    (acc, item) => ({
      ...acc,
      [item.id]: item.value,
    }),
    {},
  );
  return columnFilterObject;
}

function ClaimForms({ AppModule }) {
  const { formatMessage } = useIntl();
  const store = useStore();
  const navigate = useNavigate();
  const params = getExistingParamsFromUrlOrStore(store);
  const { Icons, Components } = AppModule;
  const { ClaimForms: ClaimFormsIcon } = Icons;
  const { Layout, Header, DashboardHeader } = Components;
  const masterData = store.domain.masterData.get();
  const [pagination, setPagination] = useState({
    pageIndex: params?.page || 0,
    pageSize: params?.limit || 20,
  });
  const [columnFilters, setColumnFilters] = useState([]);
  const columns = useMemo(
    () => getColumns(formatMessage, AppModule, masterData),
    [formatMessage],
  );
  const [data, setData] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  useEffect(() => {
    const storeClaimForms = store.uiState.claimForms.getClaimForms();
    if (storeClaimForms.length === 0) {
      store.uiState.claimForms.setClaimForms(CLAIM_FORMS);
    }
    let claimForms = store.uiState.claimForms.getClaimForms();
    setRowCount(claimForms.length);
    const start =
      pagination.pageIndex === 0
        ? 0
        : pagination.pageIndex * pagination.pageSize;
    const end = start + pagination.pageSize;
    claimForms = claimForms.slice(start, end);
    const searchFilter = columnFiltersArrayToObject(columnFilters);
    if (columnFilters.length !== 0) {
      claimForms = claimForms.filter(claimForm =>
        Object.entries(searchFilter).every(([key, value]) => {
          switch (key) {
            case 'claimFormName':
              return claimForm.claimFormName
                .toLowerCase()
                .includes(value.toLowerCase());
            case 'status':
              return claimForm.status === value;
            case 'addressCountryCode':
              return (
                claimForm.addressCountryCode.length === 0 ||
                claimForm.addressCountryCode.includes(value)
              );
            case 'industryId':
              return (
                claimForm.industryId.length === 0 ||
                claimForm.industryId.includes(value)
              );
            case 'warrantyTypeId':
              return (
                claimForm.warrantyTypeId.length === 0 ||
                claimForm.warrantyTypeId.includes(value)
              );
            case 'serviceTypeId':
              return (
                claimForm.serviceTypeId.length === 0 ||
                claimForm.serviceTypeId.includes(value)
              );
            case 'serviceAdministratorClientNumber':
              return (
                claimForm.serviceAdministratorClientNumber.length === 0 ||
                claimForm.serviceAdministratorClientNumber.includes(value)
              );
            default:
              return false;
          }
        }),
      );
    }
    setData(claimForms);
    store.uiState.claimSearchForm.set({
      filters: columnFilters,
      rowCount,
      page: pagination?.pageIndex,
      limit: pagination?.pageSize,
    });
  }, [columnFilters, pagination.pageIndex, pagination.pageSize]);

  return (
    <Layout AppModule={AppModule}>
      <Header sx={{ mb: 2 }}>
        <DashboardHeader />
      </Header>

      <Grid2 mt={3}>
        <Grid2 mb={2}>
          <ClaimFormSearch
            AppModule={AppModule}
            onColumnFiltersChanged={newColumnFilters => {
              setColumnFilters(newColumnFilters);
            }}
            columnFilters={columnFilters}
            formValues={columnFilters}
          />
        </Grid2>
        <Grid2>
          <PageSection
            headerIcon={ClaimFormsIcon}
            label={formatMessage({
              id: 'CLAIM_FORMS',
              defaultMessage: 'Claim Forms',
            })}>
            <MaterialReactTable
              localization={store.domain.locale.getForMuiTable()}
              columns={columns}
              data={data}
              getRowId={row => row.ruleId}
              rowCount={rowCount}
              manualFiltering
              manualPagination
              manualSorting={false}
              onColumnFiltersChange={setColumnFilters}
              onPaginationChange={setPagination}
              enableColumnFilters={false}
              enableSorting={false}
              enableFilters={false}
              enableDensityToggle={false}
              enableFullScreenToggle={false}
              enableHiding={false}
              initialState={{
                showColumnFilters: false,
              }}
              state={{
                columnFilters,
                density: 'compact',
                pagination,
              }}
              muiTablePaperProps={{ elevation: 0 }}
              renderTopToolbarCustomActions={() => {
                const handleCreateNewRule = () => {
                  navigate(`/claimform/create`, { replace: true });
                };

                return (
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    sx={{ flexGrow: 1 }}>
                    <Button onClick={handleCreateNewRule} variant="contained">
                      Create
                    </Button>
                  </Box>
                );
              }}
            />
          </PageSection>
        </Grid2>
      </Grid2>
    </Layout>
  );
}

export default observer(ClaimForms);
