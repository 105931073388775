import Icons from '@components/Icons';
import { SERVICE_JOB_STATUS } from '@constants/index';

function ServiceJobStatusIcon({ statusId, ...props }) {
  const {
    ServiceJobStausPendingAcceptance,
    ServiceJobStausCancelled,
    ServiceJobStatusAccepted,
    ServiceJobStatusRejected,
    ServiceJobStausCompleted,
    ServiceJobStausPendingPreAuthorization,
    ServiceJobStausPendingParts,
    ServiceJobStausCustomerScheduling,
    ServiceJobStausIncomplete,
    Unknown,
  } = Icons;

  switch (statusId) {
    case SERVICE_JOB_STATUS.INC:
      return <ServiceJobStausIncomplete {...props} />;
    case SERVICE_JOB_STATUS.PAC:
      return (
        <ServiceJobStausPendingAcceptance style={{ color: 'red' }} {...props} />
      );
    case SERVICE_JOB_STATUS.CAN:
      return <ServiceJobStausCancelled style={{ color: 'green' }} {...props} />;
    case SERVICE_JOB_STATUS.ACC:
      return <ServiceJobStatusAccepted style={{ color: 'green' }} {...props} />;
    case SERVICE_JOB_STATUS.REJ:
      return <ServiceJobStatusRejected style={{ color: 'red' }} {...props} />;
    case SERVICE_JOB_STATUS.COM:
      return <ServiceJobStausCompleted style={{ color: 'green' }} {...props} />;
    case SERVICE_JOB_STATUS.PRA:
      return (
        <ServiceJobStausPendingPreAuthorization
          style={{ color: 'green' }}
          {...props}
        />
      );
    case SERVICE_JOB_STATUS.PPT:
      return (
        <ServiceJobStausPendingParts style={{ color: 'green' }} {...props} />
      );
    case SERVICE_JOB_STATUS.PCS:
      return (
        <ServiceJobStausCustomerScheduling
          style={{ color: 'green' }}
          {...props}
        />
      );
    default:
      return <Unknown {...props} />;
  }
}

export default ServiceJobStatusIcon;
