import {
  DATE_FORMAT,
  LoadingEvent,
  useDataProvider,
  useStore,
} from '@servicexcelerator/ui-design-system';
import { useEffect, useRef, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js';
import 'chart.js/auto';
import numeral from 'numeral';
import 'numeral/locales/en-au';
import { customAlphabet } from 'nanoid';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { FormattedMessage, useIntl } from 'react-intl';

function ClaimsDoughnut({
  initialData = [],
  apiUrl,
  urlParams,
  ignoreItems = [],
}) {
  // Added temporary flag, till we decide what to do with View Claims link
  const disableViewClaimLink = true;
  const { formatMessage } = useIntl();
  const store = useStore();
  const chartRef = useRef(null);
  const { getOne } = useDataProvider();
  const [data, setData] = useState(initialData);
  const [selectedLegends, setSelectedLegends] = useState(
    Array.from({ length: 5 }, () => 1),
  );
  const [loading, setLoading] = useState(true);
  const nanoid = customAlphabet('abcdefghijklmnoprstuvwxyz', 6);
  const buttonId = nanoid();

  const claimStatusFilter = JSON.stringify([
    urlParams?.find(item => item.id === 'claimStatus') || {
      id: 'claimStatus',
      value: 'INC',
    },
  ]);
  const seeDetailsUrl = `/claims/search?filters=${claimStatusFilter}`;

  // Language set for number display
  numeral.locale('en-au');

  const legendConfig = [];
  if (!ignoreItems.includes('claimCountToday')) {
    legendConfig.push({
      id: 'claimCountToday',
      label: formatMessage({
        id: 'TODAY',
        defaultMessage: 'Today',
      }),
      backgroundColor: '#FFCD2A',
      params: [
        {
          id: 'claimStatusDateTimeFrom',
          value: dayjs().format(DATE_FORMAT.STANDARD_DATE),
        },
      ],
    });
  }
  if (!ignoreItems.includes('claimCountYesterday')) {
    legendConfig.push({
      id: 'claimCountYesterday',
      label: formatMessage({
        id: 'YESTERDAY',
        defaultMessage: 'Yesterday',
      }),
      backgroundColor: '#FF9E0B',
      params: [
        {
          id: 'claimStatusDateTimeFrom',
          value: dayjs().add(-1, 'day').format(DATE_FORMAT.STANDARD_DATE),
        },
        {
          id: 'claimStatusDateTimeTo',
          value: dayjs().add(-1, 'day').format(DATE_FORMAT.STANDARD_DATE),
        },
      ],
    });
  }
  if (!ignoreItems.includes('claimCountLast2To7Days')) {
    legendConfig.push({
      id: 'claimCountLast2To7Days',
      label: formatMessage({
        id: 'LAST_2_7_DAYS',
        defaultMessage: 'Last 2-7 days',
      }),
      backgroundColor: '#2A81FC',
      params: [
        {
          id: 'claimStatusDateTimeFrom',
          value: dayjs().add(-7, 'day').format(DATE_FORMAT.STANDARD_DATE),
        },
        {
          id: 'claimStatusDateTimeTo',
          value: dayjs().add(-2, 'day').format(DATE_FORMAT.STANDARD_DATE),
        },
      ],
    });
  }
  if (!ignoreItems.includes('claimCountLast8To30Days')) {
    legendConfig.push({
      id: 'claimCountLast8To30Days',
      label: formatMessage({
        id: 'LAST_8_30_DAYS',
        defaultMessage: 'Last 8-30 days',
      }),
      backgroundColor: '#FF6157',
      params: [
        {
          id: 'claimStatusDateTimeFrom',
          value: dayjs().add(-30, 'day').format(DATE_FORMAT.STANDARD_DATE),
        },
        {
          id: 'claimStatusDateTimeTo',
          value: dayjs().add(-8, 'day').format(DATE_FORMAT.STANDARD_DATE),
        },
      ],
    });
  }
  if (!ignoreItems.includes('claimCountLast30To60Days')) {
    legendConfig.push({
      id: 'claimCountLast30To60Days',
      label: formatMessage({
        id: 'LAST_30_60_DAYS',
        defaultMessage: 'Last 30-60 days',
      }),
      backgroundColor: '#706F6F',
      params: [
        {
          id: 'claimStatusDateTimeFrom',
          value: dayjs().add(-60, 'day').format(DATE_FORMAT.STANDARD_DATE),
        },
        {
          id: 'claimStatusDateTimeTo',
          value: dayjs().add(-30, 'day').format(DATE_FORMAT.STANDARD_DATE),
        },
      ],
    });
  }
  if (!ignoreItems.includes('claimCountToday')) {
    legendConfig.push({
      id: 'claimCountMoreThan30Days',
      label: formatMessage({
        id: 'MORE_THAN_30_DAYS',
        defaultMessage: 'More than 30 days',
      }),
      backgroundColor: '#632ED4',
      params: [
        {
          id: 'claimStatusDateTimeTo',
          value: dayjs().add(-31, 'day').format(DATE_FORMAT.STANDARD_DATE),
        },
      ],
    });
  }

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const result = await getOne(apiUrl);
      if (result?.data) {
        const {
          claimCountToday = 0,
          claimCountYesterday = 0,
          claimCountLast2To7Days = 0,
          claimCountLast8To30Days = 0,
          claimCountLast30To60Days = 0,
          claimCountMoreThan30Days = 0,
        } = result.data;

        const dataItems = {
          claimCountToday,
          claimCountYesterday,
          claimCountLast2To7Days,
          claimCountLast8To30Days,
          claimCountLast30To60Days,
          claimCountMoreThan30Days,
        };

        const filteredData = Object.entries(dataItems).reduce(
          (acc, [key, value]) => {
            if (ignoreItems.includes(key)) {
              return acc;
            }
            return [...acc, value];
          },
          [],
        );

        setData(filteredData);
      }
      setLoading(false);
    }

    fetchData();
  }, []);

  if (loading) {
    return (
      <LoadingEvent>
        <FormattedMessage
          id="GETTING_STATS"
          defaultMessage="Getting stats..."
        />
      </LoadingEvent>
    );
  }

  const onChartClick = (evt, item) => {
    const { current: chart } = chartRef;
    if (!chart) {
      return;
    }

    if (
      evt &&
      item &&
      Array.isArray(item) &&
      item.length > 0 &&
      Object.prototype.hasOwnProperty.call(item[0], 'index')
    ) {
      const filters = [...urlParams, ...legendConfig[item[0].index].params];
      store.uiState.claimSearchForm.set({
        filters,
      });
      window.location = `/claims/search`;
    }
  };

  const onLegendClick = (evt, item) => {
    const { current: chart } = chartRef;
    if (!chart) {
      return;
    }
    if (evt && item && Object.prototype.hasOwnProperty.call(item, 'index')) {
      const newSelectedLegends = JSON.parse(JSON.stringify(selectedLegends));

      newSelectedLegends[item.index] =
        newSelectedLegends[item.index] === 1 ? 0 : 1;

      setSelectedLegends(newSelectedLegends);
    }
    ChartJS.overrides.doughnut.plugins.legend.onClick(
      evt,
      { ...item, datasetIndex: 0 },
      { chart: chartRef.current },
    );
  };

  const total = selectedLegends.reduce(
    (acc, item, index) => acc + data[index] * item,
    0,
  );

  const friendlyTotal = total > 9999 ? numeral(total).format('0a') : total;

  return (
    <>
      {!disableViewClaimLink && (
        <Button
          id={buttonId}
          onClick={() => {
            window.location.href = seeDetailsUrl;
          }}>
          <FormattedMessage id="SEE_DETAILS" defaultMessage="See Details" />
        </Button>
      )}
      <div>
        <Doughnut
          ref={chartRef}
          height="200px"
          data={{
            labels: legendConfig.reduce((acc, item) => {
              if (ignoreItems.includes(item.id)) {
                return acc;
              }
              return [...acc, item.label];
            }, []),
            datasets: [
              {
                label: formatMessage({
                  id: 'CLAIMS',
                  defaultMessage: 'Claims',
                }),
                data,
                backgroundColor: legendConfig.reduce((acc, item) => {
                  if (ignoreItems.includes(item.id)) {
                    return acc;
                  }
                  return [...acc, item.backgroundColor];
                }, []),
                borderWidth: 0,
              },
            ],
          }}
          options={{
            singularLabel: formatMessage({
              id: 'CLAIM',
              defaultMessage: 'Claim',
            }),
            pluralLabel: formatMessage({
              id: 'CLAIMS',
              defaultMessage: 'Claims',
            }),
            seeDetailButtonId: buttonId,
            claimUrl: seeDetailsUrl,
            friendlyCount: friendlyTotal,
            count: total,
            maintainAspectRatio: false,
            cutout: 75,
            layout: {
              autoPadding: true,
            },
            onClick: onChartClick,
            plugins: {
              legend: {
                position: 'right',
                onClick: (evt, item) => {
                  onLegendClick(evt, item);
                },
                labels: {
                  usePointStyle: true,
                  pointStyle: 'rectRounded',
                },
              },
            },
          }}
        />
      </div>
    </>
  );
}

export default ClaimsDoughnut;
