const RulesList = [
  {
    ruleId: '1',
    ruleName: 'Address Street is required',
    priority: 1,
    decision: {
      combinator: 'and',
      decisionId: '1',
      conditions: [
        {
          conditionId: '1',
          field: 'addressStreet',
          operator: 'isEmpty',
          valueSource: 'VALUE',
          value: null,
        },
      ],
    },
    outcome: {
      type: 'CLAIM_ERROR',
      code: 'ADDRESS_STREET_REQUIRED',
      text: 'Address Street is required',
    },
  },
  {
    ruleId: '2',
    ruleName: 'Service Completion Date Required',
    priority: 1,
    decision: {
      combinator: 'and',
      decisionId: '1',
      conditions: [
        {
          conditionId: '1',
          field: 'serviceCompletionDate',
          operator: 'isEmpty',
          valueSource: 'VALUE',
          value: null,
        },
      ],
    },
    outcome: {
      type: 'CLAIM_ERROR',
      code: 'SERVICE_COMPLETION_DATE_REQUIRED',
      text: 'Service Completion Date is required',
    },
  },
  {
    ruleId: '3',
    ruleName: 'Service Completion Date is in the future',
    priority: 1,
    decision: {
      combinator: 'and',
      decisionId: '1',
      conditions: [
        {
          conditionId: '1',
          field: 'serviceCompletionDate',
          operator: 'isNotEmpty',
          valueSource: 'VALUE',
          value: null,
        },
        {
          conditionId: '2',
          field: 'serviceCompletionDate',
          operator: 'isFutureDate',
        },
      ],
    },
    outcome: {
      type: 'CLAIM_ERROR',
      code: 'SERVICE_COMPLETION_DATE_IN_FUTURE',
      text: 'Service Completion Date is in the future',
    },
  },
  {
    ruleId: '4',
    ruleName: 'Customer Last Name or Company Name required',
    priority: 1,
    decision: {
      combinator: 'or',
      decisionId: '1',
      conditions: [
        {
          conditionId: '1',
          field: 'customerLastName',
          operator: 'isEmpty',
        },
        {
          conditionId: '2',
          field: 'customerCompanyName',
          operator: 'isEmpty',
        },
      ],
    },
    outcome: {
      type: 'CLAIM_ERROR',
      code: 'LAST_NAME_OR_COMPANY_NAME_REQUIRED',
      text: 'Customer Last Name or Company Name is required',
    },
  },
  {
    ruleId: '5',
    ruleName: 'Service requested date greater than service completion date',
    priority: '',
    decision: {
      decisionId: '1',
      combinator: 'and',
      conditions: [
        {
          field: 'serviceRequestedDate',
          conditionId: '1',
          operator: 'isNotEmpty',
          value: null,
          valueSource: 'VALUE',
        },
        {
          field: 'serviceCompletionDate',
          conditionId: '2',
          operator: 'isNotEmpty',
          value: null,
          valueSource: 'VALUE',
        },
        {
          field: 'serviceRequestedDate',
          conditionId: '3',
          operator: 'after',
          value: {
            field: 'serviceCompletionDate',
          },
          valueSource: 'FACT',
        },
      ],
    },
    outcome: {
      type: 'CLAIM_ERROR',
      code: 'SERVICE_REQUESTED_DATE_GREATER_THAN_SERVICE_COMPLETION_DATE',
      text: 'Service requested date greater than service completion date',
    },
  },
  {
    ruleId: '6',
    ruleName:
      'Duplicate Claim with same service provider job number and service completion date',
    priority: '',
    decision: {
      decisionId: '1',
      combinator: 'and',
      conditions: [
        {
          field: 'serviceProviderJobNumber',
          conditionId: '1',
          operator: 'isNotEmpty',
          value: null,
          valueSource: 'VALUE',
        },
        {
          field: 'serviceCompletionDate',
          conditionId: '2',
          operator: 'isNotEmpty',
          value: null,
          valueSource: 'VALUE',
        },
        {
          field: 'serviceAdministratorClient',
          conditionId: '3',
          operator: 'isNotEmpty',
          value: null,
          valueSource: 'VALUE',
        },
        {
          field: 'serviceType',
          conditionId: '4',
          operator: 'isNotEmpty',
          value: null,
          valueSource: 'VALUE',
        },
        {
          field: 'currentClaim',
          conditionId: '5',
          operator: 'isDuplicateClaim',
          value: [
            'serviceType',
            'serviceCompletionDate',
            'serviceProviderJobNumber',
            'serviceAdministratorClient',
          ],
          valueSource: 'VALUE',
        },
      ],
    },
    outcome: {
      type: 'CLAIM_REVIEW',
      code: '107',
      text: 'Duplicate Claim found with same Service Provider Job Number and Service Completion Date',
    },
  },
  {
    ruleId: '7',
    ruleName:
      'Duplicate Claim with same model number, serial number, and service completion date',
    priority: '',
    decision: {
      decisionId: '1',
      combinator: 'and',
      conditions: [
        {
          field: 'modelNumber',
          conditionId: '1',
          operator: 'isNotEmpty',
          value: null,
          valueSource: 'VALUE',
        },
        {
          field: 'serialNumber',
          conditionId: '2',
          operator: 'isNotEmpty',
          value: null,
          valueSource: 'VALUE',
        },
        {
          field: 'serviceCompletionDate',
          conditionId: '3',
          operator: 'isNotEmpty',
          value: null,
          valueSource: 'VALUE',
        },
        {
          field: 'serviceAdministratorClient',
          conditionId: '4',
          operator: 'isNotEmpty',
          value: null,
          valueSource: 'VALUE',
        },
        {
          field: 'currentClaim',
          conditionId: '5',
          operator: 'isDuplicateClaim',
          value: [
            'modelNumber',
            'serialNumber',
            'serviceType',
            'serviceAdministratorClient',
            'serviceCompletionDate',
          ],
          valueSource: 'VALUE',
        },
      ],
    },
    outcome: {
      type: 'CLAIM_REVIEW',
      code: '106',
      text: 'Duplicate Claim found with same Model Number, Serial Number, and Service Completion Date',
    },
  },
  {
    ruleId: '8',
    ruleName: 'Send All claims to review',
    priority: '',
    decision: {
      decisionId: '1',
      combinator: 'and',
      conditions: [
        {
          field: 'sendAllClaimsToReview',
          conditionId: '1',
          operator: 'equal',
          value: 'YES',
          valueSource: 'VALUE',
        },
      ],
    },
    outcome: {
      type: 'CLAIM_REVIEW',
      code: '121',
      text: 'Claim requires Service Administrator Review',
    },
  },
  {
    ruleId: '9',
    ruleName: 'Time to submit claim exceeded',
    priority: '',
    decision: {
      decisionId: '1',
      combinator: 'and',
      conditions: [
        {
          field: 'serviceRequestedDate',
          conditionId: '1',
          operator: 'isNotEmpty',
          value: null,
          valueSource: 'VALUE',
        },
        {
          field: 'isTimeToSubmitClaimExceeded',
          conditionId: '2',
          operator: 'equal',
          value: 'TRUE',
          valueSource: 'VALUE',
        },
      ],
    },
    outcome: {
      type: 'CLAIM_REVIEW',
      code: '109',
      text: 'Time to submit claim exceeded',
    },
  },
  {
    ruleId: '10',
    ruleName: 'Labor amount exceeds authorized maximum',
    priority: '',
    decision: {
      decisionId: '1',
      combinator: 'and',
      conditions: [
        {
          field: 'laborApprovedAmount',
          conditionId: '1',
          operator: 'isNotEmpty',
          value: null,
          valueSource: 'VALUE',
        },
        {
          field: 'laborApprovedAmount',
          conditionId: '2',
          operator: 'greater',
          value: {
            field: 'laborAuthorizedAmount',
          },
          valueSource: 'FACT',
        },
      ],
    },
    outcome: {
      type: 'CLAIM_REVIEW',
      code: '110',
      text: 'Labor amount exceeds authorized maximum',
    },
  },
];
export default RulesList;
