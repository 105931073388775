import { observer } from 'mobx-react';
import { useState, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { MaterialReactTable } from 'material-react-table';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { PageSection, useStore } from '@servicexcelerator/ui-design-system';
import RuleSearch from '../../ui/RuleSearch';
import getColumns from './lib/columns';
import Rules from '../../common/rules';
import Facts from '../../common/facts';
import { generateFactMapByField } from '../../common/util';

function getExistingParamsFromUrlOrStore(store) {
  const currentUrl = new URL(window.location.href);
  const page = currentUrl?.searchParams?.get('page') || null;
  const limit = currentUrl?.searchParams?.get('limit') || null;
  const filters = currentUrl?.searchParams?.get('filters') || null;
  const sort = currentUrl?.searchParams?.get('sorting') || null;
  if (page || limit || filters || sort) {
    currentUrl?.searchParams?.delete('page');
    currentUrl?.searchParams?.delete('limit');
    currentUrl?.searchParams?.delete('filters');
    currentUrl?.searchParams?.delete('sorting');
    return {
      page: page ? parseInt(page, 10) : 0,
      limit: limit ? parseInt(limit, 10) : 20,
      filters: filters ? JSON.parse(filters) : [],
      sort: sort ? JSON.parse(sort) : [],
    };
  }
  return store.uiState.claimSearchForm.get();
}

function SearchRules({ AppModule }) {
  const { formatMessage } = useIntl();
  const store = useStore();
  const navigate = useNavigate();

  const { Components, Icons } = AppModule;
  const { ClaimRules: ClaimRulesIcon } = Icons;
  const { Layout, Header, DashboardHeader } = Components;
  const params = getExistingParamsFromUrlOrStore(store);
  const [columnFilters, setColumnFilters] = useState(params?.filters || []);
  const [data, setData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const factFieldMap = useMemo(() => generateFactMapByField(Facts), [Facts]);
  const [pagination, setPagination] = useState({
    pageIndex: params?.page || 0,
    pageSize: params?.limit || 20,
  });
  const columns = useMemo(
    () => getColumns(formatMessage, AppModule),
    [formatMessage],
  );

  function columnFiltersArrayToObject(filters) {
    const columnFilterObject = filters?.reduce(
      (acc, item) => ({
        ...acc,
        [item.id]: item.value,
      }),
      {},
    );
    return columnFilterObject;
  }

  useEffect(() => {
    const storeRules = store.uiState.rules.getRules();
    if (storeRules.length === 0) {
      store.uiState.rules.setRules(Rules);
    }
    let rules = store.uiState.rules.getRules();
    setRowCount(rules.length);
    const start =
      pagination.pageIndex === 0
        ? 0
        : pagination.pageIndex * pagination.pageSize;
    const end = start + pagination.pageSize;
    rules = rules.slice(start, end);

    const searchFilter = columnFiltersArrayToObject(columnFilters);
    if (columnFilters.length !== 0) {
      rules = rules.filter(rule =>
        Object.entries(searchFilter).every(([key, value]) => {
          switch (key) {
            case 'ruleName':
              return rule[key].toLowerCase().includes(value.toLowerCase());
            case 'ruleOutcome':
              return rule.outcome.type === value;
            case 'factField':
              return rule.decision.conditions.some(
                condition =>
                  condition.field === value ||
                  condition.conditions?.some(
                    subCondition => subCondition.field === value,
                  ),
              );
            default:
              return false;
          }
        }),
      );
    }
    setData(rules);

    store.uiState.claimSearchForm.set({
      filters: columnFilters,
      rowCount,
      page: pagination?.pageIndex,
      limit: pagination?.pageSize,
    });
  }, [columnFilters, pagination.pageIndex, pagination.pageSize]);

  return (
    <Layout AppModule={AppModule}>
      <Header sx={{ mb: 2 }}>
        <DashboardHeader />
      </Header>

      <Grid2 mt={3}>
        <Grid2 mb={2}>
          <RuleSearch
            AppModule={AppModule}
            onColumnFiltersChanged={newColumnFilters => {
              setColumnFilters(newColumnFilters);
            }}
            columnFilters={columnFilters}
            formValues={columnFilters}
            factFieldMap={factFieldMap}
          />
        </Grid2>
        <Grid2>
          <PageSection
            headerIcon={ClaimRulesIcon}
            label={formatMessage({
              id: 'CLAIM_RULES',
              defaultMessage: 'Claim Rules',
            })}>
            <MaterialReactTable
              localization={store.domain.locale.getForMuiTable()}
              columns={columns}
              data={data}
              getRowId={row => row.ruleId}
              rowCount={rowCount}
              manualFiltering
              manualPagination
              manualSorting={false}
              onColumnFiltersChange={setColumnFilters}
              onPaginationChange={setPagination}
              enableColumnFilters={false}
              enableSorting={false}
              enableFilters={false}
              enableDensityToggle={false}
              enableFullScreenToggle={false}
              enableHiding={false}
              initialState={{
                showColumnFilters: false,
              }}
              state={{
                columnFilters,
                density: 'compact',
                pagination,
              }}
              muiTablePaperProps={{ elevation: 0 }}
              renderTopToolbarCustomActions={() => {
                const handleCreateNewRule = () => {
                  navigate(`/rules/create`, { replace: true });
                };

                return (
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    sx={{ flexGrow: 1 }}>
                    <Button onClick={handleCreateNewRule} variant="contained">
                      Create New Rule
                    </Button>
                  </Box>
                );
              }}
            />
          </PageSection>
        </Grid2>
      </Grid2>
    </Layout>
  );
}

export default observer(SearchRules);
