import { makeAutoObservable } from 'mobx';
import { clearPersistedStore, makePersistable } from 'mobx-persist-store';

class ServiceJobStore {
  prospectiveServiceJobs = {};

  serviceJobs = {};

  constructor(rootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);

    makePersistable(this, {
      name: 'ServiceJobStore',
      properties: ['prospectiveServiceJobs', 'serviceJobs'],
    });
  }

  setServiceJob(serviceJobNumber, value) {
    this.serviceJobs[serviceJobNumber] = value;
  }

  deleteServiceJob(serviceJobNumber) {
    delete this.serviceJobs[serviceJobNumber];
  }

  getServiceJob(serviceJobNumber) {
    return this.serviceJobs[serviceJobNumber];
  }

  setProspectiveServiceJob(serviceJobNumber, value) {
    this.prospectiveServiceJobs[serviceJobNumber] = value;
  }

  deleteProspectiveServiceJob(serviceJobNumber) {
    delete this.prospectiveServiceJobs[serviceJobNumber];
  }

  getProspectiveServiceJob(serviceJobNumber) {
    return this.prospectiveServiceJobs[serviceJobNumber];
  }

  async clearStore() {
    await clearPersistedStore(this);
  }
}

export default ServiceJobStore;
