import { Box, Button } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {
  DYNAMIC_FORM_MODE,
  DynamicForm,
  LoadingEvent,
  useDataProvider,
  useStore,
} from '@servicexcelerator/ui-design-system';
import { nanoid } from 'nanoid';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import jsonSchema from './jsonSchema.json';
import uiSchema from './uiSchema.json';

function CreateForm() {
  const provider =
    process.env.NODE_ENV === 'development' ? 'serviceJob' : 'default';

  const { getOne } = useDataProvider(provider);
  const formRef = useRef();
  const store = useStore();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const masterData = store.domain.masterData.get();

  const buttons = [
    {
      color: 'primary',
      variant: 'contained',
      children: formatMessage({
        id: 'CREATE_SERVICEJOB_BUTTON',
        defaultMessage: 'Create Job',
      }),
      type: 'button',
      onClick: () => formRef.current.submitForm(),
    },
    {
      color: 'secondary',
      variant: 'contained',
      children: formatMessage({
        id: 'CANCEL_BUTTON',
        defaultMessage: 'Cancel',
      }),
      type: 'button',
      onClick: () => store.uiState.createServiceJobModal.setOpen(false),
    },
  ];

  const onSubmitSuccess = async data => {
    const serviceJobRef = nanoid();

    const preServiceJobData = {
      serviceAdministratorClientNumber: data.serviceAdministratorClientNumber,
      warrantyTypeId: data.warrantyTypeId,
      industryTypeId: data.industryTypeId,
      serviceTypeId: data.serviceTypeId,
      serviceSite: data.serviceSite,
      country: data.country,
      productType: data.productType,
    };

    const request = await getOne(
      `/servicejob/v1/servicejob/operation/new`,
      preServiceJobData,
    );

    const customerData = store.uiState.createServiceJobModal.getData();

    store.domain.serviceJobs.setProspectiveServiceJob(serviceJobRef, {
      ...request.data,
      customer: customerData || {},
      brand: {
        brandCode: customerData?.brand?.brandCode,
      },
      modelNumber: customerData?.modelNumber,
      serialNumber: customerData?.serialNumber,
      productPurchaseDate: customerData?.productPurchaseDate,
    });

    navigate(`/servicejob/create/${serviceJobRef}`, { replace: true });
    store.uiState.createServiceJobModal.setOpen(false);
  };

  const onSubmitError = () => {
    // navigate('/ServiceJob-create');
  };

  return (
    <Box>
      <DynamicForm
        formId="serviceJobCreate"
        uiSchema={uiSchema}
        jsonSchema={jsonSchema}
        onSubmitSuccess={onSubmitSuccess}
        onSubmitError={onSubmitError}
        reference={formRef}
        masterData={masterData}
        initialized
        loadingComponent={
          <LoadingEvent>
            <FormattedMessage
              id="LOADING_SCHEMA"
              defaultMessage="Loading Schema"
            />
          </LoadingEvent>
        }
        formMode={DYNAMIC_FORM_MODE.EDIT}
        formatMessage={formatMessage}
      />
      <Grid2 container spacing={2} mt={6} justifyContent="center">
        <Grid2 textAlign="right" xs={6}>
          <Button {...buttons[0]} />
        </Grid2>
        <Grid2 textAlign="left" xs={6}>
          <Button {...buttons[1]} />
        </Grid2>
      </Grid2>
    </Box>
  );
}

export default observer(CreateForm);
