import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { TimePicker as TimePickerMui } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';

import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { DYNAMIC_FORM_MODE } from '../DynamicForm/lib/constants';
import FormValue from '../FormValue';
import applyEffects from '../DynamicForm/lib/effects';
import { useStore } from '../../MobxProvider';

function TimePicker(props) {
  return <TimePickerMui {...props} />;
}

export const TimePickerFormControl = observer(props => {
  const {
    label,
    localeId = null,
    helperText,
    control,
    name,
    fullWidth = false,
    formMode,
    effects = null,
    effectFunctions = null,
    maxTimeField = null,
    minTimeField = null,
    formId,
    dataType,
    ...rest
  } = props;

  const store = useStore();

  const [minTime, setMinTime] = useState(null);
  const [maxTime, setMaxTime] = useState(null);

  const effectProps = effectFunctions
    ? applyEffects(effects, effectFunctions)
    : null;

  useEffect(() => {
    if (store.domain.formState.getState(formId)) {
      if (minTimeField) {
        const stateValue =
          store.domain.formState.getState(formId)[minTimeField];
        if (stateValue) {
          setMinTime(stateValue);
        }
      }
      if (maxTimeField) {
        const stateValue =
          store.domain.formState.getState(formId)[maxTimeField];
        if (stateValue) {
          setMaxTime(stateValue);
        }
      }
    }
  }, [store.domain.formState.getState(formId)]);

  const { disabled = false } = effectProps || { disabled: false };

  const getCurrentDateWithRequiredTime = militaryTime => {
    let updatedMilitaryTime = militaryTime;
    if (militaryTime.length !== 4) {
      updatedMilitaryTime = `0${militaryTime}`;
    }
    const hours = parseInt(updatedMilitaryTime.slice(0, 2), 10);
    const minutes = parseInt(updatedMilitaryTime.slice(2, 4), 10);
    const currentDate = dayjs();
    const updatedDate = currentDate.hour(hours).minute(minutes).second(0);
    return updatedDate;
  };

  return (
    <Controller
      control={control}
      name={name}
      {...props}
      render={({ field = {}, fieldState = {} }) => {
        const { onChange, value, ref, ...fieldRest } = field;

        return (
          <FormControl className="Mui-datepicker" fullWidth={fullWidth}>
            <InputLabel
              {...(fieldState?.error?.message && {
                error: !!fieldState?.error?.message,
              })}>
              <FormattedMessage id={localeId} defaultMessage={label} />
            </InputLabel>
            {formMode === DYNAMIC_FORM_MODE.VIEW || disabled ? (
              <FormValue>
                {value
                  ? getCurrentDateWithRequiredTime(value).format('h:mma')
                  : value}
              </FormValue>
            ) : (
              <>
                <TimePickerMui
                  referenceDate={dayjs(new Date())}
                  // value={value}
                  value={
                    value === null || value === ''
                      ? null
                      : getCurrentDateWithRequiredTime(value)
                  }
                  onChange={dateValue => {
                    onChange(dateValue ? dayjs(dateValue).format('HHmm') : '');
                  }}
                  {...fieldRest}
                  {...rest}
                  {...effectProps}
                  {...(minTimeField && minTime
                    ? { minTime: dayjs(minTime) }
                    : {})}
                  {...(maxTimeField && maxTime
                    ? { maxTime: dayjs(maxTime) }
                    : {})}
                />
                <FormHelperText
                  {...(fieldState?.error?.message && {
                    error: !!fieldState?.error?.message,
                  })}>
                  {fieldState?.error?.message
                    ? fieldState?.error?.message
                    : helperText}
                </FormHelperText>
              </>
            )}
          </FormControl>
        );
      }}
    />
  );
});

export default observer(TimePicker);
