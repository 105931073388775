import { FormattedMessage, useIntl } from 'react-intl';
import {
  FormValue,
  PageSection,
  Section,
  SpecialSection,
  useStore,
} from '@servicexcelerator/ui-design-system';
import { Box, Chip, FormLabel } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import dayjs from 'dayjs';
import HistoryIcon from '@mui/icons-material/History';
import DispatchHistoryTable from './DispatchHistoryTable';
import getColumnsPAStatus from './columnsPAStatus';
import getColumnsRejectedStatus from './columnsRejectedStatus';
import getColumnsAcceptedStatus from './getColumnsAcceptedStatus';

function DispatchSection({ serviceJobData, dispatchData, AppModule }) {
  const { formatMessage } = useIntl();
  const { Utils } = AppModule;
  const { MasterDataUtil, toMediumDate } = Utils;
  const store = useStore();
  const masterData = store.domain.masterData.get() || {};

  const defaultMessage = formatMessage({
    id: 'NO_DATA_EXISTS',
    defaultMessage: 'No data exists',
  });

  const convertTo12HourTime = militaryTime =>
    militaryTime ? dayjs(militaryTime, 'HHmm').format('hh:mm A') : null;

  const getTime = () => {
    const startTime = convertTo12HourTime(
      dispatchData?.requestedAppointmentTime,
    );

    if (startTime) {
      return `after ${startTime}`;
    }
    return null;
  };

  const pendingAcceptanceDispatches =
    dispatchData?.serviceProviders?.filter(
      d => d.status === 'DISPATCH_PENDING_ACCEPTANCE',
    ) || [];

  const acceptedDispatches =
    dispatchData?.serviceProviders?.filter(
      d => d.status === 'DISPATCH_ACCEPTED',
    ) || [];

  const rejectedOrExpiredDispatches =
    dispatchData?.serviceProviders?.filter(d =>
      ['DISPATCH_REJECTED', 'DISPATCH_EXPIRED'].includes(d.status),
    ) || [];

  return (
    <Box>
      <Box>
        <SpecialSection color="primary.main">
          <Grid2 container>
            <Grid2 xs>
              <Grid2>
                <FormLabel>
                  <FormattedMessage
                    id="APPOINTMENT_WINDOW"
                    defaultMessage="Appointment Window"
                  />
                </FormLabel>
              </Grid2>
              <Grid2>
                <FormValue>
                  {toMediumDate(dispatchData?.requestedAppointmentDate)}{' '}
                  {getTime()}
                </FormValue>
              </Grid2>
            </Grid2>
            <Grid2 xs={2}>
              <Grid2>
                <FormLabel>
                  <FormattedMessage
                    id="SERVICE_TYPE"
                    defaultMessage="Service Type"
                  />
                </FormLabel>
              </Grid2>
              <Grid2>
                <FormValue>
                  {
                    MasterDataUtil.getServiceTypeById(
                      masterData,
                      serviceJobData?.serviceTypeId,
                    )?.label
                  }
                </FormValue>
              </Grid2>
            </Grid2>
            <Grid2 xs={6} textAlign="right">
              <Chip
                variant="outlined"
                sx={{ marginRight: '10px' }}
                color={acceptedDispatches.length > 0 ? 'success' : 'primary'}
                label={
                  acceptedDispatches.length > 0 ? 'Scheduled' : 'Scheduling'
                }
              />
            </Grid2>
          </Grid2>
        </SpecialSection>
      </Box>
      <Box mt={2}>
        <PageSection
          headerIcon={HistoryIcon}
          label={formatMessage({
            id: 'SCHEDULING_HISTORY',
            defaultMessage: 'Scheduling History',
          })}>
          <Grid2 container spacing={2}>
            <Grid2 xs={6}>
              {pendingAcceptanceDispatches.length > 0 && (
                <Section
                  label={formatMessage({
                    id: 'PENDING_ACCEPTANCE',
                    defaultMessage: 'Pending Acceptance',
                  })}>
                  <DispatchHistoryTable
                    data={pendingAcceptanceDispatches}
                    defaultMessage={defaultMessage}
                    AppModule={AppModule}
                    getColumns={getColumnsPAStatus}
                  />
                </Section>
              )}
              {acceptedDispatches.length > 0 && (
                <Section
                  label={formatMessage({
                    id: 'ACCEPTED',
                    defaultMessage: 'Accepted',
                  })}>
                  <DispatchHistoryTable
                    data={acceptedDispatches}
                    defaultMessage={defaultMessage}
                    AppModule={AppModule}
                    getColumns={getColumnsAcceptedStatus}
                  />
                </Section>
              )}
            </Grid2>
            <Grid2 xs={6}>
              <Section
                label={formatMessage({
                  id: 'REJECTED_OR_EXPIRED',
                  defaultMessage: 'Rejected or Expired',
                })}>
                <DispatchHistoryTable
                  data={rejectedOrExpiredDispatches}
                  defaultMessage={defaultMessage}
                  AppModule={AppModule}
                  getColumns={getColumnsRejectedStatus}
                />
              </Section>
            </Grid2>
          </Grid2>
        </PageSection>
      </Box>
      <Box>
        <Grid2>
          <FormLabel>
            {formatMessage({
              id: 'SERVICE_REQUESTED_DATE',
              defaultMessage: 'Service Requested Date',
            })}
          </FormLabel>
          <br />
          <FormValue>
            {toMediumDate(dispatchData?.serviceCreatedDate)}
          </FormValue>
        </Grid2>
        <Grid2>
          <FormLabel>
            {formatMessage({
              id: 'APPOINTMENT_NOTES',
              defaultMessage: 'Appointment Notes',
            })}
          </FormLabel>
          <br />
          <FormValue>{dispatchData?.serviceNotes}</FormValue>
        </Grid2>
      </Box>
    </Box>
  );
}

export default DispatchSection;
