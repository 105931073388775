import { useState } from 'react';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Box,
  Button,
  Checkbox,
  FormLabel,
  Typography,
  styled,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { FormValue } from '@servicexcelerator/ui-design-system';
import PreviousDateButton from './PreviousDateButton';
import NextDateButton from './NextDateButton';

const Circle = styled('div')(({ theme }) => ({
  borderRadius: '15px',
  height: '30px',
  width: '30px',
  border: 'none',
  background: theme.palette.primary.main,
  margin: '2px',
  padding: '2px',
  verticalAlign: 'middle',
  textAlign: 'center',
  color: '#ffffff',
}));

export default function Filter({ loading, job }) {
  const [selectedDate, setSelectedDate] = useState(dayjs());

  return (
    <Box>
      {!loading && (
        <Grid2 container spacing={2}>
          <Grid2>
            <Grid2>
              <FormLabel>
                <FormattedMessage
                  id="SERVICE_DATE"
                  description="Service Date"
                />
              </FormLabel>
            </Grid2>
            <Grid2 sx={{ marginTop: '10px' }}>
              <DatePicker
                sx={{ maxWidth: '190px' }}
                value={selectedDate}
                slotProps={{ textField: { size: 'small' } }}
              />
              <PreviousDateButton
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
              />
              <NextDateButton
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
              />
            </Grid2>
          </Grid2>
          <Grid2>
            <Grid2>
              <FormLabel>
                <FormattedMessage
                  id="SERVICE_PROVIDER"
                  description="Service Provider"
                />
              </FormLabel>
            </Grid2>
            <Grid2
              sx={{ marginTop: '10px', width: '300px', maxWidth: '300px' }}>
              {job?.serviceProviders.map((item, index) => (
                <Grid2 key={item.companyName} sx={{ marginBottom: '10px' }}>
                  <FormValue>
                    <Grid2 container spacing={1}>
                      <Grid2>
                        <Circle>{index + 1}</Circle>
                      </Grid2>
                      <Grid2 xs={9}>
                        {item.companyName}
                        <br />
                        <Typography
                          sx={{
                            fontWeight: 'normal',
                          }}>{`${item.firstName} ${item.lastName}`}</Typography>
                      </Grid2>
                      <Grid2 xs textAlign="right">
                        <Checkbox
                          name="serviceProvider"
                          value={item.companyName}
                        />
                      </Grid2>
                    </Grid2>
                  </FormValue>
                </Grid2>
              ))}
            </Grid2>
          </Grid2>
          <Grid2>
            <Button sx={{ marginTop: '35px' }} size="small" variant="contained">
              Submit
            </Button>
            <Button
              sx={{ marginTop: '35px', marginLeft: '10px' }}
              size="small"
              variant="text">
              Clear
            </Button>
          </Grid2>
        </Grid2>
      )}
    </Box>
  );
}
