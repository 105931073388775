import { IntlProvider } from 'react-intl';
import { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { CssBaseline, ThemeProvider } from '@mui/material';
import {
  DataProvider,
  LocalizationProvider,
  SnackbarProvider,
  lightTheme,
  useStore,
} from '@servicexcelerator/ui-design-system';
import { observer } from 'mobx-react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import GlobalErrorHandler from '@components/ErrorHandling/GlobalErrorHandler';
import * as ClaimModule from '@servicexcelerator/ui-claim-module';
import * as ServiceJobModule from '@servicexcelerator/ui-servicejob-module';
import * as RulesEditorModule from '@servicexcelerator/ui-ruleseditor-module';
import getDomain from '@utils/GetDomain';
import apiClient from '@components/DataProvider/axios';
import defaultDataProvider from './components/DataProvider/default';
import getRouter, { getRoutesFromModule } from './router/index';
import 'dayjs/locale/es';

async function loadLocaleData(locale = 'en') {
  let selectedLanguage = null;
  switch (locale) {
    case 'es':
      selectedLanguage = await import('@locales/es.json');
      break;
    default:
      selectedLanguage = await import('@locales/en.json');
      break;
  }
  return selectedLanguage;
}

/* These need to be fetched only once */
/* Define all modules here */
const routes = getRoutesFromModule([
  ClaimModule,
  ServiceJobModule,
  RulesEditorModule,
]);

const router = getRouter(routes);

function App() {
  const auth = useAuth();
  const store = useStore();
  const defaultLocale = 'en';

  const [messages, setMessages] = useState(null);

  const domain = getDomain(process.env.REACT_APP_API_HOST, 'api');
  const serviceJobDomain = getDomain(
    process.env.REACT_APP_SERVICE_JOB_API_HOST,
    'api',
  );

  const notificationDomain = getDomain(
    process.env.REACT_APP_NOTIFICATION_API_HOST,
    'api',
  );

  const axiosWrapper = apiClient(
    domain,
    auth?.user?.access_token,
    store.domain.locale,
  );

  const axiosWrapperServiceJob = apiClient(
    serviceJobDomain,
    auth?.user?.access_token,
    store.domain.locale,
  );

  const axiosWrapperNotification = apiClient(
    notificationDomain,
    auth?.user?.access_token,
    store.domain.locale,
  );

  const dataProviders = {
    default: defaultDataProvider(axiosWrapper),
    serviceJob: defaultDataProvider(axiosWrapperServiceJob),
    notification: defaultDataProvider(axiosWrapperNotification),
  };

  useEffect(() => {
    async function loadLocales(localeToLoad) {
      const localeMessages = await loadLocaleData(localeToLoad);
      if (localeMessages) {
        setMessages(localeMessages);
      }
    }

    loadLocales(store.domain.locale.get());
  }, [store.domain.locale.language]);

  const SnackbarProps = {
    anchorOrigin: { vertical: 'top', horizontal: 'center' },
    autoHideDuration: 5000,
  };

  return (
    <IntlProvider
      locale={store.domain.locale.get()}
      defaultLocale={defaultLocale}
      messages={messages}>
      <DataProvider dataProviders={dataProviders}>
        <ThemeProvider theme={lightTheme}>
          <GlobalErrorHandler>
            <CssBaseline />

            {messages && store.domain.locale.get() && (
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={store.domain.locale.get()}>
                <SnackbarProvider SnackbarProps={SnackbarProps}>
                  <RouterProvider router={router} />
                </SnackbarProvider>
              </LocalizationProvider>
            )}
          </GlobalErrorHandler>
        </ThemeProvider>
      </DataProvider>
    </IntlProvider>
  );
}

export default observer(App);
