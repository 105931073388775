import dayjs from 'dayjs';

const formattedDate = dated => dayjs(dated).format('MMM DD');

const isToday = dated => {
  const today = dayjs().format('YYYY-MM-DD');
  return today === dated;
};

function replaceAll(str, search, replacement) {
  return str.replace(new RegExp(search, 'g'), replacement);
}

function getFullWeekdayName(dated) {
  return dayjs(dated).format('dddd');
}

const getColumnId = ({ dated = '', weekday = '', time }) =>
  `column_${replaceAll(dated, '-', '')}_${weekday.toUpperCase()}_${time}`;

function padTime(militaryTime) {
  let paddedMilitaryTime = militaryTime.toString();
  if (militaryTime.toString().length === 3) {
    paddedMilitaryTime = `0${paddedMilitaryTime}`;
  }
  return paddedMilitaryTime;
}

function convertToAMPM(militaryTime) {
  const paddedMilitaryTime = padTime(militaryTime);
  const hour = paddedMilitaryTime.slice(0, 2);
  const minute = paddedMilitaryTime.slice(2);
  const period = parseInt(hour, 10) >= 12 ? 'PM' : 'AM';
  const hour12 = (parseInt(hour, 10) % 12 || 12).toString();
  const time12 = `${hour12}:${minute} ${period}`;
  return time12;
}

function convertTime(input) {
  const paddedTime = padTime(input);
  const hour = parseInt(paddedTime.substring(0, 2), 10);
  const minute = parseInt(paddedTime.substring(2, 4), 10);

  // Create a Day.js object with the parsed time
  const time = dayjs().hour(hour).minute(minute);

  // Format the time to the desired output
  return time.format(minute === 0 ? 'hA' : 'h:mmA');
}

const getNextDatesWithWeekdays = (startDateArg, count) => {
  const dates = [];
  let currentDate = dayjs(startDateArg);
  for (let i = 0; i < count; i += 1) {
    dates.push({
      date: currentDate.format('YYYY-MM-DD'),
      weekday: currentDate.format('dddd'),
    });
    currentDate = currentDate.add(1, 'day');
  }
  return dates;
};

export {
  formattedDate,
  getColumnId,
  replaceAll,
  getFullWeekdayName,
  convertToAMPM,
  convertTime,
  isToday,
  getNextDatesWithWeekdays,
};
