import {
  Breadcrumbs,
  LoggerUtil,
  PageSection,
  TableControls,
  useDataProvider,
  useStore,
} from '@servicexcelerator/ui-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { MaterialReactTable } from 'material-react-table';
import { useEffect, useMemo, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import getColumns from './lib/columns';
import CustomerSearch from '../../ui/CustomerSearch';

function getExistingParamsFromUrlOrStore(store) {
  const currentUrl = new URL(window.location.href);
  const page = currentUrl?.searchParams?.get('page') || null;
  const limit = currentUrl?.searchParams?.get('limit') || null;
  const filters = currentUrl?.searchParams?.get('filters') || null;
  const sort = currentUrl?.searchParams?.get('sorting') || null;
  if (page || limit || filters || sort) {
    currentUrl?.searchParams?.delete('page');
    currentUrl?.searchParams?.delete('limit');
    currentUrl?.searchParams?.delete('filters');
    currentUrl?.searchParams?.delete('sorting');
    return {
      page: page ? parseInt(page, 10) : 0,
      limit: limit ? parseInt(limit, 10) : 10,
      filters: filters ? JSON.parse(filters) : [],
      sort: sort ? JSON.parse(sort) : [],
    };
  }
  return store.uiState.customerSearchForm.get();
}

function columnFiltersArrayToObject(columnFilters) {
  const columnFiltersForService = columnFilters?.reduce(
    (acc, item) => ({
      ...acc,
      [item.id]: item.value,
    }),
    {},
  );
  return columnFiltersForService;
}

function SearchJobs({ AppModule }) {
  const { Components, Constants, Icons } = AppModule;
  const {
    BackButton,
    ServiceJob: ServiceJobSearchIcon,
    Customers,
    Customer,
  } = Icons;
  const { useUserAccess, Layout, Header, DashboardHeader } = Components;
  const { COMPANY_TYPE } = Constants;

  const { formatMessage } = useIntl();
  const store = useStore();
  const provider =
    process.env.NODE_ENV === 'development' ? 'serviceJob' : 'default';
  const { getMany, getApiUrl } = useDataProvider(provider);

  const userAccess = useUserAccess();

  const params = getExistingParamsFromUrlOrStore(store);

  const tableControlEnabled = false;

  // data and fetching state
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [showNewCustomerBlock, setShowNewCustomerBlock] = useState(false);

  // table state
  const [rowCount, setRowCount] = useState(params?.rowCount || 0);
  const [columnFilters, setColumnFilters] = useState(params?.filters || []);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState(params?.sorting || []);
  const [pagination, setPagination] = useState({
    pageIndex: params?.page || 0,
    pageSize: params?.limit || 10,
  });

  const [tableControl, setTableControl] = useState({
    resize: false,
    move: false,
    filter: false,
  });

  const createNewCustomer = () => {};

  const onCustomerSelect = customer => {
    store.uiState.createServiceJobModal.setOpen(true, customer);
  };

  const columns = useMemo(
    () => getColumns(formatMessage, AppModule, onCustomerSelect),
    [formatMessage],
  );

  async function fetchData() {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    const convertedFilters = {
      ...columnFiltersArrayToObject(columnFilters),
    };

    const apiUrl = new URL(`${getApiUrl()}/servicejob/v1/servicejob/customers`);

    apiUrl.searchParams.set('page', `${pagination.pageIndex + 1}`);
    apiUrl.searchParams.set('limit', `${pagination.pageSize}`);
    apiUrl.searchParams.set('filters', JSON.stringify(convertedFilters || {}));
    apiUrl.searchParams.set('sorting', JSON.stringify(sorting || []));

    try {
      const response = await getMany(apiUrl.href);
      if (response?.data) {
        const responseData = response?.data;
        setData(responseData.data);
        // store.uiState.serviceJobList.setPendingAcceptance(responseData);
        const tempRowCount =
          responseData?.pagination?.totalItems || response?.data.length;
        setRowCount(tempRowCount);
        store.uiState.customerSearchForm.set({
          page: pagination?.pageIndex,
          limit: pagination?.pageSize,
          filters: columnFilters,
          rowCount: tempRowCount,
          sorting,
        });
      }
    } catch (error) {
      setIsError(true);
      LoggerUtil.log('Service Job Search', error);
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  }

  const HeaderContent = tableControlEnabled ? (
    <TableControls
      localization={store.domain.locale.get()}
      onChange={(key, checked) => {
        setTableControl({
          ...tableControl,
          [key]: checked,
        });
      }}
      justifyContent="end"
    />
  ) : null;

  useEffect(() => {
    fetchData();
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  useEffect(() => {
    if (Array.isArray(columnFilters) && columnFilters.length > 0) {
      setShowNewCustomerBlock(false);
    } else {
      setShowNewCustomerBlock(true);
    }
  }, [columnFilters]);

  const breadCrumb = [
    {
      label: formatMessage({
        id: 'DASHBOARD',
        defaultMessage: 'Dashboard',
      }),
      href: '/dashboard',
    },
  ];

  breadCrumb.push({
    label: formatMessage({
      id: 'CREATE_NEW_SERVICEJOB',
      defaultMessage: 'Create new Job',
    }),
  });

  return (
    <Layout AppModule={AppModule}>
      <Header sx={{ mb: 2 }}>
        <DashboardHeader />
      </Header>

      <div>
        <Grid2 container pt={0} pb={0} alignItems="center">
          <Grid2 xs={6}>
            <Breadcrumbs links={breadCrumb} />
          </Grid2>
          <Grid2 textAlign="right" xs={6}>
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                window.history.back();
              }}
              startIcon={<BackButton />}>
              <FormattedMessage id="BACK_BUTTON" defaultMessage="Back" />
            </Button>
          </Grid2>
        </Grid2>
      </div>
      <Grid2 mt={3}>
        <Grid2 mb={2}>
          <PageSection
            label={formatMessage({
              id: 'CREATE_NEW_SERVICEJOB',
              defaultMessage: 'Create new Job',
            })}
            headerIcon={ServiceJobSearchIcon}>
            <Grid2 container direction="column" spacing={2}>
              <CustomerSearch
                AppModule={AppModule}
                onColumnFiltersChanged={newColumnFilters => {
                  setColumnFilters(newColumnFilters);
                  setGlobalFilter(null);
                }}
                columnFilters={columnFilters}
                formValues={columnFilters}
              />
              {showNewCustomerBlock ? (
                <Grid2 mb={2} textAlign="center">
                  <Typography mt={2} mb={2}>
                    &ndash;OR&ndash;
                  </Typography>
                  <Button
                    variant="outlined"
                    onClick={() => createNewCustomer()}>
                    <FormattedMessage
                      id="CREATE_NEW_CUSTOMER"
                      description="Create new Customer"
                    />
                  </Button>
                </Grid2>
              ) : (
                <PageSection
                  subSection
                  HeaderContent={HeaderContent}
                  headerIcon={Customers}
                  label={formatMessage({
                    id: 'CUSTOMERS',
                    defaultMessage: 'Customers',
                  })}>
                  <MaterialReactTable
                    localization={store.domain.locale.getForMuiTable()}
                    columns={columns}
                    data={data}
                    getRowId={row => row.customerId}
                    rowCount={rowCount}
                    manualFiltering
                    manualPagination
                    manualSorting={false}
                    onColumnFiltersChange={setColumnFilters}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    enableColumnFilters={false}
                    enableSorting={false}
                    enableFilters={false}
                    initialState={{
                      showColumnFilters: false,
                      columnVisibility: {
                        serviceAdministratorName:
                          userAccess.companyType ===
                          COMPANY_TYPE.SERVICE_PROVIDER,
                        serviceProviderName:
                          userAccess.companyType ===
                          COMPANY_TYPE.SERVICE_ADMINISTRATOR,
                        serviceProviderNumber:
                          userAccess.companyType ===
                          COMPANY_TYPE.SERVICE_ADMINISTRATOR,
                      },
                    }}
                    state={{
                      columnFilters,
                      globalFilter,
                      isLoading,
                      showAlertBanner: isError,
                      showProgressBars: isRefetching,
                      sorting,
                      pagination,
                    }}
                    muiToolbarAlertBannerProps={
                      isError
                        ? {
                            color: 'error',
                            children: formatMessage({
                              id: 'ERROR_LOADING_DATA',
                              defaultMessage: 'Error loading data',
                            }),
                          }
                        : undefined
                    }
                    muiTablePaperProps={{ elevation: 0 }}
                    renderTopToolbarCustomActions={() => (
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '1rem',
                          mr: '0.5rem',
                          flex: 1,
                          justifyContent: 'end',
                          flexWrap: 'wrap',
                          alignContent: 'end',
                        }}>
                        <Button
                          color="primary"
                          onClick={() => createNewCustomer()}
                          startIcon={<Customer />}
                          variant="outlined">
                          <FormattedMessage
                            id="CREATE_NEW_CUSTOMER"
                            defaultMessage="Create new Customer"
                          />
                        </Button>
                      </Box>
                    )}
                  />
                </PageSection>
              )}
            </Grid2>
          </PageSection>
        </Grid2>
      </Grid2>
    </Layout>
  );
}

export default SearchJobs;
