import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {
  AsyncSelectFormControl,
  useDataProvider,
} from '@servicexcelerator/ui-design-system';

function Brands({ ...props }) {
  const provider =
    process.env.NODE_ENV === 'development' ? 'serviceJob' : 'default';
  const { getMany, getOne } = useDataProvider(provider);

  const getBrandsByBrandName = async (inputValue = '') => {
    const result = await getMany(
      `/servicejob/v1/servicejob/brand?brandname=${inputValue}`,
    );
    return result?.data || [];
  };

  const getBrandByBrandCode = async (inputValue = '') => {
    const result = await getOne(
      `/servicejob/v1/servicejob/brandbycode?brandcode=${inputValue}`,
    );
    return result.data ? result.data : [];
  };

  return (
    <Grid2 xs>
      <AsyncSelectFormControl
        optionLabelKey="brandName"
        optionValueKey="brandCode"
        fullWidth
        fetchOptions={input => getBrandsByBrandName(input)}
        fetchOptionsById={input => getBrandByBrandCode(input)}
        {...props}
      />
    </Grid2>
  );
}

export default Brands;
