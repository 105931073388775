import {
  Box,
  styled,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SpecialSection from '../../core/SpecialSection';
import Seperator from '../../core/Seperator';
import SectionTitle from '../SectionTitle';
import applyEffects from '../../core/Forms/DynamicForm/lib/effects';

const StyledAccordion = styled(Accordion, {
  shouldForwardProp: props => !['selected'].includes(props),
})(({ theme, selected }) => ({
  borderRadius: '20px !important',
  borderColor: selected
    ? theme.palette.primary.main
    : theme.palette.secondary.light,
  borderStyle: 'solid',
  borderWidth: '1px',
  '& .MuiAccordionSummary-expandIconWrapper': {
    boxShadow: theme.shadows[1],
    borderRadius: '8px',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
    svg: {
      transform: 'rotate(180deg)',
    },
  },
  '& .MuiAccordionSummary-content, > .MuiButtonBase-root': {
    cursor: 'default !important',
  },
}));

export function CardHeaderTitleWithIcon({
  label,
  localeId = null,
  headerIcon,
  subSection,
}) {
  return (
    <Box>
      <SectionTitle subSection={subSection} Icon={headerIcon}>
        {localeId ? (
          <div>
            <FormattedMessage id={localeId} defaultMessage={label} />
          </div>
        ) : (
          label
        )}
      </SectionTitle>
    </Box>
  );
}

function PageSection({
  label = null,
  localeId = null,
  headerIcon = null,
  children,
  selected = false,
  defaultExpanded = true,
  onChange = () => undefined,
  id = null,
  HeaderContent = null,
  subSection = false,
  wrapInComponent = null,
  effects = null,
  effectFunctions = null,
  ...props
}) {
  const [expanded, setExpanded] = useState(defaultExpanded);

  const changeExpanded = value => {
    setExpanded(value);
  };

  if (!window?.sxclPageSections) {
    window.sxclPageSections = new Map();
  }

  // Hacky way but worky way
  window.sxclPageSections.set(id, changeExpanded);

  const effectProps = effectFunctions
    ? applyEffects(effects, effectFunctions)
    : null;

  if (effectProps?.hide) {
    return null;
  }

  return (
    <Grid2 xs={12}>
      <StyledAccordion
        {...(id && { id })}
        elevation={0}
        selected={selected}
        onChange={onChange}
        expanded={expanded}
        {...props}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setExpanded(!expanded);
              }}
            />
          }>
          <Grid2 container xs={12}>
            <Grid2 xs={6}>
              <CardHeaderTitleWithIcon
                label={label}
                localeId={localeId}
                headerIcon={headerIcon}
                subSection={subSection}
              />
            </Grid2>
            <Grid2 sx={{ pr: 2 }} alignSelf="center" xs={6}>
              {HeaderContent}
            </Grid2>
          </Grid2>
        </AccordionSummary>
        <Seperator />
        <AccordionDetails sx={{ mt: 2 }}>
          {wrapInComponent && wrapInComponent === 'SpecialSection' ? (
            <SpecialSection>{children}</SpecialSection>
          ) : (
            children
          )}
        </AccordionDetails>
      </StyledAccordion>
    </Grid2>
  );
}

export function Section({
  label = null,
  localeId = null,
  headerIcon = null,
  children,
  headerContent = null,
}) {
  return (
    <Card sx={{ width: '100%' }}>
      <Box sx={{ padding: 2 }}>
        <Grid2 container xs={12}>
          <Grid2 xs={6}>
            <CardHeaderTitleWithIcon
              localeId={localeId}
              label={label}
              headerIcon={headerIcon}
            />
          </Grid2>
          {headerContent && (
            <Grid2 sx={{ pr: 2 }} alignSelf="center" xs={6}>
              {headerContent}
            </Grid2>
          )}
        </Grid2>
      </Box>
      <Seperator />
      <Box sx={{ p: 2 }}>{children}</Box>
    </Card>
  );
}

export default PageSection;
