import describeArc from '../../lib/describeArc';

function ServiceIcon({ startAngle = 0, endAngle = 90 }) {
  const radius = 8;
  const strokeWidth = 4;
  const viewBoxSize = radius * 2 + strokeWidth * 2;

  const d = describeArc(
    viewBoxSize / 2,
    viewBoxSize / 2,
    radius,
    startAngle,
    endAngle,
  );

  return (
    <svg
      width={viewBoxSize}
      height={viewBoxSize}
      viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}>
      <circle
        cx={viewBoxSize / 2}
        cy={viewBoxSize / 2}
        r={radius}
        fill="transparent"
        stroke="#EAEBEC"
        strokeWidth={strokeWidth}
      />
      <path d={d} fill="none" stroke="#009ACD" strokeWidth={strokeWidth} />
    </svg>
  );
}

export default ServiceIcon;
