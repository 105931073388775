import { Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import FormLabel from './FormLabel';

function InfoBlock({ label, value }) {
  return (
    <Grid2 container spacing={1}>
      <Grid2 xs={8}>
        <FormLabel>{label}</FormLabel>
      </Grid2>
      <Grid2 xs={4}>
        <Typography
          sx={{ fontSize: '14px', fontWeight: 500, textAlign: 'right' }}
          color={value ? 'primary.main' : 'secondary.dark'}>
          {value ? 'Yes' : 'No'}
        </Typography>
      </Grid2>
    </Grid2>
  );
}

export default InfoBlock;
