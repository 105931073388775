import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';
import { Box, Select, InputLabel, MenuItem, FormControl } from '@mui/material';

import {
  TextField,
  StackedFloatingButtons,
} from '@servicexcelerator/ui-design-system';
import RuleGroup from '../RuleGroup/index';
import {
  emptyRule,
  emptyRuleDecision,
  emptyRuleOutcome,
  getRuleOutcomeLabels,
} from '../../common/util';
import { RULE_OUTCOME } from '../../common/constants';
import getPageButtons from './lib/getPageButtons';

function RuleContainer(props) {
  const {
    rule = emptyRule(),
    errors = {},
    combinators,
    onRuleEdit,
    onRuleSave,
    onCancel,
    onNewRule,
    level,
    addCondition,
    deleteCondition,
    onFactFieldChange,
    addGroup,
    deleteGroup,
    onOperatorFieldChange,
    onCombinatorChange,
    onValueFieldChange,
    onValueSourceChange,
    onValueFactFieldChange,
    factFieldMap = {},
    factFields = [],
    operatorsByTypeMap = {},
    operatorsByValueMap = {},
  } = props;
  const {
    ruleId = null,
    ruleName = null,
    decision = emptyRuleDecision(),
    outcome = emptyRuleOutcome(),
  } = { ...rule };
  const { formatMessage } = useIntl();
  const { combinator, conditions, decisionId } = { ...decision };
  const labelOptions = getRuleOutcomeLabels(outcome.type);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid2 container spacing={1}>
        <Grid2 xs={8}>
          <FormControl fullWidth>
            <InputLabel id="rulename-textfield-label">Rule Name</InputLabel>
            <TextField
              required
              error={!!errors?.ruleName}
              helperText={errors?.ruleName}
              size="small"
              fullWidth
              id="ruleName-textfield"
              value={ruleName}
              onChange={e => {
                onRuleEdit({ ...rule, ruleName: e.target.value });
              }}
              disabled={false}
            />
          </FormControl>
        </Grid2>
      </Grid2>
      <Grid2 sx={{ flexGrow: 1, p: 1 }}>
        <RuleGroup
          key={decisionId}
          combinator={combinator}
          conditions={conditions}
          level={level}
          errors={errors}
          addCondition={addCondition}
          deleteCondition={deleteCondition}
          combinators={combinators}
          onFactFieldChange={onFactFieldChange}
          addGroup={addGroup}
          deleteGroup={deleteGroup}
          onOperatorFieldChange={onOperatorFieldChange}
          decisionId={decisionId}
          onCombinatorChange={onCombinatorChange}
          onValueFieldChange={onValueFieldChange}
          onValueSourceChange={onValueSourceChange}
          onValueFactFieldChange={onValueFactFieldChange}
          factFieldMap={factFieldMap}
          factFields={factFields}
          operatorsByTypeMap={operatorsByTypeMap}
          operatorsByValueMap={operatorsByValueMap}
        />
      </Grid2>
      <Grid2
        container
        spacing={1}
        sx={{
          flexGrow: 1,
          p: 1,
          border: 'dotted thin',
          mt: 2,
          borderRadius: 2,
        }}>
        <Grid2 xs={3}>
          <FormControl fullWidth>
            <InputLabel id="ruleoutcome-select-label">
              Default Rule Outcome
            </InputLabel>
            <Select
              size="small"
              labelId="ruleoutcome-select-label"
              id="ruleoutcome-select"
              value={outcome.type}
              label="Default Rule Outcome"
              onChange={e => {
                const newRule = { ...rule };
                newRule.outcome.type = e.target.value;
                onRuleEdit({ ...newRule });
              }}>
              {RULE_OUTCOME.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid2>
        <Grid2 xs={6}>
          <FormControl fullWidth>
            <InputLabel id="ruleoutcomecode-textfield-label">
              {labelOptions.codeLabel}
            </InputLabel>
            <TextField
              size="small"
              fullWidth
              id="ruleoutcomecode-textfield"
              value={outcome.code}
              error={!!errors?.outcomeCode}
              helperText={errors?.outcomeCode}
              onChange={e => {
                const newRule = { ...rule };
                newRule.outcome.code = e.target.value;
                onRuleEdit({ ...newRule });
              }}
              disabled={false}
            />
          </FormControl>
        </Grid2>
        <Grid2 xs={8}>
          <FormControl fullWidth>
            <InputLabel id="ruleoutcometext-textfield-label">
              {labelOptions.textLabel}
            </InputLabel>
            <TextField
              size="small"
              fullWidth
              id="ruleoutcometext-textfield"
              value={outcome.text}
              error={!!errors?.outcomeText}
              helperText={errors?.outcomeText}
              onChange={e => {
                const newRule = { ...rule };
                newRule.outcome.text = e.target.value;
                onRuleEdit({ ...newRule });
              }}
              disabled={false}
            />
          </FormControl>
        </Grid2>
      </Grid2>
      <Grid2>
        <StackedFloatingButtons
          position={{ bottom: true, right: true }}
          buttons={getPageButtons({
            formatMessage,
            onRuleSave,
            onNewRule,
            onCancel: () => onCancel(ruleId),
          })}
        />
      </Grid2>
    </Box>
  );
}

export default observer(RuleContainer);
