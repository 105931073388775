import { makeAutoObservable } from 'mobx';
import { clearPersistedStore, makePersistable } from 'mobx-persist-store';

class MapStore {
  jobs = [];

  constructor(rootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);

    makePersistable(this, {
      name: 'MapStore',
      properties: ['jobs'],
    });
  }

  setJob(jobId, value) {
    this.jobs[jobId] = value;
  }

  deleteJob(jobId) {
    delete this.jobs[jobId];
  }

  getJob(jobId) {
    return this.jobs[jobId] || null;
  }

  async clearStore() {
    await clearPersistedStore(this);
  }
}

export default MapStore;
