import AppDataProvider from './AppDataProvider';
import AuthWrapper from './AuthWrapper';
import Brands from './Brands';
import ClaimsDoughnut from './Charts/Widgets/ClaimsDoughnut';
import Cities from './Cities';

import ClaimStatusIcon from './ClaimStatusIcon';
import ServiceJobStatusIcon from './ServiceJobStatusIcon';
import apiClient from './DataProvider/axios';
import dataProvider from './DataProvider/default';
import GlobalFallbackComponent from './ErrorHandling/GlobalErrorHandler/GlobalFallbackComponent';
import GlobalErrorHandler from './ErrorHandling/GlobalErrorHandler/index';
import Header from './Header';
import Layout from './Layout';
import LinkTextControl from './LinkText';
import LazyLoadRoute from './Routing/LazyLoadRoute';
import ProtectedRoute from './Routing/ProtectedRoute';
import RouteLoading from './Routing/RouteLoading';
import RouteRBACCheck from './Routing/RouteRBACCheck';
import States from './States';
import { useUserAccess, hasPermission } from './UserAccessProvider';
import DashboardHeader from '../pages/Dashboard/Header/DashboardHeader';

export default {
  DashboardHeader,
  useUserAccess,
  hasPermission,
  States,
  RouteRBACCheck,
  LazyLoadRoute,
  ProtectedRoute,
  RouteLoading,
  AppDataProvider,
  AuthWrapper,
  Brands,
  ClaimsDoughnut,
  Cities,
  ClaimStatusIcon,
  ServiceJobStatusIcon,
  apiClient,
  dataProvider,
  GlobalFallbackComponent,
  GlobalErrorHandler,
  Header,
  Layout,
  LinkTextControl,
};
