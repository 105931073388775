import { useIntl } from 'react-intl';
import { useMemo } from 'react';
import { MaterialReactTable } from 'material-react-table';
import getColumns from './lib/columns';

function RuleClaimForm({ store, data }) {
  const { formatMessage } = useIntl();
  const columns = useMemo(() => getColumns(formatMessage), [formatMessage]);
  return (
    <MaterialReactTable
      localization={store.domain.locale.getForMuiTable()}
      columns={columns}
      data={data}
      enablePagination={false}
      enableGlobalFilter={false}
      enableFilters={false}
      enableTopToolbar={false}
      muiTablePaperProps={{ elevation: 0 }}
      state={{
        density: 'compact',
      }}
    />
  );
}

export default RuleClaimForm;
