import { Box } from '@mui/material';

import { Modal, useStore } from '@servicexcelerator/ui-design-system';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { useSearchParams } from 'react-router-dom';
import CreateFormAutoRedirect from './CreateFormAutoRedirect';
import CreateForm from './CreateForm';

function CreateServiceJobModal({ AppModule }) {
  const { Icons } = AppModule;
  const AUTO_REDIRECT = true;
  const { ServiceJob } = Icons;
  const store = useStore();
  const { formatMessage } = useIntl();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const jobcreatesrc = searchParams.get('createjobmodal');
    if (jobcreatesrc === 'true') {
      store.uiState.createServiceJobModal.setOpen(true);
    }
  }, [searchParams]);

  return (
    <Modal
      PaperProps={{ sx: { width: '650px' } }}
      handleClose={() => store.uiState.createServiceJobModal.setOpen(false)}
      maxWidth="lg"
      title={formatMessage({
        id: 'CREATE_NEW_SERVICE_JOB',
        defaultMessage: 'Create new job',
      })}
      open={store.uiState.createServiceJobModal.open}
      Icon={ServiceJob}>
      <Box>
        {AUTO_REDIRECT ? (
          <CreateFormAutoRedirect AppModule={AppModule} />
        ) : (
          <CreateForm AppModule={AppModule} />
        )}
      </Box>
    </Modal>
  );
}

export default observer(CreateServiceJobModal);
