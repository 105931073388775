import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {
  LoadingEvent,
  useDataProvider,
} from '@servicexcelerator/ui-design-system';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ServiceHistoryTable from './ServiceHistoryTable';

function ServiceJobServiceHistory({
  claimNumber = null,
  modelNumber = null,
  serialNumber = null,
  serviceAdministratorContractNumber = null,
  AppModule,
}) {
  const { Components, Constants } = AppModule;
  const { useUserAccess } = Components;
  const { COMPANY_TYPE } = Constants;

  const { formatMessage } = useIntl();
  const defaultMessage = formatMessage({
    id: 'NO_DATA_EXISTS',
    defaultMessage: 'No data exists',
  });
  const userAccess = useUserAccess();

  const { getMany } = useDataProvider();
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);

  const isSA = userAccess.companyType === COMPANY_TYPE.SERVICE_ADMINISTRATOR;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await getMany(
        `/claims/v1/claim/${claimNumber}/audit/servicehistory?modelnumber=${modelNumber}&serialnumber=${serialNumber}&serviceadministratorcontractnumber=${serviceAdministratorContractNumber}`,
      );

      if (result) {
        setTableData(result?.data || []);
      }
      setLoading(false);
    };

    if (claimNumber && isSA) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [claimNumber]);

  if (!isSA) {
    return null;
  }

  return (
    <Grid2>
      {loading ? (
        <LoadingEvent>
          <FormattedMessage id="FETCHING_DATA" defaultMessage="Fetching Data" />
        </LoadingEvent>
      ) : (
        <ServiceHistoryTable
          data={tableData}
          defaultMessage={defaultMessage}
          AppModule={AppModule}
        />
      )}
    </Grid2>
  );
}

export default ServiceJobServiceHistory;
