import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';

const getPageButtons = ({
  formatMessage = () => undefined,
  onRuleSave = () => undefined,
  onNewRule = () => undefined,
  onCancel = () => undefined,
}) => {
  const BUTTONS = {
    NEW: {
      async: true,
      Icon: AddIcon,
      children: formatMessage({
        id: 'NEW_BUTTON',
        defaultMessage: 'New',
      }),
      processingLabel: formatMessage({
        id: 'PROCESSING',
        defaultMessage: 'Processing...',
      }),
      onClick: () => onNewRule(),
    },
    SAVE: {
      async: true,
      Icon: SaveIcon,
      children: formatMessage({
        id: 'SAVE_BUTTON',
        defaultMessage: 'Save',
      }),
      processingLabel: formatMessage({
        id: 'SAVING',
        defaultMessage: 'Saving...',
      }),
      onClick: () => onRuleSave(),
    },
    CANCEL: {
      async: true,
      Icon: CancelIcon,
      children: formatMessage({
        id: 'CANCEL_BUTTON',
        defaultMessage: 'Cancel',
      }),
      processingLabel: formatMessage({
        id: 'CANCELING',
        defaultMessage: 'Canceling...',
      }),
      onClick: () => onCancel(),
    },
  };

  return [BUTTONS.NEW, BUTTONS.SAVE, BUTTONS.CANCEL];
};

export default getPageButtons;
