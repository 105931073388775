import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { RULE_OUTCOME } from '../../../common/constants';

function RuleLink(props) {
  const {
    row: {
      original: { ruleId, ruleName },
    },
  } = props;
  return <Link to={`/rules/edit/${ruleId}`}>{ruleName}</Link>;
}

function RuleOutcome(props) {
  const {
    row: {
      original: { outcome },
    },
  } = props;
  const ruleOutcome = RULE_OUTCOME.find(item => item.value === outcome.type);
  return (
    <FormattedMessage
      id={ruleOutcome.label}
      defaultMessage={ruleOutcome.label}
    />
  );
}

const getColumns = formatMessage => [
  {
    accessorKey: 'ruleName',
    header: formatMessage({
      id: 'RULE',
      defaultMessage: 'Rule',
    }),
    minSize: 320,
    enableColumnActions: false,
    Cell: props => RuleLink(props),
  },
  {
    accessorKey: 'outcome',
    header: formatMessage({
      id: 'RULE_OUTCOME',
      defaultMessage: 'Rule Outcome',
    }),
    enableColumnActions: false,
    Cell: props => RuleOutcome(props),
  },
  {
    header: formatMessage({
      id: 'ACTIVE_CLAIM_FORMS',
      defaultMessage: 'Active Claim Forms',
    }),
    enableColumnActions: false,
    Cell: () => Math.floor(Math.random() * 5),
  },
];

export default getColumns;
