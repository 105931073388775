import { Button } from '@mui/material';
import { ReadMore } from '@servicexcelerator/ui-design-system';
import { FormattedMessage } from 'react-intl';

function Customer(job) {
  const name = [];
  if (job?.customer?.customerNameFirst) {
    name.push(job?.customer?.customerNameFirst);
  }
  if (job?.customer?.customerNameLast) {
    name.push(job?.customer?.customerNameLast);
  }

  const fullName = name.join(' ');

  const address = [];

  if (job?.customer) {
    const { customerAddress = null } = job.customer;

    if (customerAddress?.addressCity) {
      address.push(customerAddress?.addressCity);
    }
    if (customerAddress?.addressState) {
      address.push(customerAddress?.addressState);
    }
    if (customerAddress?.addressPostalCode) {
      address.push(customerAddress?.addressPostalCode);
    }
  }

  const fullAddress = address?.join(', ');

  const final = [];
  final.push(fullName);
  if (job?.customerAddress) {
    final.push(job?.customerAddress);
  }
  final.push(fullAddress);

  const sendMessage = (
    <Button sx={{ textTransform: 'none' }} variant="text">
      <FormattedMessage id="SEND_MESSAGE" description="Send Message" />
    </Button>
  );

  return [...final, sendMessage].map((item, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <div key={`${index}jd`}>{item}</div>
  ));
}

function BrandProductType(job) {
  const data = [];
  if (job?.brand?.brandCode) {
    data.push(job?.brand?.brandCode);
  }
  if (job?.productType) {
    data.push(job?.productType);
  }
  return data.join(' ');
}

function Problem(job, formatMessage) {
  return (
    <ReadMore
      showReadLess
      readMoreText={formatMessage({
        id: 'READ_MORE',
        defaultMessage: 'read more',
      })}
      showLessText={formatMessage({
        id: 'SHOW_LESS',
        defaultMessage: 'show less',
      })}
      charsToShow={30}>
      {job?.customerComplaint}
    </ReadMore>
  );
}

export { Customer, BrandProductType, Problem };
