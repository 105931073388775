import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { IconButton } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import FactField from '../FactField';
import OperatorField from '../OperatorField';
import ValueField from '../ValueField';
import ValueSource from '../ValueSource';

function Condition(props) {
  const {
    decisionId,
    condition,
    deleteCondition,
    onFactFieldChange,
    onOperatorFieldChange,
    onValueFieldChange,
    conditionErrors = {},
    onValueSourceChange,
    onValueFactFieldChange,
    factFieldMap = {},
    factFields = [],
    operatorsByTypeMap = {},
    operatorsByValueMap = {},
  } = props;
  const { conditionId, field, operator, valueSource, value } = condition;
  const { fieldError, operatorError, valueError } = {
    ...conditionErrors[conditionId],
  };
  const canShowValueFields = () => {
    if (
      operator &&
      operatorsByValueMap &&
      operatorsByValueMap[operator]?.valueField === false
    ) {
      return false;
    }
    return true;
  };

  return (
    <Grid2 container spacing={1} sx={{ pl: 1, mb: 1 }} display="flex">
      <Grid2>
        <IconButton
          size="small"
          onClick={() => deleteCondition(decisionId, conditionId)}>
          <RemoveCircleOutlineIcon color="error" />
        </IconButton>
      </Grid2>
      <Grid2 xs="4">
        <FactField
          field={field}
          conditionId={conditionId}
          onChange={onFactFieldChange}
          fieldError={fieldError}
          factFieldMap={factFieldMap}
          factFields={factFields}
        />
      </Grid2>
      {field && (
        <Grid2 xs="auto">
          <OperatorField
            field={field}
            conditionId={conditionId}
            operator={operator}
            onOperatorFieldChange={onOperatorFieldChange}
            operatorError={operatorError}
            factFieldMap={factFieldMap}
            operatorsByTypeMap={operatorsByTypeMap}
            operatorsByValueMap={operatorsByValueMap}
          />
        </Grid2>
      )}
      {operator && canShowValueFields() && (
        <Grid2 xs="auto">
          <ValueSource
            conditionId={conditionId}
            operator={operator}
            onValueSourceChange={onValueSourceChange}
            valueSource={valueSource}
          />
        </Grid2>
      )}
      {operator &&
        canShowValueFields() &&
        (valueSource === 'VALUE' ? (
          <Grid2 xs="auto">
            <ValueField
              field={field}
              conditionId={conditionId}
              fieldValue={value}
              onValueFieldChange={onValueFieldChange}
              valueError={valueError}
              factFieldMap={factFieldMap}
            />
          </Grid2>
        ) : (
          <Grid2 xs="4">
            <FactField
              field={value?.field}
              conditionId={conditionId}
              onChange={onValueFactFieldChange}
              factFieldMap={factFieldMap}
              factFields={factFields}
            />
          </Grid2>
        ))}
    </Grid2>
  );
}

export default Condition;
