import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

function HeaderTitle({ jobDetail, loading }) {
  if (loading) {
    return null;
  }
  return (
    <Typography
      variant="h5"
      sx={{
        fontWeight: 500,
        fontSize: '18px',
        position: 'absolute',
        left: '38px',
        top: '7px',
      }}>
      <FormattedMessage
        id="SERVICEJOB_DETAILS_NUMBER"
        description="Job: {number}"
        values={{ number: jobDetail.serviceJobNumber }}
      />
    </Typography>
  );
}

export default HeaderTitle;
