import { Box, Button, Typography } from '@mui/material';

import {
  DYNAMIC_FORM_MODE,
  DynamicForm,
  LoadingEvent,
  Modal,
  useDataProvider,
  useStore,
} from '@servicexcelerator/ui-design-system';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import jsonSchema from './jsonSchema.json';
import uiSchema from './uiSchema.json';

function RequestAlternateTimeModal({ AppModule, serviceJobNumber }) {
  const { Icons } = AppModule;

  const { ServiceJob } = Icons;
  const store = useStore();
  const { formatMessage } = useIntl();
  const [searchParams] = useSearchParams();
  const provider =
    process.env.NODE_ENV === 'development' ? 'serviceJob' : 'default';
  const { updateOne } = useDataProvider(provider);
  const formRef = useRef();

  const navigate = useNavigate();
  const masterData = store.domain.masterData.get();
  const [showMessage, setShowMessage] = useState(false);

  const buttons = [
    {
      color: 'primary',
      variant: 'contained',
      children: formatMessage({
        id: 'SCHEDULE_BUTTON',
        defaultMessage: 'Schedule',
      }),
      type: 'button',
      onClick: () => formRef.current.submitForm(),
    },
    {
      color: 'secondary',
      variant: 'contained',
      children: formatMessage({
        id: 'CANCEL_BUTTON',
        defaultMessage: 'Cancel',
      }),
      type: 'button',
      onClick: () => store.uiState.requestAlternateTimeModal.setOpen(false),
    },
    {
      color: 'primary',
      variant: 'contained',
      children: formatMessage({
        id: 'CLOSE_BUTTON',
        defaultMessage: 'Close',
      }),
      type: 'button',
      onClick: () => {
        navigate(`/servicejob/edit/${serviceJobNumber}`, { replace: true });
        store.uiState.requestAlternateTimeModal.setOpen(false);
      },
    },
  ];

  const onSubmitSuccess = async data => {
    const response = await updateOne(
      `/servicejob/v1/servicejob/${serviceJobNumber}/operation/scheduleappointment`,
      data,
    );
    if (response.data) {
      setShowMessage(true);
    }
    // TODO: Add a else case
  };

  const onSubmitError = (data, additional) => {
    // eslint-disable-next-line no-console
    console.log({ data, additional });
  };

  useEffect(() => {
    const jobcreatesrc = searchParams.get('createjobmodal');
    if (jobcreatesrc === 'true') {
      store.uiState.requestAlternateTimeModal.setOpen(true);
    }
  }, [searchParams]);

  return (
    <Modal
      PaperProps={{ sx: { width: '650px' } }}
      handleClose={() => store.uiState.requestAlternateTimeModal.setOpen(false)}
      maxWidth="lg"
      title={formatMessage({
        id: showMessage ? 'JOB_REQUEST_DISPATCHED' : 'REQUEST_ALTERNATE_TIME',
        defaultMessage: showMessage
          ? 'Job Request Dispatched'
          : 'Request Alternate Time',
      })}
      open={store.uiState.requestAlternateTimeModal.open}
      Icon={ServiceJob}>
      {!showMessage ? (
        <Box>
          <DynamicForm
            formId="alternateTimeCreate"
            uiSchema={uiSchema}
            jsonSchema={jsonSchema}
            onSubmitSuccess={onSubmitSuccess}
            onSubmitError={onSubmitError}
            reference={formRef}
            masterData={masterData}
            initialized
            loadingComponent={
              <LoadingEvent>
                <FormattedMessage
                  id="LOADING_SCHEMA"
                  defaultMessage="Loading Schema"
                />
              </LoadingEvent>
            }
            // initialFormValues={{ requestedAppointmentDate: new Date() }}
            formMode={DYNAMIC_FORM_MODE.EDIT}
            formatMessage={formatMessage}
          />
          <Grid2 container spacing={2} mt={6} justifyContent="center">
            <Grid2 textAlign="right" xs={6}>
              <Button {...buttons[0]} />
            </Grid2>
            <Grid2 textAlign="left" xs={6}>
              <Button {...buttons[1]} />
            </Grid2>
          </Grid2>
        </Box>
      ) : (
        <Box>
          <Grid2>
            <Typography>
              Three providers have been notified about the service. We will
              contact you in less than two hours with detailed information on
              your service
            </Typography>
          </Grid2>
          <Grid2 mt={2} textAlign="center">
            <Button {...buttons[2]} />
          </Grid2>
        </Box>
      )}
    </Modal>
  );
}

export default observer(RequestAlternateTimeModal);
