function LongDateDisplay(props, fieldName, AppModule) {
  const {
    row: { original },
  } = props;
  const { Utils } = AppModule;
  const { toLongDate } = Utils;

  return original[fieldName] ? toLongDate(original[fieldName]) : '';
}

const getColumns = (formatMessage, AppModule) => [
  {
    accessorKey: 'companyName',
    header: formatMessage({
      id: 'SERVICE_PROVIDER_NAME',
      defaultMessage: 'Service Provider Name',
    }),
  },
  {
    accessorKey: 'notificationDateTime',
    header: formatMessage({
      id: 'NOTIFICATION_DATE_TIME',
      defaultMessage: 'Notification Date & Time',
    }),
    Cell: props => LongDateDisplay(props, 'notificationDateTime', AppModule),
  },
  {
    accessorKey: 'expirationDateTime',
    header: formatMessage({
      id: 'EXPIRATION_DATE_TIME',
      defaultMessage: 'Expiration Date & Time',
    }),
    Cell: props => LongDateDisplay(props, 'expirationDateTime', AppModule),
  },
];

export default getColumns;
