import { RootStore as MobxRootStore } from '@servicexcelerator/ui-design-system';
import CreateClaimModalStore from './ui/CreateClaimModal';
import ClaimSearchFormStore from './ui/ClaimSearchForm';
import ClaimsStore from './domain/Claims';
import MasterDataStore from './domain/MasterData';
import FormStateStore from './domain/FormState';
import UserAccessStore from './domain/UserAccess';
import LocaleStore from './domain/Locale';
import ServiceJobStore from './domain/ServiceJobs';
import ServiceJobSearchFormStore from './ui/ServiceJobSearchForm';
import ServiceJobListStore from './ui/ServiceJobList';
import CustomerSearchForm from './ui/CustomerSearchForm';
import MapStore from './ui/Map';
import RulesStore from './ui/RulesStore';
import ClaimRulesSearchFormStore from './ui/ClaimRulesSearchForm';
import CreateServiceJobModalStore from './ui/CreateServiceJobModal';
import ClaimForms from './ui/ClaimForms';
import ClaimFormSearchStore from './ui/ClaimFormSearchStore';
import DemoServiceProviderFormStore from './ui/DemoServiceProviderForm';
import DemoCustomerFormStore from './ui/DemoCustomerForm';
import RequestAlternateTimeModalStore from './ui/RequestAlternateTimeModal';

function RootStore() {
  const rootStore = new MobxRootStore();

  rootStore.addDomainStore('claims', new ClaimsStore(rootStore));
  rootStore.addDomainStore('masterData', new MasterDataStore(rootStore));
  rootStore.addDomainStore('formState', new FormStateStore(rootStore));
  rootStore.addDomainStore('userAccess', new UserAccessStore(rootStore));
  rootStore.addDomainStore('locale', new LocaleStore(rootStore));
  rootStore.addDomainStore('serviceJobs', new ServiceJobStore(rootStore));

  rootStore.addUiStore(
    'createClaimModal',
    new CreateClaimModalStore(rootStore),
  );
  rootStore.addUiStore('claimSearchForm', new ClaimSearchFormStore(rootStore));
  rootStore.addUiStore(
    'serviceJobSearchForm',
    new ServiceJobSearchFormStore(rootStore),
  );
  rootStore.addUiStore('serviceJobList', new ServiceJobListStore(rootStore));
  rootStore.addUiStore('customerSearchForm', new CustomerSearchForm(rootStore));
  rootStore.addUiStore('mapView', new MapStore(rootStore));
  rootStore.addUiStore('rules', new RulesStore(rootStore));
  rootStore.addUiStore(
    'claimRulesSearchForm',
    new ClaimRulesSearchFormStore(rootStore),
  );

  rootStore.addUiStore(
    'demoServiceProviderForm',
    new DemoServiceProviderFormStore(rootStore),
  );
  rootStore.addUiStore(
    'demoCustomerForm',
    new DemoCustomerFormStore(rootStore),
  );
  rootStore.addUiStore(
    'createServiceJobModal',
    new CreateServiceJobModalStore(rootStore),
  );
  rootStore.addUiStore('claimForms', new ClaimForms(rootStore));
  rootStore.addUiStore('claimFormsSearch', new ClaimFormSearchStore(rootStore));

  rootStore.addUiStore(
    'requestAlternateTimeModal',
    new RequestAlternateTimeModalStore(rootStore),
  );

  return rootStore;
}

export default RootStore;
