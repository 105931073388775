import { LoggerUtil } from '@servicexcelerator/ui-design-system';

const hasServiceJobErrors = responseData =>
  responseData?.serviceJobErrors &&
  Array.isArray(responseData?.serviceJobErrors) &&
  responseData?.serviceJobErrors.length > 0;

async function updateServiceJob(
  data,
  additionalData,
  { updateOne, serviceJobNumber },
) {
  const result = {
    message: {
      message: {
        id: 'ERROR_SAVING_SERVICE_JOB',
        defaultMessage:
          'The Job Could Not be Submitted due to Errors on the Form',
      },
    },
    errors: null,
    success: false,
    data: null,
  };

  const serviceJobUpdateResponse = await updateOne(
    `/servicejob/v1/servicejob/${serviceJobNumber}/operation/update`,
    data,
  );

  // Todo: Need to figure out when we get errors vs error
  if (serviceJobUpdateResponse?.errors) {
    result.errors = serviceJobUpdateResponse.errors;
  } else if (serviceJobUpdateResponse?.error) {
    result.errors = serviceJobUpdateResponse.error;
  } else {
    if (hasServiceJobErrors(serviceJobUpdateResponse?.data)) {
      result.errors = serviceJobUpdateResponse?.data?.serviceJobErrors;
    }
    result.data = serviceJobUpdateResponse?.data;
    result.success = true;
    result.message = {
      message: {
        id: 'SERVICE_JOB_X_UPDATED_SUCCESSFULLY',
        defaultMessage: 'Job {serviceJobNumber} was saved',
      },
      params: {
        serviceJobNumber: serviceJobUpdateResponse?.data?.serviceJobNumber,
      },
    };
  }

  LoggerUtil.log('Update Response', {
    additionalData,
    request: data,
    response: serviceJobUpdateResponse,
    actionResult: result,
  });

  return result;
}

export default updateServiceJob;
