import * as icons from '@mui/icons-material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { SelectFormControl } from '../../Select';
import PageSection from '../../../../composite/PageSection';
import { SwitchFormControl } from '../../Switch';
import { TextFieldFormControl } from '../../TextField';
import { DatePickerFormControl } from '../../DatePicker';
// eslint-disable-next-line import/no-named-as-default
import ReadOnlyFormControl from '../../ReadOnly';
import HiddenFormControl from '../../Hidden';
import FormValue from '../../FormValue';
import FormLabel from '../../FormLabel';
// eslint-disable-next-line import/no-cycle
import SimpleTable from '../../SimpleTable';
import CompositeFormControl from '../../CompositeFormControl';
import IterativeFieldControl from '../../IterativeFieldControl';
import { TimePickerFormControl } from '../../TimePicker';

const getComponents = ({
  setValue,
  getValues,
  componentProps = {},
  control,
  hasConstantValue,
  formMode,
  type = null,
  required = false,
  masterData = [],
  formId,
  jsonSchema,
  format,
  additionalComponents = {},
  effectFunctions = null,
  formatMessage,
  itemsArrayIndex,
  renderComponents,
  dataType,
}) => {
  const componentConfig = {
    Root: {
      component: Grid2,
      ...componentProps,
    },
    Row: {
      component: Grid2,
      componentProps: {
        container: true,
        spacing: 2,
        className: 'DynamicRow',
        ...componentProps,
      },
    },
    FormValue: {
      component: FormValue,
      componentProps: {
        formId,
        dataType,
        ...componentProps,
      },
    },
    FormLabel: {
      component: FormLabel,
      componentProps: {
        control,
        formMode,
        required,
        type,
        masterData,
        hasConstantValue,
        formId,
        jsonSchema,
        dataType,
        ...componentProps,
      },
    },
    Column: {
      component: Grid2,
      componentProps: {
        ...componentProps,
      },
    },
    IterativeFieldControl: {
      component: IterativeFieldControl,
      componentProps: {
        control,
        formMode,
        required,
        type,
        masterData,
        hasConstantValue,
        formId,
        jsonSchema,
        additionalComponents,
        effectFunctions,
        formatMessage,
        itemsArrayIndex,
        setValue,
        getValues,
        dataType,
        renderComponents,
        ...componentProps,
      },
    },
    SimpleTableControl: {
      component: SimpleTable,
      componentProps: {
        control,
        formMode,
        required,
        type,
        masterData,
        hasConstantValue,
        formId,
        jsonSchema,
        additionalComponents,
        effectFunctions,
        formatMessage,
        dataType,
        renderComponents,
        ...componentProps,
      },
    },
    CompositeFormControl: {
      component: CompositeFormControl,
      componentProps: {
        control,
        formMode,
        required,
        type,
        masterData,
        hasConstantValue,
        formId,
        jsonSchema,
        effectFunctions,
        dataType,
        ...componentProps,
      },
    },
    PageSection: {
      component: PageSection,
      componentProps: {
        ...componentProps,
        headerIcon: componentProps.headerIcon
          ? icons[componentProps.headerIcon]
          : null,
        effectFunctions,
      },
    },
    TextArea: {
      component: TextFieldFormControl,
      componentProps: {
        containerComponent: Grid2,
        control,
        formId,
        fullWidth: true,
        multiline: true,
        rows: 2,
        formMode,
        required,
        dataType,
        effectFunctions,
        ...componentProps,
      },
    },
    SelectFormControl: {
      component: SelectFormControl,
      componentProps: {
        containerComponent: Grid2,
        control,
        setValue,
        fullWidth: true,
        hasConstantValue,
        formMode,
        formId,
        required,
        masterData,
        effectFunctions,
        formatMessage,
        dataType,
        ...componentProps,
      },
    },
    SwitchFormControl: {
      component: SwitchFormControl,
      componentProps: {
        containerComponent: Grid2,
        control,
        formId,
        formMode,
        required,
        dataType,
        ...componentProps,
      },
    },
    DatePickerFormControl: {
      component: DatePickerFormControl,
      componentProps: {
        containerComponent: Grid2,
        formId,
        control,
        fullWidth: true,
        slotProps: { textField: { size: 'small' } },
        formMode,
        required,
        effectFunctions,
        dataType,
        ...componentProps,
      },
    },

    TimePickerFormControl: {
      component: TimePickerFormControl,
      componentProps: {
        containerComponent: Grid2,
        formId,
        control,
        fullWidth: true,
        slotProps: { textField: { size: 'small' } },
        formMode,
        required,
        effectFunctions,
        dataType,
        ...componentProps,
      },
    },
    HiddenFormControl: {
      component: HiddenFormControl,
      componentProps: {
        containerComponent: Grid2,
        formId,
        control,
        dataType,
        ...componentProps,
      },
    },
    ReadOnlyFormControl: {
      component: ReadOnlyFormControl,
      componentProps: {
        control,
        containerComponent: Grid2,
        formId,
        fullWidth: true,
        dataType,
        setValue,
        getValues,
        ...componentProps,
      },
    },
    TextField: {
      component: TextFieldFormControl,
      componentProps: {
        containerComponent: Grid2,
        control,
        formId,
        fullWidth: true,
        size: 'small',
        formMode,
        effectFunctions,
        required,
        dataType,
        ...(format !== null && { format }),
        ...(type !== null && { type }),
        ...componentProps,
      },
    },
  };

  return componentConfig;
};

export default getComponents;
