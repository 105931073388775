import { Link } from 'react-router-dom';

function RuleLink(props) {
  const {
    row: {
      original: { claimFormId, claimFormName },
    },
  } = props;
  return <Link to={`/claimforms/edit/${claimFormId}`}>{claimFormName}</Link>;
}

function Country(props, AppModule, masterData) {
  const {
    row: {
      original: { addressCountryCode },
    },
  } = props;
  const { Utils } = AppModule;
  const { MasterDataUtil } = Utils;
  const countries = [];
  if (addressCountryCode?.length > 0) {
    addressCountryCode.forEach(id => {
      countries.push(MasterDataUtil.getCountryById(masterData, id)?.label);
    });
    return countries.join(', ');
  }
  return '<ANY>';
}

function Industry(props, AppModule, masterData) {
  const {
    row: {
      original: { industryId },
    },
  } = props;
  const { Utils } = AppModule;
  const { MasterDataUtil } = Utils;
  const industries = [];
  // prepare string with comma separated industry names and newline separated if multiple industries
  if (industryId?.length > 0) {
    industryId.forEach(id => {
      industries.push(MasterDataUtil.getIndustryById(masterData, id)?.label);
    });
    return industries.join(', ');
  }
  return '<ANY>';
}

function WarrantyType(props, AppModule, masterData) {
  const {
    row: {
      original: { warrantyTypeId },
    },
  } = props;
  const { Utils } = AppModule;
  const { MasterDataUtil } = Utils;
  const warrantyTypes = [];
  if (warrantyTypeId?.length > 0) {
    warrantyTypeId.forEach(id => {
      warrantyTypes.push(
        MasterDataUtil.getWarrantyTypeId(masterData, id)?.label,
      );
    });
    return warrantyTypes.join(', ');
  }
  return '<ANY>';
}

function ServiceType(props, AppModule, masterData) {
  const {
    row: {
      original: { serviceTypeId },
    },
  } = props;
  const { Utils } = AppModule;
  const { MasterDataUtil } = Utils;
  const serviceTypes = [];
  if (serviceTypeId?.length > 0) {
    serviceTypeId.forEach(id => {
      serviceTypes.push(
        MasterDataUtil.getServiceTypeById(masterData, id)?.label,
      );
    });
    return serviceTypes.join(', ');
  }
  return '<ANY>';
}

function ServiceAdministratorClientNumber(props, AppModule, masterData) {
  const {
    row: {
      original: { serviceAdministratorClientNumber },
    },
  } = props;
  const { Utils } = AppModule;
  const { MasterDataUtil } = Utils;
  const clients = [];
  if (serviceAdministratorClientNumber?.length > 0) {
    serviceAdministratorClientNumber.forEach(id => {
      clients.push(MasterDataUtil.getSAClientById(masterData, id)?.label);
    });
    return clients.join(', ');
  }
  return '<ANY>';
}

const getColumns = (formatMessage, AppModule, masterData) => [
  {
    accessorKey: 'checkOrder',
    header: formatMessage({
      id: 'CLAIM_FORM_SEARCH_ORDER',
      defaultMessage: 'Search Order',
    }),
    enableColumnActions: false,
  },
  {
    accessorKey: 'claimFormName',
    header: formatMessage({
      id: 'CLAIM_FORM_NAME',
      defaultMessage: 'Claim Form Name',
    }),
    enableColumnActions: false,
    Cell: props => RuleLink(props),
  },
  {
    accessorKey: 'addressCountryCode',
    header: formatMessage({
      id: 'COUNTRY',
      defaultMessage: 'Country',
    }),
    enableColumnActions: false,
    Cell: props => Country(props, AppModule, masterData),
  },
  {
    accessorKey: 'industryId',
    header: formatMessage({
      id: 'INDUSTRY',
      defaultMessage: 'Industry',
    }),
    enableColumnActions: false,
    Cell: props => Industry(props, AppModule, masterData),
  },
  {
    accessorKey: 'warrantyTypeId',
    header: formatMessage({
      id: 'WARRANTY_TYPE',
      defaultMessage: 'Warranty Type',
    }),
    enableColumnActions: false,
    Cell: props => WarrantyType(props, AppModule, masterData),
  },
  {
    accessorKey: 'serviceTypeId',
    header: formatMessage({
      id: 'SERVICE_TYPE',
      defaultMessage: 'Service Type',
    }),
    enableColumnActions: false,
    Cell: props => ServiceType(props, AppModule, masterData),
  },
  {
    accessorKey: 'serviceAdministratorClientNumber',
    header: formatMessage({
      id: 'CLIENT',
      defaultMessage: 'Client',
    }),
    enableColumnActions: false,
    Cell: props =>
      ServiceAdministratorClientNumber(props, AppModule, masterData),
  },
  {
    accessorKey: 'status',
    header: formatMessage({
      id: 'CLAIM_FORM_STATUS',
      defaultMessage: 'Claim Form Status',
    }),
    enableColumnActions: false,
  },
];

export default getColumns;
