import { Link } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

function ServiceProviderDetail(props) {
  const {
    row: { original },
  } = props;
  return original?.serviceProviderDetail?.companyName || '';
}

function AppointmentDate(props, AppModule) {
  const {
    row: { original },
  } = props;
  const { Utils } = AppModule;
  const { toMediumDate } = Utils;

  const normalTime = original?.arrivalWindowStartTime
    ? dayjs(original?.arrivalWindowStartTime, 'HHmm').format('h:mm A')
    : '';

  const scheduledDate = `${toMediumDate(
    original?.appointmentDate,
  )} ${normalTime}`;

  return original?.appointmentDate ? scheduledDate : '';
}

function CustomerName(props) {
  const {
    row: { original },
  } = props;

  const name = [];
  if (original?.customer?.customerNameFirst) {
    name.push(original?.customer?.customerNameFirst);
  }
  if (original?.customer?.customerNameLast) {
    name.push(original?.customer?.customerNameLast);
  }
  return name?.join(' ');
}

function IconClaimStatus(props, AppModule) {
  const {
    row: {
      original: {
        serviceJobStatus: { key, value } = { key: 'UnKnown', value: 'UnKnown' },
      },
    },
  } = props;
  const { Components } = AppModule;
  const { ServiceJobStatusIcon } = Components;

  return (
    <Grid2 sx={{ display: 'inline-flex' }} alignItems="center">
      <ServiceJobStatusIcon statusId={key} sx={{ marginRight: '5px' }} />
      {value}
    </Grid2>
  );
}

function ReceivedDaysAgo(props) {
  const {
    row: { original },
  } = props;
  return dayjs().to(dayjs(original.serviceCreatedDate));
}

function LastUpdated(props) {
  const {
    row: { original },
  } = props;
  return dayjs().to(dayjs(original.serviceJobStatusDate));
}

function Address(props) {
  const {
    row: { original },
  } = props;

  const address = [];
  const { customer = null } = original;
  if (customer) {
    const { customerAddress = null } = customer;
    if (customerAddress?.addressStreet) {
      address.push(customerAddress?.addressStreet);
    }
    if (customerAddress?.addressCity) {
      address.push(customerAddress?.addressCity);
    }
    if (customerAddress?.addressState) {
      address.push(customerAddress?.addressState);
    }
    if (customerAddress?.addressPostalCode) {
      address.push(customerAddress?.addressPostalCode);
    }
  }
  return address?.join(', ');
}

function ServiceJobLink(props, AppModule) {
  const {
    row: {
      original: { serviceJobNumber },
    },
  } = props;
  const { Components, Constants } = AppModule;
  const { useUserAccess } = Components;
  const { COMPANY_TYPE } = Constants;
  const userAccess = useUserAccess();
  const isSA = userAccess.companyType === COMPANY_TYPE.SERVICE_ADMINISTRATOR;
  return (
    <Link href={`/servicejob/${isSA ? 'edit' : 'view'}/${serviceJobNumber}`}>
      {serviceJobNumber}
    </Link>
  );
}

const getColumns = (formatMessage, AppModule) => [
  {
    accessorKey: 'serviceJobNumber',
    header: formatMessage({
      id: 'JOB_NUMBER',
      defaultMessage: 'Job Number',
    }),
    Cell: props => ServiceJobLink(props, AppModule),
  },
  {
    accessorKey: 'serviceJobStatus.value',
    header: formatMessage({
      id: 'STATUS',
      defaultMessage: 'Status',
    }),
    Cell: props => IconClaimStatus(props, AppModule),
  },
  {
    accessorKey: 'serviceProviderDetail',
    header: formatMessage({
      id: 'SERVICE_PROVIDER',
      defaultMessage: 'Service Provider',
    }),
    Cell: props => ServiceProviderDetail(props),
  },
  {
    accessorKey: 'appointmentDate',
    header: formatMessage({
      id: 'APPOINTMENT_DATE',
      defaultMessage: 'Appointment Date',
    }),
    Cell: props => AppointmentDate(props, AppModule),
  },
  {
    accessorKey: 'customer',
    header: formatMessage({
      id: 'CUSTOMER',
      defaultMessage: 'Customer',
    }),
    Cell: props => CustomerName(props),
  },

  {
    accessorKey: 'customerCity',
    header: formatMessage({
      id: 'ADDRESS',
      defaultMessage: 'Address',
    }),
    Cell: props => Address(props),
  },
  {
    accessorKey: 'brand.brandName',
    header: formatMessage({
      id: 'BRAND',
      defaultMessage: 'Brand',
    }),
  },
  {
    accessorKey: 'productType',
    header: formatMessage({
      id: 'PRODUCT_TYPE',
      defaultMessage: 'Product Type',
    }),
  },
  {
    accessorKey: 'receivedDate',
    header: formatMessage({
      id: 'SERVICE_JOB_AGE',
      defaultMessage: 'Job Age',
    }),
    Cell: props => ReceivedDaysAgo(props),
  },

  {
    accessorKey: 'serviceJobStatusDate',
    header: formatMessage({
      id: 'LAST_UPDATED',
      defaultMessage: 'Last Updated',
    }),
    Cell: props => LastUpdated(props),
  },
];

export default getColumns;
