import { IconButton, Typography, styled } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import {
  getNextDatesWithWeekdays,
  formattedDate,
  convertToAMPM,
  getColumnId,
  isToday,
} from './lib/utils';

import EventSlot from './EventSlot';
import BookingSlot from './BookingSlot';

const border = `1px solid #dadada`;

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  boxShadow: theme.shadows[1],
  borderRadius: '8px',
  width: '32px',
  height: '32px',
  background: '#ffffff',
  ':hover': {
    background: theme.palette.secondary.light,
  },
}));

const CalendarContainer = styled(Grid2)({});

const WeekDayHeaderColumn = styled(Grid2)({
  textAlign: 'center',
  borderRight: border,
  borderLeft: border,
  borderBottom: border,
  borderTop: border,
  padding: '10px',
});

const HeaderName = styled(Typography, {
  shouldForwardProp: props => !['special'].includes(props),
})(({ theme, special = false }) => ({
  color: special ? theme.palette.success.light : '#000000',
  fontWeight: 500,
}));

const WeekDayHeader = styled(Typography)({
  color: '#828487',
  fontWeight: 500,
});

const TimeHeaderColumn = styled(Grid2)({
  textAlign: 'center',
  borderRight: border,
  borderLeft: border,
  borderBottom: border,
  padding: '10px',
});

const WeekDayValueColumn = styled(Grid2)({
  textAlign: 'center',
  borderRight: border,
  borderLeft: border,
  borderBottom: border,
  padding: '10px',
});

export const CALENDAR_TYPE = {
  BOOKING_SLOT: 'booking_slot',
  EVENT_SLOT: 'event_slot',
};

function CalendarGrid({
  rows,
  columns,
  calendarState,
  startTime,
  minTick,
  onGridRendered = () => undefined,
}) {
  useEffect(() => {
    onGridRendered();
  }, [calendarState.events]);

  return rows.map((_, timeRowIndex) => (
    // eslint-disable-next-line react/no-array-index-key
    <Grid2 container key={`row${timeRowIndex}`}>
      {timeRowIndex === 0
        ? columns.map((_c1, columnIndex) =>
            columnIndex === 0 ? (
              <WeekDayHeaderColumn
                sm
                id={`weekBlock${columnIndex}`}
                key="unUsedWeek">
                &nbsp;
              </WeekDayHeaderColumn>
            ) : (
              <WeekDayHeaderColumn
                sm
                key={calendarState.nextXDates[columnIndex - 1].date}
                id={`weekBlock${columnIndex}`}>
                <WeekDayHeader>
                  {calendarState.nextXDates[columnIndex - 1].weekday}
                </WeekDayHeader>
                <HeaderName
                  special={isToday(
                    calendarState.nextXDates[columnIndex - 1].date,
                  )}>
                  {formattedDate(
                    calendarState.nextXDates[columnIndex - 1].date,
                  )}
                </HeaderName>
              </WeekDayHeaderColumn>
            ),
          )
        : columns.map((_c2, columnIndex) => {
            if (columnIndex === 0) {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <TimeHeaderColumn sm key={columnIndex}>
                  <HeaderName>
                    {convertToAMPM(startTime + minTick * (timeRowIndex - 1))}
                  </HeaderName>
                </TimeHeaderColumn>
              );
            }
            return (
              <WeekDayValueColumn
                id={getColumnId({
                  dated: calendarState.nextXDates[columnIndex - 1].date,
                  weekday: calendarState.nextXDates[columnIndex - 1].weekday,
                  time: startTime + minTick * (timeRowIndex - 1),
                })}
                sm
                // eslint-disable-next-line react/no-array-index-key
                key={columnIndex}>
                &nbsp;
              </WeekDayValueColumn>
            );
          })}
    </Grid2>
  ));
}

function Calendar({
  startDate = dayjs(new Date()).format('YYYY-MM-DD'),
  startTime = 600,
  endTime = 1800,
  minTick = 100,
  datesToDisplay = 7,
  paginationCount = 7,
  fetchData = () => undefined,
}) {
  const totalColumns = datesToDisplay + 1;
  const columns = Array.from({ length: totalColumns });
  const totalRows = (endTime - startTime) / minTick + 2;
  const rows = Array.from({ length: totalRows });

  const createCalendarState = (startDateArg, data = []) => {
    const nextXDates = getNextDatesWithWeekdays(startDateArg, datesToDisplay);
    return {
      startDate: startDateArg,
      nextXDates,
      events: data,
    };
  };

  const addEventToCalendar = eventArg => {
    switch (eventArg.type) {
      case CALENDAR_TYPE.EVENT_SLOT:
        return <EventSlot key={JSON.stringify(eventArg)} {...eventArg} />;
      case CALENDAR_TYPE.BOOKING_SLOT:
        return <BookingSlot key={JSON.stringify(eventArg)} {...eventArg} />;
      default:
        return null;
    }
  };

  const [navigation, setNavigation] = useState({
    left1: -1,
    left2: -1,
    top: -1,
  });
  const [showEvents, setShowEvents] = useState(false);

  const [calendarState, setCalendarState] = useState({
    startDate: dayjs().format('YYYY-MM_DD'),
    nextXDates: getNextDatesWithWeekdays(startDate, datesToDisplay),
    events: [],
  });

  const onNext = async () => {
    setShowEvents(false);
    const nextDate = dayjs(calendarState.startDate)
      .add(paginationCount, 'day')
      .format('YYYY-MM-DD');

    const data = await fetchData(nextDate);
    const calendarStateNew = createCalendarState(nextDate, data);
    setCalendarState(calendarStateNew);
  };

  const onPrevious = async () => {
    setShowEvents(false);
    const previousDate = dayjs(calendarState.startDate)
      .add(paginationCount * -1, 'day')
      .format('YYYY-MM-DD');
    const data = await fetchData(previousDate);
    const calendarStateNew = createCalendarState(previousDate, data);
    setCalendarState(calendarStateNew);
  };

  const setNavigationPosition = () => {
    const firstElement = document.getElementById(`weekBlock0`);
    const firstElementBlock = firstElement?.getBoundingClientRect();

    const lastElement = document.getElementById(`weekBlock${datesToDisplay}`);
    const lastElementBlock = lastElement?.getBoundingClientRect();

    const leftFirstElement =
      (firstElementBlock?.left || 0) + (firstElementBlock?.width || 0) - 15;
    const leftLastElement =
      (lastElementBlock?.left || 0) + (lastElementBlock?.width || 0) - 15;
    const top = firstElementBlock.top + 10;
    setNavigation({ left1: leftFirstElement, left2: leftLastElement, top });
  };

  const renderNavigation = () => (
    <div>
      <StyledIconButton
        onClick={() => onPrevious()}
        size="small"
        variant="outlined"
        sx={{
          left: navigation.left1,
          top: navigation.top,
          position: 'absolute',
        }}>
        <NavigateBeforeIcon />
      </StyledIconButton>
      <StyledIconButton
        onClick={() => onNext()}
        size="small"
        variant="outlined"
        sx={{
          left: navigation.left2,
          top: navigation.top,
          position: 'absolute',
        }}>
        <NavigateNextIcon />
      </StyledIconButton>
    </div>
  );

  useEffect(() => {
    const initialize = async () => {
      setShowEvents(false);
      const events = await fetchData(startDate);
      const calendarStateInitial = createCalendarState(startDate, events);
      setCalendarState(calendarStateInitial);

      setNavigationPosition();
    };

    initialize();

    window.addEventListener('resize', setNavigationPosition);

    return () => {
      window.removeEventListener('resize', setNavigationPosition);
    };
  }, []);

  return (
    <CalendarContainer id="calendarContainer">
      <CalendarGrid
        rows={rows}
        columns={columns}
        calendarState={calendarState}
        startTime={startTime}
        minTick={minTick}
        onGridRendered={() => {
          setShowEvents(true);
        }}
      />
      <div>
        {showEvents &&
          calendarState.events.map(eventItem => addEventToCalendar(eventItem))}
      </div>
      {renderNavigation()}
    </CalendarContainer>
  );
}

export default Calendar;
