import { Button, styled } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {
  ArrowStepper,
  Breadcrumbs,
  StickySection,
  useScrolling,
} from '@servicexcelerator/ui-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import PageHeader from './Header';

const StickySectionStyled = styled(StickySection)(({ theme }) => ({
  '.smooth': {
    backgroundColor: theme.palette.background.content,
  },
}));

function HeaderSection({ steps, pageData = null, AppModule }) {
  const { Components, Constants, Icons } = AppModule;
  const { BackButton } = Icons;
  const { Header } = Components;
  const { sectionMapping } = Constants;
  const { formatMessage } = useIntl();
  const { scroller } = useScrolling();

  return (
    <StickySectionStyled
      top={0}
      offset={100}
      innerClass="smooth"
      innerZ={20}
      enabled>
      <Grid2
        id="stickyHeaderContainer"
        container
        spacing={3}
        flexDirection="column">
        <Grid2>
          <Header>
            <PageHeader AppModule={AppModule} pageData={pageData} />
          </Header>
        </Grid2>
        <Grid2 container alignItems="center" pt={0} pb={0}>
          <Grid2 xs={6}>
            <Breadcrumbs
              links={[
                {
                  label: formatMessage({
                    id: 'DASHBOARD',
                    defaultMessage: 'Dashboard',
                  }),
                  href: '/dashboard',
                },
                {
                  label: formatMessage({
                    id: 'VIEW_SERVICEJOBS',
                    defaultMessage: 'View Jobs',
                  }),
                  href: '/servicejobs/search',
                },
                {
                  label: `${
                    pageData?.serviceJobNumber ||
                    formatMessage({
                      id: 'NEW_SERVICEJOB',
                      defaultMessage: 'New Job',
                    })
                  }`,
                },
              ]}
            />
          </Grid2>
          <Grid2 textAlign="right" xs={6}>
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                window.history.back();
              }}
              startIcon={<BackButton />}>
              <FormattedMessage id="BACK_BUTTON" defaultMessage="Back" />
            </Button>
          </Grid2>
        </Grid2>
        <Grid2>
          <Grid2 sx={{ mb: 2 }}>
            <ArrowStepper
              containerId="stickyHeaderContainer"
              onChange={(_, stepInfo) => {
                scroller.scrollTo(stepInfo.id, {
                  duration: 1000,
                  delay: 100,
                  smooth: true,
                  offset: -190,
                });
              }}
              steps={steps}
              sectionMapping={sectionMapping}
              errors={[]}
            />
          </Grid2>
        </Grid2>
      </Grid2>
    </StickySectionStyled>
  );
}

export default HeaderSection;
