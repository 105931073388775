import {
  DYNAMIC_FORM_MODE,
  DynamicForm,
  FormJsonSchemaUtil,
  FormValue,
  LoadingEvent,
  PageSection,
  SpecialSection,
  useStore,
} from '@servicexcelerator/ui-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, FormLabel, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { nanoid } from 'nanoid';
import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import jsonSchemaSimpleSearch from './simple/jsonSchema.json';
import uiSchemaSimpleSearch from './simple/uiSchema.json';
import jsonSchemaAdvancedSearch from './advanced/jsonSchema.json';
import uiSchemaAdvancedSearch from './advanced/uiSchema.json';
import getAdditionalComponents from './getAdditionalComponents';

export const CUSTOMER_SEARCH_SCHEMA = {
  SIMPLE: 'SIMPLE',
  ADVANCED: 'ADVANCED',
};

function CustomerSearch({
  onColumnFiltersChanged = () => undefined,
  columnFilters = [],
  formValues = {},
  AppModule,
}) {
  const simpleSchema = {
    uiSchema: uiSchemaSimpleSearch,
    jsonSchema: jsonSchemaSimpleSearch,
  };

  const advancedSchema = {
    uiSchema: uiSchemaAdvancedSearch,
    jsonSchema: jsonSchemaAdvancedSearch,
  };

  const { Icons } = AppModule;
  const { Customer } = Icons;
  const { formatMessage } = useIntl();
  const formRef = useRef();
  const store = useStore();
  const [errors, setErrors] = useState([]);

  const isAdvancedSearchForm = () =>
    store.uiState.customerSearchForm.getSchemaType() ===
    CUSTOMER_SEARCH_SCHEMA.ADVANCED;

  const [schema, setSchema] = useState(
    isAdvancedSearchForm() ? advancedSchema : simpleSchema,
  );

  const [defaultValues, setDefaultvalues] = useState(formValues);
  const [initialized, setInitialized] = useState(false);
  const [currentFormMode, setCurrentFormMode] = useState(
    columnFilters.length > 0 ? DYNAMIC_FORM_MODE.VIEW : DYNAMIC_FORM_MODE.EDIT,
  );

  const masterData = store.domain.masterData.get();

  const onSubmitSuccess = data => {
    // Has atleast one object
    const filterQuery = Object.entries(data).reduce((acc, [key, value]) => {
      if (value) {
        acc.push({
          id: key,
          value,
        });
      }
      return acc;
    }, []);

    if (filterQuery.length === 0) {
      setErrors([
        {
          path: '',
          errorMessage: isAdvancedSearchForm()
            ? formatMessage({
                id: 'NEED_ATLEAST_ONE_SEARCH_ITEM',
                defaultMessage: 'Need atleast one search item to be filled',
              })
            : formatMessage({
                id: 'NEED_SEARCH_TEXT',
                defaultMessage: 'Please provide search text',
              }),
        },
      ]);
    } else {
      setCurrentFormMode(DYNAMIC_FORM_MODE.VIEW);
      setErrors([]);
      onColumnFiltersChanged(filterQuery);
    }
  };

  const onSubmitError = data => {
    setErrors(data);
  };

  const switchSearch = () => {
    store.uiState.customerSearchForm.set({});

    if (!isAdvancedSearchForm()) {
      store.uiState.customerSearchForm.setSchemaType(
        CUSTOMER_SEARCH_SCHEMA.ADVANCED,
      );
    } else {
      store.uiState.customerSearchForm.setSchemaType(
        CUSTOMER_SEARCH_SCHEMA.SIMPLE,
      );
    }
    onColumnFiltersChanged([]);
    setCurrentFormMode(DYNAMIC_FORM_MODE.EDIT);
    setErrors([]);
  };

  const ADVANCED_SEARCH_BUTTON = {
    color: 'primary',
    variant: 'text',
    children: formatMessage({
      id: 'ADVANCED_SEARCH_BUTTON',
      defaultMessage: 'Advanced Search',
    }),
    sx: { textTransform: 'none', fontSize: '16px' },
    type: 'button',
    onClick: () => {
      switchSearch();
    },
  };

  const SIMPLE_SEARCH_BUTTON = {
    color: 'primary',
    variant: 'text',
    children: formatMessage({
      id: 'SIMPLE_SEARCH_BUTTON',
      defaultMessage: 'Basic Search',
    }),
    sx: { textTransform: 'none', fontSize: '16px' },
    type: 'button',
    onClick: () => {
      switchSearch();
    },
  };

  const searchFormButton = [
    isAdvancedSearchForm() ? SIMPLE_SEARCH_BUTTON : ADVANCED_SEARCH_BUTTON,
    {
      color: 'primary',
      variant: 'contained',
      children: formatMessage({
        id: 'SEARCH_BUTTON',
        defaultMessage: 'Search',
      }),
      type: 'button',
      onClick: () => {
        formRef.current.submitForm();
      },
    },
  ];

  const reEditFormButtons = [
    isAdvancedSearchForm() ? SIMPLE_SEARCH_BUTTON : ADVANCED_SEARCH_BUTTON,
    {
      color: 'primary',
      variant: 'contained',
      children: formatMessage({
        id: 'EDIT_SEARCH_BUTTON',
        defaultMessage: 'Edit Search',
      }),
      type: 'button',
      onClick: () => {
        setCurrentFormMode(DYNAMIC_FORM_MODE.EDIT);
      },
    },
  ];

  useEffect(() => {
    setSchema(isAdvancedSearchForm() ? advancedSchema : simpleSchema);
  }, [store.uiState.customerSearchForm.getSchemaType()]);

  useEffect(() => {
    setInitialized(false);
    const values = columnFilters.reduce(
      (acc, item) => ({
        ...acc,
        [item.id]: item.value,
      }),
      {},
    );
    setDefaultvalues(values);
    setInitialized(true);
  }, [columnFilters]);

  const renderFilters = (columnFiltersToRender = []) => {
    const getValue = (id, value) => value;

    const columnMap = FormJsonSchemaUtil.getLeafNodesMap([
      isAdvancedSearchForm() ? uiSchemaAdvancedSearch : uiSchemaSimpleSearch,
    ]);

    const flattenObjectTree = (obj, prefix = '') => {
      const flattened = {};

      // eslint-disable-next-line no-restricted-syntax
      for (const key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          Object.assign(
            flattened,
            flattenObjectTree(obj[key], `${prefix}${key}.`),
          );
        } else {
          flattened[`${prefix}${key}`] = obj[key];
        }
      }

      return flattened;
    };

    const flatten = arrayOfObjects => {
      const result = [];
      arrayOfObjects.every(item => {
        if (typeof item.value === 'object') {
          const items = flattenObjectTree({ [item.id]: item.value }) || {};
          const flattendItems = Object.entries(items)
            .filter(([, value]) => value !== null)
            .map(([key, value]) => ({
              id: key,
              value,
            }));
          result.push(...flattendItems);
        } else if (item.value !== null) {
          result.push(item);
        }
        return true;
      });
      return result;
    };

    const columnFiltersToRenderFinal = flatten(columnFiltersToRender);

    return columnFiltersToRenderFinal.map(item => (
      <Grid2 xs={4} key={item.id} container spacing={1}>
        <Grid2>
          <FormLabel>
            <FormattedMessage
              defaultMessage={columnMap.get(item.id)['ui:props'].label}
              id={columnMap.get(item.id)['ui:props'].localeId}
            />
          </FormLabel>
        </Grid2>
        <Grid2>:</Grid2>
        <Grid2>
          <FormValue>{getValue(item.id, item.value)}</FormValue>
        </Grid2>
      </Grid2>
    ));
  };

  return (
    <PageSection
      subSection
      label={formatMessage({
        id: 'FIND_CUSTOMER',
        defaultMessage: 'Find Customer',
      })}
      headerIcon={Customer}>
      {errors.length > 0 && (
        <Grid2 ml={1} mr={1} mb={2}>
          <SpecialSection color="error.main">
            <Typography>
              <FormattedMessage
                id="RESOLVE_ISSUES_BEFORE_FORM_SUBMIT"
                defaultMessage="Please resolve below issues before submitting the form."
              />
            </Typography>
            <ul>
              {errors.map(item => (
                <li key={item.errorMessage} style={{ marginBottom: '5px' }}>
                  <Typography color="error">
                    {item?.path}
                    {item?.path ? ' : ' : ''}
                    {item.errorMessage}
                  </Typography>
                </li>
              ))}
            </ul>
          </SpecialSection>
        </Grid2>
      )}
      {currentFormMode === DYNAMIC_FORM_MODE.VIEW ? (
        <Grid2 container spacing={2}>
          {renderFilters(columnFilters)}
        </Grid2>
      ) : (
        <DynamicForm
          key={JSON.stringify(schema.jsonSchema)}
          formId="customerSearchForm"
          formMode={currentFormMode}
          uiSchema={schema.uiSchema}
          jsonSchema={schema.jsonSchema}
          onSubmitSuccess={onSubmitSuccess}
          onSubmitError={onSubmitError}
          reference={formRef}
          masterData={masterData}
          initialFormValues={defaultValues}
          initialized={initialized}
          additionalComponents={getAdditionalComponents(AppModule)}
          loadingComponent={
            <LoadingEvent>
              <FormattedMessage
                id="LOADING_SCHEMA"
                defaultMessage="Loading Schema"
              />
            </LoadingEvent>
          }
          formatMessage={formatMessage}
        />
      )}

      {initialized && currentFormMode === DYNAMIC_FORM_MODE.EDIT && (
        <Grid2 mt={1} mb={1} container spacing={2} justifyContent="end">
          {searchFormButton.map(itemProps => (
            <Grid2 key={nanoid()}>
              <Button {...itemProps} />
            </Grid2>
          ))}
        </Grid2>
      )}
      {currentFormMode === DYNAMIC_FORM_MODE.VIEW && (
        <Grid2 container mt={1} spacing={2} justifyContent="end">
          {reEditFormButtons.map(itemProps => (
            <Grid2 key={nanoid()}>
              <Button size="small" {...itemProps} />
            </Grid2>
          ))}
        </Grid2>
      )}
    </PageSection>
  );
}

export default observer(CustomerSearch);
