import ShortcutIcon from '@mui/icons-material/Shortcut';
import { Button, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function BackButton() {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Button
      variant="contained"
      sx={{
        boxShadow: theme.shadows[4],
        position: 'absolute',
        right: '10px',
        top: '10px',
        background: '#ffffff',
        paddingLeft: '10px',
        paddingRight: '10px',
        minWidth: '0px',
        color: '#000000',
        ':hover': {
          background: '#efefef',
        },
      }}
      onClick={() => navigate('/dashboard')}>
      <ShortcutIcon sx={{ transform: 'scale(-1, 1)' }} />
    </Button>
  );
}

export default BackButton;
