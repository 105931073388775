import { useState } from 'react';
import { Box, Drawer, useTheme } from '@mui/material';
import MenuButton from './MenuButton';
import SidebarCloseButton from './SidebarCloseButton';
import PreviousJobButton from './PreviousJobButton';
import HeaderTitle from './HeaderTitle';
import NextJobButton from './NextJobButton';

const drawerWidth = 350;

function Sidebar({
  AppModule,
  children,
  jobData,
  nextJob,
  previousJob,
  loading,
}) {
  const { Icons } = AppModule;
  const { ServiceJob } = Icons;
  const theme = useTheme();
  const [open, setOpen] = useState(true);

  const jobDetail = jobData?.current;

  return (
    <>
      <MenuButton setOpen={setOpen} />
      <Drawer
        PaperProps={{
          sx: {
            width: drawerWidth,
            overflow: 'visible',
            zIndex: '100 !important',
            boxShadow: theme.shadows[4],
          },
        }}
        variant="persistent"
        onClose={() => {
          setOpen(false);
        }}
        anchor="left"
        open={open}>
        <SidebarCloseButton open={open} setOpen={setOpen} />
        <PreviousJobButton
          previousJob={previousJob}
          serviceJobNumber={jobData?.previous}
          loading={loading}
        />
        <HeaderTitle
          loading={loading}
          jobDetail={jobDetail}
          Icon={ServiceJob}
        />
        <NextJobButton
          serviceJobNumber={jobData?.next}
          nextJob={nextJob}
          loading={loading}
        />
        <Box sx={{ marginTop: '40px' }}>{children}</Box>
      </Drawer>
    </>
  );
}

export default Sidebar;
