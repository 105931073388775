import { Autocomplete, FormControl, TextField } from '@mui/material';

function FactField(props) {
  const { field, conditionId, onChange, fieldError, factFieldMap, factFields } =
    props;
  const fact = field ? factFieldMap[field] : null;

  return (
    <FormControl fullWidth style={{ minWidth: 270 }}>
      <Autocomplete
        size="small"
        fullWidth
        disableClearable
        groupBy={option => option.group}
        getOptionLabel={option => option.label}
        isOptionEqualToValue={(option, value) => option.field === value.field}
        options={factFields}
        renderInput={params => (
          <TextField
            {...params}
            placeholder="Fact"
            size="small"
            variant="outlined"
            error={!!fieldError?.errorMessage}
            helperText={fieldError?.errorMessage}
          />
        )}
        value={fact}
        onChange={(event, selected) => {
          const value = selected ? selected.field : null;
          onChange({ conditionId, value });
        }}
      />
    </FormControl>
  );
}
export default FactField;
