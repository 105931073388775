import { useEffect, useRef } from 'react';
import maplibregl, { NavigationControl } from 'maplibre-gl';

function SXCLMap({ lat = 0, lng = 0, zoom = 14, onReady = () => {} }) {
  const mapContainer = useRef(null);
  const map = useRef(null);

  useEffect(() => {
    if (map.current) return; // stops map from intializing more than once
    const mapUrl = `https://api.maptiler.com/maps/${
      process.env.REACT_APP_MAP_ID || 'streets'
    }/style.json?key=${process.env.REACT_APP_MAP_API_KEY}`;

    map.current = new maplibregl.Map({
      container: mapContainer.current,
      style: mapUrl,
      center: [lng, lat],
      zoom,
      attributionControl: true,
    });

    map.current.on('load', () => {
      onReady(map.current);
    });

    map.current.addControl(new NavigationControl(), 'bottom-right');
  }, [lng, lat, zoom]);

  return (
    <div className="map-wrap">
      <div ref={mapContainer} className="map" />
    </div>
  );
}

export default SXCLMap;
