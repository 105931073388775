import { History, Quiz, WorkOutline, Engineering } from '@mui/icons-material';

const getSteps = (formatMessage, serviceJobFormId, AppModule, isSA, status) => {
  const { Icons } = AppModule;
  const {
    BasicInfo: BasicInfoIcon,
    Customer,
    NotesAttachment,
    Payment,
    Product,
    Service,
    Parts,
  } = Icons;

  const STEPS = {
    BASIC_INFO: {
      icon: BasicInfoIcon,
      label: formatMessage({
        id: 'BASIC_INFO',
        defaultMessage: 'Basic Info',
      }),
      id: 'stepBasicInfo',
    },
    CUSTOMER: {
      icon: Customer,
      label: formatMessage({
        id: 'CUSTOMER',
        defaultMessage: 'Customer',
      }),
      id: 'stepCustomer',
    },
    PRODUCT: {
      icon: Product,
      label: formatMessage({
        id: 'PRODUCT',
        defaultMessage: 'Product',
      }),
      id: 'stepProduct',
    },
    SERVICE: {
      icon: Service,
      label: formatMessage({
        id: 'SERVICE',
        defaultMessage: 'Service',
      }),
      id: 'stepService',
    },
    AUTHORIZATION: {
      icon: Payment,
      label: formatMessage({
        id: 'AUTHORIZATION',
        defaultMessage: 'Authorization',
      }),
      id: 'stepAuthorization',
    },
    NOTES_AND_ATTACHMENTS: {
      icon: NotesAttachment,
      label: formatMessage({
        id: 'NOTES',
        defaultMessage: 'Notes/Attachment',
      }),
      id: 'stepNotesAttachment',
    },
    ADDITIONAL_INFO: {
      icon: Quiz,
      label: formatMessage({
        id: 'ADDITIONAL_INFO',
        defaultMessage: 'Additional Info',
      }),
      id: 'stepAdditionalInfo',
    },
    PARTS: {
      icon: Parts,
      label: formatMessage({
        id: 'PARTS',
        defaultMessage: 'Parts',
      }),
      id: 'stepParts',
    },
    SERVICEHISTORY: {
      icon: History,
      label: formatMessage({
        id: 'SERVICE_HISTORY',
        defaultMessage: 'Service History',
      }),
      id: 'stepServiceHistory',
    },
    SERVICEJOB: {
      icon: WorkOutline,
      label: formatMessage({
        id: 'SERVICE_JOB',
        defaultMessage: 'Job',
      }),
      id: 'stepServiceJob',
    },
    DISPATCH: {
      icon: Engineering,
      label: formatMessage({
        id: 'DISPATCH',
        defaultMessage: 'Dispatch',
      }),
      id: 'stepDispatch',
    },
  };
  if (serviceJobFormId) {
    switch (serviceJobFormId) {
      case 'JOBINITIATION-2024':
        return [STEPS.CUSTOMER, STEPS.PRODUCT, STEPS.SERVICE];
      case 'BASICOEM-2024': {
        if (isSA) {
          return [
            STEPS.BASIC_INFO,
            STEPS.CUSTOMER,
            STEPS.PRODUCT,
            STEPS.SERVICE,
            STEPS.SERVICEJOB,
            STEPS.DISPATCH,
            STEPS.PARTS,
            STEPS.AUTHORIZATION,
            STEPS.SERVICEHISTORY,
            STEPS.NOTES_AND_ATTACHMENTS,
            STEPS.ADDITIONAL_INFO,
          ];
        }
        if (!isSA && status === 'ACC') {
          return [
            STEPS.BASIC_INFO,
            STEPS.CUSTOMER,
            STEPS.PRODUCT,
            STEPS.SERVICE,
            STEPS.SERVICEJOB,
            STEPS.PARTS,
            STEPS.AUTHORIZATION,
            STEPS.SERVICEHISTORY,
            STEPS.NOTES_AND_ATTACHMENTS,
            STEPS.ADDITIONAL_INFO,
          ];
        }
        if (!isSA && status === 'PAC') {
          return [STEPS.CUSTOMER, STEPS.PRODUCT, STEPS.SERVICE];
        }
        return [];
      }

      case 'SERVICECONTRACT-2024':
        return [
          STEPS.BASIC_INFO,
          STEPS.CUSTOMER,
          STEPS.PRODUCT,
          STEPS.SERVICE,
          STEPS.SERVICEJOB,
          STEPS.DISPATCH,
          STEPS.PARTS,
          STEPS.AUTHORIZATION,
          STEPS.SERVICEHISTORY,
          STEPS.NOTES_AND_ATTACHMENTS,
          STEPS.ADDITIONAL_INFO,
        ];
      case 'COD-2024':
        return [
          STEPS.BASIC_INFO,
          STEPS.CUSTOMER,
          STEPS.PRODUCT,
          STEPS.SERVICE,
          STEPS.SERVICEJOB,
          STEPS.DISPATCH,
          STEPS.PARTS,
          STEPS.AUTHORIZATION,
          STEPS.SERVICEHISTORY,
          STEPS.NOTES_AND_ATTACHMENTS,
          STEPS.ADDITIONAL_INFO,
        ];
      default:
        return [];
    }
  }
  return [];
};

export default getSteps;
