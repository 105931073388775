import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import {
  FormJsonSchemaUtil,
  FormValue,
  useStore,
} from '@servicexcelerator/ui-design-system';

const paymentItems = () => {
  const result = [
    {
      'html:name': `authorizedAmounts.labor`,
    },
    {
      'html:name': `authorizedAmounts.freightAndShipping`,
    },
    {
      'html:name': `authorizedAmounts.travel`,
    },
    {
      'html:name': `authorizedAmounts.totalParts`,
    },
    {
      'html:name': `authorizedAmounts.partsHandling`,
    },
    {
      'html:name': `authorizedAmounts.tax`,
    },
    {
      'html:name': `authorizedAmounts.other`,
    },
  ];
  return result;
};

function Sum(schemaItems, formValues) {
  return schemaItems.reduce((acc, item) => {
    const formElementName = item['html:name'];
    const value = parseFloat(
      FormJsonSchemaUtil.getValueFromObject(formValues, formElementName) || 0,
    );
    return parseFloat(acc + value);
  }, 0);
}

function getCalculatedTotal(currentValues) {
  const prePaidAmount = Number(currentValues?.prepaidAmount) || 0;
  let calculatedValue = Sum(paymentItems(), currentValues);
  calculatedValue = Number(calculatedValue);
  if (prePaidAmount) {
    calculatedValue -= prePaidAmount;
  }
  return calculatedValue;
}

function PaymentAuthorizedCalculated(props) {
  const { formId, decimal = 0 } = props;

  const [result, setResult] = useState(null);
  const store = useStore();

  useEffect(() => {
    const currentValues = store.domain.formState.getState(formId);
    let calculatedValue = null;
    calculatedValue = getCalculatedTotal(currentValues);
    if (calculatedValue || calculatedValue === 0) {
      const withDecimalValue = Number.isNaN(calculatedValue)
        ? calculatedValue
        : parseFloat(calculatedValue).toFixed(decimal);
      setResult(`$${withDecimalValue}`);
    } else {
      setResult('');
    }
  }, [
    store.domain.formState.formState[formId]?.authorizedAmounts?.labor,
    store.domain.formState.formState[formId]?.authorizedAmounts
      ?.freightAndShipping,
    store.domain.formState.formState[formId]?.authorizedAmounts?.travel,
    store.domain.formState.formState[formId]?.authorizedAmounts?.totalParts,
    store.domain.formState.formState[formId]?.authorizedAmounts?.partsHandling,
    store.domain.formState.formState[formId]?.authorizedAmounts?.tax,
    store.domain.formState.formState[formId]?.authorizedAmounts?.other,
  ]);

  return <FormValue>{result}</FormValue>;
}

export default observer(PaymentAuthorizedCalculated);
