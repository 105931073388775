import { makeAutoObservable } from 'mobx';
import { clearPersistedStore, makePersistable } from 'mobx-persist-store';

class DemoCustomerFormStore {
  params = JSON.stringify({});

  constructor(rootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);

    makePersistable(this, {
      name: 'DemoCustomerFormStore',
      properties: ['params'],
    });
  }

  set(newParams) {
    this.params = JSON.stringify(newParams);
  }

  get() {
    return JSON.parse(this.params);
  }

  async clearStore() {
    await clearPersistedStore(this);
  }
}

export default DemoCustomerFormStore;
