import {
  Breadcrumbs,
  LoadingEvent,
  PageSection,
  useDataProvider,
} from '@servicexcelerator/ui-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { useState } from 'react';
import { Button, Typography } from '@mui/material';
import { nanoid } from 'nanoid';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import ServiceProviderDataTable from './ServiceProviderDataTable';
import CustomerDataTable from './CustomerDataTable';

function DemoSetup({ AppModule }) {
  const { Components, Icons } = AppModule;
  const { BackButton, ServiceJob: ServiceJobSearchIcon } = Icons;
  const { Layout, Header, DashboardHeader } = Components;

  const { formatMessage } = useIntl();

  const provider =
    process.env.NODE_ENV === 'development' ? 'serviceJob' : 'default';
  const { getOne } = useDataProvider(provider);

  const [isSettingUpDate, setIsSettingUpDate] = useState(false);

  const setUpData = async () => {
    setIsSettingUpDate(true);
    await getOne(`/servicejob/v1/servicejob/generate/data`);
    setIsSettingUpDate(false);
  };

  const HeaderContent = null;

  const breadCrumb = [
    {
      label: formatMessage({
        id: 'DASHBOARD',
        defaultMessage: 'Dashboard',
      }),
      href: '/dashboard',
    },
  ];

  breadCrumb.push({
    label: formatMessage({
      id: 'SERVICE_PROVIDERS',
      defaultMessage: 'Service Providers',
    }),
  });

  return (
    <Layout AppModule={AppModule}>
      <Header sx={{ mb: 2 }}>
        <DashboardHeader />
      </Header>

      <div>
        <Grid2 container pt={0} pb={0} alignItems="center">
          <Grid2 xs={6}>
            <Breadcrumbs links={breadCrumb} />
          </Grid2>
          <Grid2 textAlign="right" xs={6}>
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                window.history.back();
              }}
              startIcon={<BackButton />}>
              <FormattedMessage id="BACK_BUTTON" defaultMessage="Back" />
            </Button>
          </Grid2>
        </Grid2>
      </div>

      <Grid2 mt={3}>
        <Grid2 mb={2}>
          <PageSection
            HeaderContent={HeaderContent}
            headerIcon={ServiceJobSearchIcon}
            label={formatMessage({
              id: 'SERVICE_PROVIDERS',
              defaultMessage: 'Service Providers',
            })}>
            {!isSettingUpDate && (
              <ServiceProviderDataTable key={nanoid()} AppModule={AppModule} />
            )}
          </PageSection>
        </Grid2>

        <Grid2 mb={2}>
          <PageSection
            defaultExpanded={false}
            HeaderContent={HeaderContent}
            headerIcon={ServiceJobSearchIcon}
            label="Customers">
            {!isSettingUpDate && (
              <CustomerDataTable key={nanoid()} AppModule={AppModule} />
            )}
          </PageSection>
        </Grid2>
        <Grid2 mb={2}>
          <PageSection
            defaultExpanded={false}
            HeaderContent={HeaderContent}
            headerIcon={ServiceJobSearchIcon}
            label="Setup Data">
            <Typography>Clicking the below setup data button will</Typography>
            <ul>
              <li style={{ color: 'red' }}>Erase all existing service jobs</li>
              <li>Setup new customers</li>
              <li>Setup new serviceproviders</li>
            </ul>
            {!isSettingUpDate ? (
              <Button onClick={() => setUpData()} variant="contained">
                Setup Data
              </Button>
            ) : (
              <LoadingEvent>Setting up data</LoadingEvent>
            )}
          </PageSection>
        </Grid2>
      </Grid2>
    </Layout>
  );
}

export default DemoSetup;
