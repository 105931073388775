const Operators = [
  {
    label: 'equalTo',
    value: 'equal',
    types: ['date', 'integer', 'number', 'radio', 'select', 'switch', 'text'],
  },
  {
    label: 'notEqualTo',
    value: 'not_equal',
    types: ['date', 'integer', 'number', 'radio', 'select', 'switch', 'text'],
  },
  {
    label: 'contains',
    value: 'contains',
    types: ['text'],
  },
  {
    label: 'doesNotContain',
    value: 'not_contains',
    types: ['text'],
  },
  {
    label: 'lessThan',
    value: 'less',
    types: ['number', 'integer'],
  },
  {
    label: 'greaterThan',
    value: 'greater',
    types: ['number', 'integer'],
  },
  {
    label: 'lessThanInclusive',
    value: 'less_equal',
    types: ['number', 'integer'],
  },
  {
    label: 'greaterThanInclusive',
    value: 'greater_equal',
    types: ['number', 'integer'],
  },
  {
    label: 'before',
    value: 'before',
    types: ['date'],
  },
  {
    label: 'after',
    value: 'after',
    types: ['date'],
  },
  {
    label: 'beforeThanInclusive',
    value: 'before_equal',
    types: ['date'],
  },
  {
    label: 'afterThanInclusive',
    value: 'after_equal',
    types: ['date'],
  },
  {
    label: 'in',
    value: 'in',
    types: ['multiselect'],
  },
  {
    label: 'notIn',
    value: 'not_in',
    types: ['multiselect'],
  },
  {
    label: 'isEmpty',
    value: 'isEmpty',
    valueField: false,
    types: [
      'date',
      'integer',
      'number',
      'multiselect',
      'radio',
      'select',
      'switch',
      'text',
    ],
  },
  {
    label: 'isNotEmpty',
    value: 'isNotEmpty',
    valueField: false,
    types: [
      'date',
      'integer',
      'number',
      'multiselect',
      'radio',
      'select',
      'switch',
      'text',
    ],
  },
  {
    label: 'isFutureDate',
    value: 'isFutureDate',
    types: ['date'],
    valueField: false,
  },
  {
    label: 'isSameAsPreviousClaimBy',
    value: 'isDuplicateClaim',
    types: ['multiselect'],
    valueField: true,
  },
];
export default Operators;
