function ServiceProviderServiceJob({ index }) {
  const length = 36;
  const strokeWidth = 2;
  const viewBoxSize = length + strokeWidth * 2;

  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  svg.setAttribute('width', viewBoxSize);
  svg.setAttribute('height', viewBoxSize);
  svg.setAttribute('viewBox', `0 0 ${viewBoxSize} ${viewBoxSize}`);

  const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');

  rect.setAttribute('width', length);
  rect.setAttribute('height', length);
  rect.setAttribute('fill', '#5fbcdb');
  rect.setAttribute('stroke', '#049bce');
  rect.setAttribute('stroke-width', strokeWidth);

  svg.appendChild(rect);

  const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
  text.setAttribute('fill', '#000000');
  text.setAttribute('font-weight', '500');
  text.setAttribute('x', '35%');
  text.setAttribute('y', '65%');
  text.setAttribute('font-size', '1.4em');

  const textNode = document.createTextNode((index + 1).toString());
  text.appendChild(textNode);

  svg.appendChild(text);

  return svg;
}

export default ServiceProviderServiceJob;
