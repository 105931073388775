import getBasicOEMSchema from '../../../formSchema/BASICOEM2024/index';
import SERVICECONTRACT2024 from '../../../formSchema/SERVICECONTRACT2024/index';
import COD2024 from '../../../formSchema/COD2024/index';
import JOBINITITATION2024 from '../../../formSchema/JOBINITIATION2024/index';

function getFormSchemas(
  serviceJobFormId = 'null',
  isSA = false,
  status = 'INC',
) {
  switch (serviceJobFormId) {
    case 'JOBINITIATION-2024':
      return JOBINITITATION2024;
    case 'BASICOEM-2024': {
      return getBasicOEMSchema(isSA, status);
    }
    case 'SERVICECONTRACT-2024':
      return SERVICECONTRACT2024;
    case 'COD-2024':
      return COD2024;
    default:
      return null;
  }
}

export default getFormSchemas;
