import uiSchema from './index.json';
import uiSchemaCustomer from './customer_section.json';
import uiSchemaProduct from './product_section.json';
import uiSchemaService from './service_section.json';
import createAjv from './ajv/create.json';
import updateAjv from './ajv/update.json';

const getUISchema = () => {
  uiSchema['ui:children'] = [
    uiSchemaCustomer,
    uiSchemaProduct,
    uiSchemaService,
  ];
  return uiSchema;
};

const getAjvSchema = () => ({
  create: createAjv,
  update: updateAjv,
});

const schema = {
  ui: getUISchema(),
  ajv: getAjvSchema(),
};

export default schema;
