import { FormLabel } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { FormValue } from '@servicexcelerator/ui-design-system';

function DataItemBlock({ label, value, ...rest }) {
  return (
    <Grid2 {...rest}>
      <Grid2>
        <FormLabel>{label}</FormLabel>
      </Grid2>
      <Grid2>
        <FormValue sx={{ fontSize: '14px', fontWeight: 500 }}>
          {value}
        </FormValue>
      </Grid2>
    </Grid2>
  );
}

export default DataItemBlock;
