function transformBoolean(props, fieldName) {
  const {
    row: { original },
  } = props;

  return original?.services[fieldName] ? 'Yes' : 'No';
}

const getColumns = () => [
  {
    accessorKey: 'companyName',
    header: 'Company Name',
  },
  {
    accessorKey: 'serviceProviderNumber',
    header: 'Service Provider Number',
    enableEditing: false,
  },
  {
    accessorKey: 'email',
    header: 'Email',
  },
  {
    accessorKey: 'phoneNumber',
    header: 'Phone Number',
  },
  {
    accessorKey: 'managedPhoneNumber',
    header: 'Managed Phone Number',
  },
  {
    accessorKey: 'services_networkType',
    header: 'Network Type',
    enableEditing: false,
  },
  {
    accessorKey: 'services_newJob',
    header: 'Services - New Job',
    Cell: props => transformBoolean(props, 'newJob'),
    enableEditing: false,
  },
  {
    accessorKey: 'services_zip',
    header: 'Services - Zip Code',
    Cell: props => transformBoolean(props, 'zip'),
    enableEditing: false,
  },
  {
    accessorKey: 'services_brand',
    header: 'Services - Brand',
    Cell: props => transformBoolean(props, 'brand'),
    enableEditing: false,
  },
  {
    accessorKey: 'services_productType',
    header: 'Services - Product Type',
    Cell: props => transformBoolean(props, 'productType'),
    enableEditing: false,
  },
];

export default getColumns;
