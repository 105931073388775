import {
  DYNAMIC_FORM_MODE,
  DynamicForm,
  FormJsonSchemaUtil,
  FormValue,
  LoadingEvent,
  PageSection,
  SpecialSection,
  useStore,
} from '@servicexcelerator/ui-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, FormLabel, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { nanoid } from 'nanoid';
import { useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import jsonSchema from './jsonSchema.json';
import uiSchema from './uiSchema.json';

function ClaimFormsSearchPage({
  onColumnFiltersChanged = () => undefined,
  columnFilters = [],
  formValues = {},
  AppModule,
}) {
  const { Icons, Utils } = AppModule;
  const { MasterDataUtil } = Utils;
  const { ClaimForms: ClaimFormsIcon } = Icons;
  const { formatMessage } = useIntl();
  const formRef = useRef();
  const store = useStore();
  const [errors, setErrors] = useState([]);
  const [defaultValues, setDefaultvalues] = useState(formValues);
  const [initialized, setInitialized] = useState(false);
  const [currentFormMode, setCurrentFormMode] = useState(
    columnFilters.length > 0 ? DYNAMIC_FORM_MODE.VIEW : DYNAMIC_FORM_MODE.EDIT,
  );
  const masterData = store.domain.masterData.get();

  const columnMap = useMemo(
    () => FormJsonSchemaUtil.getLeafNodesMap([uiSchema]),
    [uiSchema],
  );

  const onSubmitSuccess = data => {
    // Has atleast one object
    const filterQuery = Object.entries(data).reduce((acc, [key, value]) => {
      if (value) {
        acc.push({
          id: key,
          value,
        });
      }
      return acc;
    }, []);
    setCurrentFormMode(DYNAMIC_FORM_MODE.VIEW);
    onColumnFiltersChanged(filterQuery);
  };

  const onSubmitError = data => {
    setErrors(data);
  };

  const searchFormButton = [
    {
      color: 'primary',
      variant: 'contained',
      children: formatMessage({
        id: 'SEARCH_BUTTON',
        defaultMessage: 'Search',
      }),
      type: 'button',
      onClick: () => {
        formRef.current.submitForm();
      },
    },
    {
      color: 'primary',
      variant: 'outlined',
      children: formatMessage({
        id: 'CLOSE_BUTTON',
        defaultMessage: 'Close',
      }),
      type: 'button',
      onClick: () => {
        setCurrentFormMode(DYNAMIC_FORM_MODE.VIEW);
      },
    },
  ];

  const reEditFormButtons = [
    {
      color: 'primary',
      variant: 'contained',
      children: formatMessage({
        id: 'EDIT_SEARCH_BUTTON',
        defaultMessage: 'Edit Search',
      }),
      type: 'button',
      onClick: () => {
        setCurrentFormMode(DYNAMIC_FORM_MODE.EDIT);
      },
    },
  ];

  useEffect(() => {
    setInitialized(false);
    const values = columnFilters.reduce(
      (acc, item) => ({
        ...acc,
        [item.id]: item.value,
      }),
      {},
    );
    setDefaultvalues(values);
    setInitialized(true);
  }, [columnFilters]);

  const renderFilters = (columnFiltersToRender = []) => {
    const getValue = (id, value) => {
      if (id === 'addressCountryCode') {
        return MasterDataUtil.getCountryById(masterData, value)?.label;
      }
      if (id === 'industryId') {
        return MasterDataUtil.getIndustryById(masterData, value)?.label;
      }
      if (id === 'warrantyTypeId') {
        return MasterDataUtil.getWarrantyTypeId(masterData, value)?.label;
      }
      if (id === 'serviceTypeId') {
        return MasterDataUtil.getServiceTypeById(masterData, value)?.label;
      }
      if (id === 'serviceAdministratorClientNumber') {
        return MasterDataUtil.getSAClientById(masterData, value)?.label;
      }
      return value;
    };

    return columnFiltersToRender.map(item => (
      <Grid2 xs={4} key={item.id} container spacing={1}>
        <Grid2>
          <FormLabel>
            <FormattedMessage
              defaultMessage={columnMap.get(item.id)['ui:props'].label}
              id={columnMap.get(item.id)['ui:props'].localeId}
            />
          </FormLabel>
        </Grid2>
        <Grid2>:</Grid2>
        <Grid2>
          <FormValue>{getValue(item.id, item.value)}</FormValue>
        </Grid2>
      </Grid2>
    ));
  };

  return (
    <PageSection
      label={formatMessage({
        id: 'CLAIM_FORMS_SEARCH',
        defaultMessage: 'Claim Form Search',
      })}
      headerIcon={ClaimFormsIcon}>
      {errors.length > 0 && (
        <Grid2 ml={1} mr={1} mb={2}>
          <SpecialSection color="error.main">
            <Typography>
              <FormattedMessage
                id="RESOLVE_ISSUES_BEFORE_FORM_SUBMIT"
                defaultMessage="Please resolve below issues before submitting the form."
              />
            </Typography>
            <ul>
              {errors.map(item => (
                <li key={item.errorMessage} style={{ marginBottom: '5px' }}>
                  <Typography color="error">
                    {item?.path}
                    {item?.path ? ' : ' : ''}
                    {item.errorMessage}
                  </Typography>
                </li>
              ))}
            </ul>
          </SpecialSection>
        </Grid2>
      )}
      {currentFormMode === DYNAMIC_FORM_MODE.VIEW ? (
        <Grid2 container spacing={2}>
          {renderFilters(columnFilters)}
        </Grid2>
      ) : (
        <DynamicForm
          formId="claimFormsSearch"
          formMode={currentFormMode}
          uiSchema={uiSchema}
          jsonSchema={jsonSchema}
          onSubmitSuccess={onSubmitSuccess}
          onSubmitError={onSubmitError}
          reference={formRef}
          initialFormValues={defaultValues}
          initialized={initialized}
          loadingComponent={
            <LoadingEvent>
              <FormattedMessage
                id="LOADING_SCHEMA"
                defaultMessage="Loading Schema"
              />
            </LoadingEvent>
          }
          formatMessage={formatMessage}
          masterData={masterData}
        />
      )}
      {initialized && currentFormMode === DYNAMIC_FORM_MODE.EDIT && (
        <Grid2 mt={3} mb={1} container spacing={2} justifyContent="end">
          {searchFormButton.map(itemProps => (
            <Grid2 key={nanoid()}>
              <Button {...itemProps} />
            </Grid2>
          ))}
        </Grid2>
      )}
      {currentFormMode === DYNAMIC_FORM_MODE.VIEW && (
        <Grid2 container mt={1} justifyContent="end">
          {reEditFormButtons.map(itemProps => (
            <Grid2 key={nanoid()}>
              <Button size="small" {...itemProps} />
            </Grid2>
          ))}
        </Grid2>
      )}
    </PageSection>
  );
}

export default observer(ClaimFormsSearchPage);
