import { LoggerUtil } from '@servicexcelerator/ui-design-system';

async function createServiceJob(data, additionalData, { createOne }) {
  const result = {
    message: {
      message: {
        id: 'ERROR_CREATING_SERVICE_JOB',
        defaultMessage: 'There was an error creating the job',
      },
    },
    errors: null,
    success: false,
    data: null,
  };

  const createServiceJobResponse = await createOne(
    '/servicejob/v1/servicejob',
    data,
  );

  if (createServiceJobResponse?.errors) {
    result.errors = createServiceJobResponse.errors;
  } else if (
    !createServiceJobResponse?.errors &&
    createServiceJobResponse?.data?.serviceJobNumber
  ) {
    result.data = createServiceJobResponse?.data;
    result.success = true;
    result.message = {
      message: {
        id: 'NEW_SERVICE_JOB_CREATED_WITH_ID_X',
        defaultMessage:
          'New job was created with job number {serviceJobNumber}',
      },
      params: {
        serviceJobNumber: createServiceJobResponse?.data?.serviceJobNumber,
      },
    };
  }

  LoggerUtil.log('Create Response', {
    additionalData,
    request: data,
    response: createServiceJobResponse,
    actionResult: result,
  });

  return result;
}

export default createServiceJob;
