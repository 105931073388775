import { Box } from '@mui/material';
import {
  LoadingEvent,
  useDataProvider,
  useStore,
} from '@servicexcelerator/ui-design-system';
import { nanoid } from 'nanoid';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { observer } from 'mobx-react';

function CreateFormAutoRedirect() {
  const provider =
    process.env.NODE_ENV === 'development' ? 'serviceJob' : 'default';

  const { getOne } = useDataProvider(provider);

  const store = useStore();

  const navigate = useNavigate();

  const generateFakeJob = async () => {
    const serviceJobRef = nanoid();

    const preServiceJobData = {
      serviceAdministratorClientNumber: 'BIGGIE',
      warrantyTypeId: 'OEM',
      industryTypeId: 'MAJOR_APPLIANCE',
      serviceTypeId: 'REPAIR',
      serviceSite: 'ON_SITE',
      country: 'US',
      productType: 'Dishwasher',
    };

    const request = await getOne(
      `/servicejob/v1/servicejob/operation/new`,
      preServiceJobData,
    );

    const customerData = store.uiState.createServiceJobModal.getData();

    store.domain.serviceJobs.setProspectiveServiceJob(serviceJobRef, {
      ...request.data,
      customer: customerData || {},
      brand: {
        brandCode: customerData?.brand?.brandCode,
      },
      modelNumber: customerData?.modelNumber,
      serialNumber: customerData?.serialNumber,
      productPurchaseDate: customerData?.productPurchaseDate,
    });

    navigate(`/servicejob/create/${serviceJobRef}`, { replace: true });
    store.uiState.createServiceJobModal.setOpen(false);
  };

  useEffect(() => {
    generateFakeJob();
  }, []);

  return (
    <Box>
      <LoadingEvent>Creating Service Job</LoadingEvent>
    </Box>
  );
}

export default observer(CreateFormAutoRedirect);
