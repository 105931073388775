import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button } from '@mui/material';

function NextDateButton({ selectedDate, setSelectedDate }) {
  return (
    <Button
      onClick={() => {
        const newDate = selectedDate.add(1, 'day');
        setSelectedDate(newDate);
      }}
      variant="outlined"
      size="small"
      sx={{ minWidth: '0px', padding: '0 2px', marginTop: '5px' }}>
      <ChevronRightIcon />
    </Button>
  );
}

export default NextDateButton;
