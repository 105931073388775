import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { VALUE_SOURCES } from '../../common/constants';

function ValueSource(props) {
  const { onValueSourceChange, conditionId, valueSource } = props;

  return (
    <FormControl fullWidth>
      <Select
        size="small"
        labelId="ruleoutcome-select-label"
        id="ruleoutcome-select"
        value={valueSource}
        onChange={e => {
          onValueSourceChange({ conditionId, value: e.target.value });
        }}>
        {VALUE_SOURCES.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default ValueSource;
