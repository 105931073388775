const OutNetworkCommunity = theme => {
  const group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
  group.setAttribute('transform', 'scale(0.5) translate(23 35)');

  const path1 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path1.setAttribute(
    'd',
    'M19.5 3.5 18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2v14H3v3c0 1.66 1.34 3 3 3h12c1.66 0 3-1.34 3-3V2zM19 19c0 .55-.45 1-1 1s-1-.45-1-1v-3H8V5h11z',
  );
  path1.setAttribute('fill', 'none');
  path1.setAttribute('stroke', theme.palette.warning.main);

  const path2 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path2.setAttribute('d', 'M9 7h6v2H9zm7 0h2v2h-2zm-7 3h6v2H9zm7 0h2v2h-2z');
  path2.setAttribute('fill', 'none');
  path2.setAttribute('stroke', theme.palette.warning.main);

  group.appendChild(path1);
  group.appendChild(path2);
  return group;
};

export default OutNetworkCommunity;
