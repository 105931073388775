const OutNetworkIndependent = theme => {
  const group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
  group.setAttribute('transform', 'scale(0.5) translate(23 35)');

  const path1 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path1.setAttribute('d', 'M12 5.99 19.53 19H4.47zM12 2 1 21h22z');
  path1.setAttribute('fill', theme.palette.error.main);
  path1.setAttribute('stroke', theme.palette.error.main);

  const path2 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path2.setAttribute('d', 'M13 16h-2v2h2zm0-6h-2v5h2z');
  path2.setAttribute('fill', 'none');
  path2.setAttribute('stroke', theme.palette.error.main);

  group.appendChild(path1);
  group.appendChild(path2);
  return group;
};

export default OutNetworkIndependent;
