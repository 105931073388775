import HandymanIcon from '@mui/icons-material/Handyman';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

const getPageButtons = ({
  formSubmitting,
  formRef,
  setFormSubmitting,
  formatMessage = () => undefined,
  AppModule,
  serviceJobData,
  cancelServiceJob = () => undefined,
  acceptServiceJobCallback = () => undefined,
  rejectServiceJobCallback = () => undefined,
  navigate,
  isSA,
}) => {
  const { Icons, Constants } = AppModule;
  const { SaveButton, SubmitButton, CancelButton } = Icons;
  const { SERVICE_JOB_STATUS } = Constants;

  let result = [];

  const BUTTONS = {
    SCHEDULE_SERVICE: {
      async: true,
      Icon: CalendarMonthIcon,
      children: formatMessage({
        id: 'SCHEDULE_SERVICE_BUTTON',
        defaultMessage: 'Schedule Service',
      }),
      processingLabel: formatMessage({
        id: 'PROCESSING',
        defaultMessage: 'Processing...',
      }),
      inProcess: formSubmitting,
      onClick: () => {
        formRef.current.submitForm('saveOnlyScheduleFlow');
      },
    },
    ORDER_PARTS: {
      async: true,
      Icon: HandymanIcon,
      children: formatMessage({
        id: 'ORDER_PARTS_BUTTON',
        defaultMessage: 'Order Parts',
      }),
      processingLabel: formatMessage({
        id: 'PROCESSING',
        defaultMessage: 'Processing...',
      }),
      inProcess: formSubmitting,
      onClick: () => {},
    },
    SUBMIT: {
      async: true,
      Icon: SubmitButton,
      children: formatMessage({
        id: 'SUBMIT_BUTTON',
        defaultMessage: 'Submit',
      }),
      processingLabel: formatMessage({
        id: 'PROCESSING',
        defaultMessage: 'Processing...',
      }),
      inProcess: formSubmitting,
      onClick: () => {
        setFormSubmitting(true);
        formRef.current.submitForm('submit');
      },
    },
    SAVE: {
      async: true,
      Icon: SaveButton,
      children: formatMessage({
        id: 'SAVE_BUTTON',
        defaultMessage: 'Save',
      }),
      processingLabel: formatMessage({
        id: 'SAVING',
        defaultMessage: 'saving...',
      }),
      inProcess: formSubmitting,
      onClick: () => {
        setFormSubmitting(true);
        formRef.current.submitForm('saveonly');
      },
    },
    CANCEL: {
      async: true,
      Icon: CancelButton,
      children: formatMessage({
        id: 'CANCEL_BUTTON',
        defaultMessage: 'Cancel',
      }),
      processingLabel: formatMessage({
        id: 'SAVING',
        defaultMessage: 'saving...',
      }),
      inProcess: formSubmitting,
      onClick: async () => {
        setFormSubmitting(true);
        await cancelServiceJob();
        setFormSubmitting(false);
      },
    },
    DISCARD: {
      async: true,
      Icon: DeleteForeverIcon,
      children: formatMessage({
        id: 'DISCARD_BUTTON',
        defaultMessage: 'Discard',
      }),
      processingLabel: formatMessage({
        id: 'SAVING',
        defaultMessage: 'saving...',
      }),
      inProcess: formSubmitting,
      onClick: async () => {
        navigate(`/servicejob/create/selectcustomer`);
      },
    },
    REJECT: {
      async: true,
      Icon: CloseIcon,
      children: formatMessage({
        id: 'REJECT_BUTTON',
        defaultMessage: 'Reject',
      }),
      processingLabel: formatMessage({
        id: 'SAVING',
        defaultMessage: 'saving...',
      }),
      inProcess: formSubmitting,
      color: 'error',
      onClick: async () => {
        rejectServiceJobCallback(serviceJobData);
      },
    },
    ACCEPT: {
      async: true,
      Icon: DoneIcon,
      children: formatMessage({
        id: 'ACCEPT_BUTTON',
        defaultMessage: 'Accept',
      }),
      processingLabel: formatMessage({
        id: 'SAVING',
        defaultMessage: 'saving...',
      }),
      inProcess: formSubmitting,
      onClick: async () => {
        acceptServiceJobCallback(serviceJobData);
      },
    },
    COMPLETE: {
      async: true,
      Icon: DoneIcon,
      children: formatMessage({
        id: 'COMPLETE_BUTTON',
        defaultMessage: 'Complete',
      }),
      processingLabel: formatMessage({
        id: 'SAVING',
        defaultMessage: 'saving...',
      }),
      color: 'success',
      inProcess: formSubmitting,
      onClick: async () => {},
    },
    RESCHEDULE: {
      async: true,
      Icon: CalendarMonthIcon,
      children: formatMessage({
        id: 'RESCHEDULE_BUTTON',
        defaultMessage: 'Reschedule',
      }),
      processingLabel: formatMessage({
        id: 'PROCESSING',
        defaultMessage: 'Processing...',
      }),
      inProcess: formSubmitting,
      onClick: () => {},
    },
  };

  switch (serviceJobData.serviceJobStatus.key) {
    case SERVICE_JOB_STATUS.PAC:
      if (isSA) {
        result = [
          BUTTONS.ORDER_PARTS,
          BUTTONS.CANCEL,
          BUTTONS.SUBMIT,
          BUTTONS.SAVE,
        ];
      } else if (!isSA) {
        result = [BUTTONS.ACCEPT, BUTTONS.REJECT];
      }

      break;
    case SERVICE_JOB_STATUS.INC:
      result = [BUTTONS.ORDER_PARTS, BUTTONS.SCHEDULE_SERVICE, BUTTONS.DISCARD];
      break;
    case SERVICE_JOB_STATUS.ACC:
      result = [BUTTONS.RESCHEDULE, BUTTONS.COMPLETE];
      break;
    case SERVICE_JOB_STATUS.CAN:
    default:
      result = [];
  }

  return result;
};

export default getPageButtons;
